import { useState, useContext, useEffect, createContext } from "react";
import axios from "axios";

const AuthContenxt = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
  });
  // window.addEventListener("message", (event)=> {
  //   console.log('Data received from React Native: ', event.data);
  //   toast.success("data",event.data);
  // });
  // console.log("auth",auth)
  // default axios
  axios.defaults.headers.common["Authorization"] = auth?.token;
  
  useEffect(() => {
    const data = localStorage.getItem("auth");
    const token = localStorage.getItem("expopushtoken");
    if (data) {
      const parseData = JSON.parse(data);
      setAuth({
        ...auth,
        user: parseData.user,
        name :parseData.user.name,
        token: parseData.token,
        ExpoToken: token,
      });
    }
    if(token){
      setAuth({
        ...auth,
        ExpoToken: token,
      });
    }

    // eslint-disable-next-line
  }, []);
  return (
    <AuthContenxt.Provider value={[auth, setAuth]}>
      {children}
    </AuthContenxt.Provider>
  );
};

const useAuth = () => useContext(AuthContenxt);

export { useAuth, AuthProvider };
