import React from "react";
import { LineGraph } from "./Graph";

export const OrdersCard = ({ data }) => {
  // console.log(data)
  const today = new Date().toISOString().slice(0, 10);
  const newdata=data[0]?.date===today?data:data.unshift({date:today,TotalOrders:0})
  console.log("newdata",newdata)
  const per =
    ((data[0]?.TotalOrders - data[1]?.TotalOrders) / data[1]?.TotalOrders) *
    100;
  // console.log("p", per);
  // console.log(
  //   "tpday:",
  //   data[0]?.TotalOrders,
  //   "yesterday:",
  //   data[1]?.TotalOrders
  // );
  return (
    <div
      className="d-flex flex-column"
      style={{
        padding: "1rem",
        border: "2px solid #11074C ",
        borderRadius: "5px",
      }}
    >
      <div className="top d-flex flex-row justify-content-between">
        <div className="div">
          <h1
            className="ps-1"
            style={{
              borderLeft: "10px solid #11074C",
              borderRadius: "5px",
              margin: "0",
            }}
          >
            Orders
          </h1>
        </div>
        <div className=" pe-5 d-flex align-items-center">
          <h1 className="m-0 pe-2">
            {" "}
            {data[0]?.TotalOrders}
            {"   "}{" "}
          </h1>
          <b style={{ fontSize: "25px", color: per >= 0 ? "green" : "red" }}>
            {per >= 0 ? "+" : ""} {Math.floor(per)}%
          </b>
        </div>
      </div>
      <div className="graph">
        <LineGraph OrderData={data} />
      </div>
    </div>
  );
};
