import { useState, useContext, createContext, useEffect } from "react";

const FavContext = createContext();

const FavProvider = ({ children }) => {
    const [Fav, setFav] = useState([]);
  
    useEffect(() => {
      let existingFav = localStorage.getItem("Fav");
      if (existingFav) setFav(JSON.parse(existingFav));
    }, []);
  
    return (
      <FavContext.Provider value={[Fav,setFav]}>
        {children}
      </FavContext.Provider>
    );
  };

  const useFav = () => useContext(FavContext);

export { useFav, FavProvider };