import React, { useEffect, useState } from "react";
// import { Rating } from "../Components/Rating";
import { Header } from "../Components/Header/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { useImg } from "../Contex/AssetProvider";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useAuth } from "../Contex/AuthProvider";
export const FStore = () => {
  const [auth] = useAuth();
  if (auth?.user?.role === 1) {
    window.location.href = "/admin/dashboard";
  }
  useEffect(() => {
    fetchData();
  }, []);
  const array = [1, 2, 3, 4, 5];
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("store", store);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/store`
      );

      setLoading(false);
      if (res.data.success) {
        setStore(res.data.store);
        // console.log("id", res.data.store);
      } else {
        // console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { getImage } = useImg();
  const [image, setImage] = useState([]);
  useEffect(() => {
    store.forEach(async (s, i) => {
      const img = await getImage(s.img);
      setImage((prevState) => ({ ...prevState, [s.img]: img }));
      // Do something with the image
    });
  }, [getImage, store]);
  // const { images, getImage } = useImg();

  // useEffect(() => {
  //   getImage(imgId);
  // }, [imgId, getImage]);
  // useEffect(() => {
  //   store.forEach(async (s) => {
  //     const img = await getImage(s.img);
  //     setImage((prevState) => ({ ...prevState, [s.img]: img }));
  //   });
  // }, [getImage, store]);
  return (
    <div className="Deskcontainer">
      <Header state={"Stores"} />
      <div>
        <div>
          {/* <div className=" ps-3 ">
            <Link to="/" className="d-inline back-btn">
              <b>
                <i className="bi bi-arrow-left"></i> Back
              </b>
            </Link>
          </div> */}
          {loading ? (
            <SkeletonTheme
              baseColor="rgb(205 213 220 / 64%)"
              highlightColor="white"
            >
              <div
                className="storecards pt-2 mb-5"
                style={{ zIndex: "-100", height: "0vh", position: "relative" }}
              >
                {array.map(() => {
                  console.log();
                  return (
                    <div
                      className="store-card mt-4"
                      style={{
                        marginTop: "20px",
                        margin: "auto",
                        border: "2px",
                        borderRadius: "20px",
                        width: "80%",
                        boxShadow: "10px 10px 5px grey",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <Skeleton height={200} width={"100%"} />{" "}
                        {/* For image */}
                      </div>

                      <div className="store-disc p-3">
                        <h1 style={{ fontWeight: "bold", marginBottom: "1%" }}>
                          <Skeleton />
                        </h1>
                        <h5 style={{ margin: "0" }}>
                          <Skeleton count={2} />
                        </h5>
                        <div className="rating">
                          <Skeleton />
                        </div>
                        <div className="prepare-time mb-2 d-inline pe-2">
                          <Skeleton />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </SkeletonTheme>
          ) : (
            <div
              className="storecards pt-2 mb-5"
              style={{
                paddingBottom: "5rem",
              }}
            >
              {store.map((s) => {
                return (
                  <div
                    className="store-card mt-4"
                    style={{
                      margin: "auto",
                      border: "2px",
                      borderRadius: "20px",
                      width: "80%",
                      boxShadow: "10px 10px 5px grey",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      color: " #11074C",
                      backgroundColor: "white",
                    }}
                    key={s._id}
                  >
                    <Link to={`/${s.type}/${s._id}`}>
                      <div className="" style={{ height: "200px" }}>
                        {image[s.img] ? (
                          <img
                            // src={`${process?.env?.REACT_APP_API}/api/v1/assets/getImg/${s?.img}`}
                            src={image[s.img]}
                            alt=""
                            style={{
                              width: "100%",
                              borderRadius: "20px",
                              height: "200px",
                            }}
                          />
                        ) : (
                          <Skeleton
                            style={{
                              width: "100%",
                              borderRadius: "20px",
                              height: "100%",
                            }}
                          />
                        )}
                      </div>

                      <div className="store-disc p-3">
                        <h1 style={{ fontWeight: "bold", marginBottom: "1%" }}>
                          {s.StoreName}
                        </h1>
                        <h5
                          style={{
                            margin: "0",
                          }}
                        >
                          <i className="bi bi-dot fs-3"></i>
                          {s.Spec1} <i className="bi bi-dot fs-3"></i>
                          {s.Spec2}
                        </h5>
                        {/* <Rating rating={s.rating} ratecount={s.rcount} /> */}
                        <div
                          className="prepare-time mb-2 d-inline pe-2"
                          style={{
                            backgroundColor: "#cfd1d3",
                            borderRadius: "8px",
                          }}
                        >
                          <i className="bi bi-clock ps-2 "> </i>
                          <b> {s.PrepTime} Min</b>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
