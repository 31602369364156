import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../Contex/AuthProvider";
import axios from "axios";
import { useSocket } from "../../Contex/WebSocket";
import { useImg } from "../../Contex/AssetProvider";
import Skeleton from "react-loading-skeleton";

export const MessagesBody = () => {
  // const [Conv, setConv] = useConv();
  const params = useParams();
  const [webSocket] = useSocket();
  const [auth] = useAuth();
  const [arrivalMessage, setArrivalMessage] = useState(null);

  // const index = Conv?.findIndex((Conv) => Conv._id === params.id);
  // console.log("conv : ", Conv);
  const [Conversation, setConversarion] = useState();
  // console.log("Conv:", Conversation);
  const scrollRef = useRef();
  const [msg, setMsg] = useState("");
  function Timestamp() {
    let dateObj = new Date();

    let date = dateObj.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
    });
    let time = dateObj.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${date} ${time}`;
  }

  const fetchData = useCallback(async () => {
    try {
      if (auth.user?.role === 0 && params.id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/conv/GetConv/${params.id}`
        );
        if (res.data) {
          // console.log("res : ", res.data);
          setConversarion(res.data.Conversation[0]);
          // setstores(res.data.Conversation)
          // setConv(res.data.Conversation);
        } else {
          // console.log("res : ", res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [auth, params.id]); // Add dependencies here

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Now fetchData is a dependency

  // useEffect(() => {
  //   webSocket.on("getMessage", (message) => {
  //     console.log("getMessage:", message);
  //     setArrivalMessage({
  //       message: message.message,
  //       sender: message.sender,
  //       receiver: message.receiver,
  //       timestamp: message.timestamp,
  //     });
  //   })
  // },
  // []);
  useEffect(() => {
    if (webSocket) {
      webSocket.on("getMessage", (message) => {
        // console.log("get msg true");
        setArrivalMessage({
          sender: message.sender,
          message: message.message,
          receiver: message.receiver,
          timestamp: message.timestamp,
        });
      });
    } else {
      // console.log("first");
    }
  }, [webSocket]);
  useEffect(() => {
    if (arrivalMessage) {
      setConversarion((prevstate) => ({
        ...prevstate,
        Messages: [...prevstate.Messages, arrivalMessage],
      }));
      setArrivalMessage(null);
    }
  }, [arrivalMessage]);
  // webSocket.on("getMessage", (message) => {
  //   console.log("getMessage:", message);
  //   // if(Conversation._id===message.ConversationId){
  //   //   setConversarion((prevstate) => ({
  //   //     ...prevstate,
  //   //     Messages: {
  //   //       message:message.message,
  //   //       sender:message.sender,
  //   //       receiver:message.receiver,
  //   //       timestamp:message.timestamp
  //   //     },
  //   //   }));
  //   // }
  // });
  const { getImage } = useImg(); // Get the getImage function from the context
  const [StoreimgSrc, setStoreImgSrc] = useState(null);
  const [CustimgSrc, setCustImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const Storesrc = await getImage(Conversation?.StoreImg); // Fetch the image for this item
      setStoreImgSrc(Storesrc); // Set the fetched image as the source
      const Custsrc = await getImage(Conversation?.CustomerImg); // Fetch the image for this item
      setCustImgSrc(Custsrc); // Set the fetched image as the source
    };

    fetchImage();
  }, [Conversation?.StoreImg, Conversation?.CustomerImg,getImage]);
  const IncommingMsg = (msg, timestamp) => {
    return (
      <div className="body ps imcoming-msg">
        <div className="row ">
          <div className="col-2 ">
            {StoreimgSrc ? (
              <img
                // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${Conversation?.StoreImg}`}
                src={StoreimgSrc}
                className="ms-img-round"
                alt="people"
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "100px",
                }}
              />
            ) : (
              <Skeleton
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "100px",
                }}
              />
            )}
          </div>
          <div className="col-10 pt-2 d-flex flex-column align-items-start">
            <div
              className="p-1 d-flex align-items-start inc-msg"
              style={{
                backgroundColor: "rgb(205 213 220 / 64%)",
                borderRadius: "0 10px 10px 10px",
                position: "relative",
                width: "fit-content",
              }}
            >
              <p style={{ fontSize: "15px" }}>
                <b>{msg}</b>
              </p>
            </div>
            <p style={{ fontSize: "15px" }}>{timestamp}</p>
          </div>
        </div>
      </div>
    );
  };
  const OutgoingMsg = (msg, timestamp) => {
    return (
      <div className="body ps outgoing-msg text-end">
        <div className="row justify-content-end pe-0">
          <div className="col-10 pt-2 ms-auto d-flex flex-column align-items-end">
            <div
              className="p-1 d-flex out-msg "
              style={{
                backgroundColor: "#ee817f7a",
                borderRadius: " 10px 0 10px 10px",
                position: "relative",
                justifyContent: "end",
                width: "fit-content",
              }}
            >
              <p style={{ fontSize: "15px" }}>
                <b>{msg}</b>
              </p>
            </div>
            <p style={{ fontSize: "15px" }}>{timestamp}</p>
          </div>

          <div className="col-1 p-0">
            {CustimgSrc ? (
              <img
                // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${Conversation?.StoreImg}`}
                src={CustimgSrc}
                className="ms-img-round"
                alt="people"
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "100px",
                }}
              />
            ) : (
              <Skeleton
                style={{
                  height: "45px",
                  width: "45px",
                  borderRadius: "100px",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  const sendMsg = async () => {
    try {
      // console.log("auth:", auth.user);
      if (auth.user && Conversation?._id) {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/conv/SendMsg/${Conversation?._id}`,
          {
            message: {
              sender: auth.user?._id,
              message: msg,
              timestamp: Timestamp(),
            },
          }
        );
        const messagePayload = {
          message: {
            ConversationId: Conversation?._id,
            receiver: Conversation?.StoreId,
            sender: auth.user?._id,
            message: msg,
            timestamp: Timestamp(),
          },
        };
        webSocket.emit("sendMessage", messagePayload);
        // setMsg("");
        if (res.data.Messages) {
          setConversarion((prevstate) => ({
            ...prevstate,
            Messages: res.data.Messages,
          }));
        } else {
          console.log(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Conversation]);
  // useEffect(()=>{
  //   const index = Conv?.findIndex((Conv) => Conv._id === params.id);
  //   setConversarion(Conv[index])     for reload fix
  // })
  return (
    <div className="Deskcontainer mb-5" style={{ height: "80vh" }}>
      <div className=" ps-3 mb-2">
        <Link to="/Messages" className="d-inline back-btn">
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="m-1">
        <div className="ms-panel   p-1 ">
          <div
            className="d-flex mb-4 ms-header"
            style={{
              position: "relative",
              borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className=" mr-3 align-self-center p-2">
              {StoreimgSrc ? (
                <img
                  // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${Conversation?.StoreImg}`}
                  src={StoreimgSrc}
                  className="ms-img-round"
                  alt="people"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                  }}
                />
              ) : (
                <Skeleton
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                  }}
                />
              )}
            </div>
            <div className=" mt-1 d-flex justify-content-center">
              <h5 className="m-0 d-flex align-items-center">
                <b>{Conversation?.StoreName}</b>
              </h5>
            </div>
          </div>
          <div
            className="ms-body overflow-y-scroll overflow-x-hidden pe-5"
            style={{
              height: "60vh",
              // overflow: "hidden",
              // overflowAnchor: "none",
              touchAction: "auto",
              position: "relative",
            }}
          >
            {Conversation?.Messages
              ? Conversation?.Messages?.map((i) => (
                  <div className="" ref={scrollRef} key={i._id}>
                    {i.sender === auth.user._id
                      ? OutgoingMsg(i.message, i.timestamp)
                      : IncommingMsg(i.message, i.timestamp)}
                  </div>
                ))
              : ""}
            {/* {IncommingMsg(
              "It is a long established fact that a reader will be distracted by the readable content of a page when looking "
            )} */}
          </div>
          <div className="ms-footer pt-0">
            <div
              style={{
                padding: ".7rem",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "25px",
              }}
            >
              <ul
                className="mb-0 ps-1"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  listStyle: "none",
                }}
              >
                <li style={{ flex: "1 1 auto" }}>
                  <input
                    type="text"
                    name="msg"
                    placeholder="Enter Message"
                    value={msg}
                    onChange={(e) => {
                      setMsg(e.target.value);
                    }}
                    fdprocessedid="mijkkyd"
                    spellcheck="false"
                    data-ms-editor="true"
                    style={{ border: "none", width: "100%" }}
                  />
                </li>
                <div>
                  {msg === "" ? (
                    <ul
                      className="d-flex  px-2"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,.1)",
                        listStyle: "none",
                      }}
                    >
                      <li className="me-3" style={{ cursor: "pointer" }}>
                        {" "}
                        <i className="bi bi-paperclip px-1 fs-4 "></i>
                      </li>
                      <li style={{ cursor: "pointer" }}>
                        {" "}
                        <i className="bi bi-camera-fill px-1 fs-4"></i>
                      </li>
                    </ul>
                  ) : (
                    <ul
                      className="d-flex  px-2"
                      style={{
                        borderLeft: "1px solid rgba(0,0,0,.1)",
                        listStyle: "none",
                      }}
                    >
                      <li
                        className="mx-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => sendMsg()}
                      >
                        {" "}
                        <i className="bi bi-send-fill px-1 fs-4 "></i>
                      </li>
                    </ul>
                  )}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
