import React, { useState } from "react";
// import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../Contex/AuthProvider";
import { Link, useNavigate } from "react-router-dom";

export const Login = () => {
  const [click, setClick] = useState(true);

  const sendmsg = async (msg) => {
    const registration = await navigator.serviceWorker.ready;
    registration.active.postMessage(msg);
  };
  function sendDataToNativeApp(data) {
    window.ReactNativeWebView.postMessage(data);
  }
  // console.log("process.env", process.env.REACT_APP_API);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (click) {
      try {
        setClick(false);
        const res = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/auth/login`,
          {
            email,
            password,
            ExpoToken: auth.ExpoToken,
          }
        );

        if (res.data.success) {
          // sendDataToNativeApp(res.data);
          toast.success(res.data && res.data.message);
          // console.log(res.data && res.data.message);
          setAuth({
            ...auth,
            user: res.data.user,
            token: res.data.token,
          });
          res.data.user.role === 1
            ? navigate("/Admin/Dashboard")
            : navigate("/");
          const lauth = {
            user: res.data.user,
            token: res.data.token,
          };

          const msg = {
            type: "Register",
            data:
              res.data.user.role === 0
                ? res.data.user._id
                : res.data.user.storeId,
          };
          if (!window.ReactNativeWebView) {
            sendmsg(JSON.stringify(msg));
          } else {
            sendDataToNativeApp(JSON.stringify(msg));
          }
          localStorage.setItem("auth", JSON.stringify(lauth));
        } else if (res.data.status === 404) {
          toast.error(res.data.message);
          // console.log(res.data.message);
        } else {
          toast.error(res.data.message);
          // console.log(res.data.message);
        }
        setClick(true);
      } catch (error) {
        setClick(true);
        // console.log(error);
        toast.error("Something went Wrong");
      }
    }
  };
  return (
    <div className="Deskcontainer d-flex justify-content-center flex-column">
      <h1 className="text-center  mt-3 mb-4 fs-1">
        Welcome to College Store Hub - SGU
      </h1>
      <div className="f-cont  p-4 ">
        <h2 className="text-center mt-2 mb-4 fw-bold">Login to Continue</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label fw-bold fs-5"
            >
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
              value={email}
              autoComplete="username"
              aria-describedby="emailHelp"
              required
            ></input>
            <div id="emailHelp" className="form-text"></div>
          </div>
          <div className="mb-1">
            <label
              htmlFor="exampleInputPassword1"
              className="form-label fw-bold fs-5"
            >
              Password
            </label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              id="exampleInputPassword1"
              autoComplete="current-password"
              required
            ></input>
          </div>
          <div className="d-flex justify-content-between link-cont my-3">
            <Link to="/CreateAccount" className="fw-bold">
              Create Account
            </Link>
{/* 
            <Link to="/ForgetPassword" className="fw-bold">
              Forget Password?
            </Link> */}
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="sub-btn fw-bolder fs-5 fw-bold"
              style={{ color: "white", backgroundColor: "#11074C" }}
            >
              {click ? (
                "Sign in"
              ) : (
                <div
                  className="spinner-border"
                  style={{ height: "25px", width: "25px" }}
                  role="status"
                />
              )}
            </button>
          </div>
          {/* <div className="orCont d-flex justify-content-center mt-3">
            <div className="or fw-bold">
              <hr></hr>
              OR
              <hr></hr>
            </div>
          </div> */}

          {/* <div className="mt-3 mb-3 d-flex justify-content-center ">
            <GoogleOAuthProvider clientId="447754552165-mc1bmq3qnb8hd4ps1bu4tqh3uis9dmdn.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  const decoded = jwtDecode(credentialResponse.credential);
                  const email = decoded.email;
                  const name = decoded.name;

                  const res = await axios.post(
                    `${process.env.REACT_APP_API}/api/v1/auth/Glogin`,
                    {
                      name,
                      email,
                    }
                  );
                  if (res && res.data.success) {
                    toast.success(res.data && res.data.message);
                    console.log(res.data && res.data.message);
                    setAuth({
                      ...auth,
                      user: res.data.user,
                      token: res.data.token,
                    });
                    console.log(res.data.user);
                    const lauth = {
                      user: res.data.user,
                      token: res.data.token,
                    };
                    localStorage.setItem("auth", JSON.stringify(lauth));
                  }
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div> */}
        </form>
      </div>
    </div>
  );
};
