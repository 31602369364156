import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Contex/AuthProvider";

export const Footer = () => {
  const [auth] = useAuth();
  // console.log(auth);

  return (
    <div
    // style={{
    //   height: "80px",
    // }}
    >
      {auth?.user?.role === 1 ? (
        <div className="footer">
          <ul className="nav justify-content-evenly">
            <li className="nav-item ">
              <NavLink
                to="/Admin/Dashboard"
                className="nav-link"
                aria-current="page"
                href="#"
              >
                <i className="bi bi-speedometer2"></i>
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Admin/MyStore" className="nav-link" href="#">
                <i className="bi bi-shop"></i>
                My Store
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Admin/Orders" className="nav-link" href="#">
                <i className="bi bi-receipt"></i>
                Orders
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Admin/Setting" className="nav-link" href="#">
                <i className="bi bi-gear"></i>
                Setting
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        <div className="footer">
          <ul className="nav justify-content-evenly">
            <li className="nav-item">
              <NavLink
                to="/"
                className="nav-link "
                aria-current="page"
                href="#"
              >
                <i className="bi bi-house-door-fill "></i>
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Favourite" className="nav-link" href="#">
                <i className="bi bi-heart"></i>
                Favourite
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Cart" className="nav-link" href="#">
                <i className="bi bi-circle-fill c1"></i>
                <i className="bi bi-circle-fill c2"></i>
                <i className="bi bi-cart3 c3"></i>
                <span className="cart-span ">Cart</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/Orders" className="nav-link" href="#">
                <i className="bi bi-receipt"></i>
                Orders
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`${
                  auth?.user?.role === 1 ? "/Dashboard/Admin" : "Profile"
                }`}
                className="nav-link"
              >
                <i className="bi bi-person-circle"></i>Profile{" "}
              </NavLink>
            </li>
          </ul>
        </div>
      )}

      {/* <footer className="container mx-auto py-16 px-3 md:px-0">
            {Ldata.user.name}
            <pre>{JSON.stringify(auth,null,4)}</pre>
            <h2>{data}</h2> 
            {console.log(Ldata.user.name)}
        </footer> */}
    </div>
  );
};
