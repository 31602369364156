import { Login } from "./Pages/Auth/Login";
import { CreateAccount } from "./Pages/Auth/CreateAccount";
import { Routes, Route } from "react-router-dom";
import Layout from "./Components/layout/Layout";
import { LandingPage } from "./Pages/LandingPage";
import { FStore } from "./Pages/FStore";
import { Cart } from "./Pages/User/Cart";
import { Favourite } from "./Pages/User/Favourite";
import { SOrders } from "./Pages/Store/SOrders";
import { COrders } from "./Pages/User/COrders";
import { Profile } from "./Pages/User/Profile";
import { Notifications } from "./Pages/User/Notifications";
import "./Style/Style.css";
import { FItems } from "./Pages/FItems";
import { StoreDashboard } from "./Pages/Store/StoreDashboard";
import AdminRoute from "./Routes/AdminRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import { AuthProvider } from "./Contex/AuthProvider";
import { MyStore } from "./Pages/Store/MyStore";
import { EditProduct } from "./Pages/Store/EditProduct";
import { CartProvider } from "./Contex/CartProvider";
import { FavProvider } from "./Contex/FavProvider";
import { CustomerMessages } from "./Pages/User/CustomerMessages";
import { ConvProvider } from "./Contex/ConversationProvider";
import { MessagesBody } from "./Pages/User/MessagesBody";
import { SocketProvider } from "./Contex/WebSocket";
import { ImageProvider } from "./Contex/AssetProvider";
import { EditProfile } from "./Pages/User/EditProfile";
import "react-loading-skeleton/dist/skeleton.css";
import { Setting } from "./Pages/Store/Setting";
import { PItems } from "./Pages/PItems";
import { PrivacyPolicies } from "./policies/PrivacyPolicies";
import { TermsAndConditions } from "./policies/TermsAndConditions";
import { CancellationRefundPolicies } from "./policies/CancellationRefundPolicies";
import { AboutUs } from "./policies/AboutUs";
import { ShippingPolicy } from "./policies/ShippingPolicies";
// import AdminLayout from "./Components/layout/AdminLayout"

function App() {
  return (
    // <StoreProvider>
    <SocketProvider>
      <AuthProvider>
        <ImageProvider>
          <FavProvider>
            <CartProvider>
              <ConvProvider>
                <Layout>
                  <Routes>
                    {/* <Route path="/Home" element={<LandingPage />} /> */}
                    <Route path="/CreateAccount" element={<CreateAccount />} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/" element={<FStore />} />
                    <Route path="/FoodStore/:id" element={<FItems />} />
                    <Route path="/PrintStore/:id" element={<PItems />} />
                    <Route path="/Cart" element={<Cart />} />
                    <Route
                      path="/Notification"
                      element={<Notifications state={"Profile"} />}
                    />
                    <Route
                      path="/PrivacyPolicies"
                      element={<PrivacyPolicies />}
                    />
                    <Route
                      path="/ShippingPolicies"
                      element={<ShippingPolicy />}
                    />
                    <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                    <Route path="/CancellationRefundPolicies" element={<CancellationRefundPolicies />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route path="/Customer" element={<PrivateRoute />}></Route>

                    <Route path="/Messages" element={<CustomerMessages />} />
                    <Route path="/Messages/:id" element={<MessagesBody />} />

                    <Route path="Home" element={<LandingPage />} />
                    <Route path="Profile" element={<Profile />} />
                    <Route
                      path="Profile/EditProfile"
                      element={<EditProfile />}
                    />
                    <Route path="Orders" element={<COrders />} />

                    <Route path="Favourite" element={<Favourite />} />

                    <Route path="/Admin" element={<AdminRoute />}>
                      <Route path="Setting" element={<Setting />} />
                      <Route
                        path="Setting/Notification"
                        element={<Notifications state={"Admin/Setting"} />}
                      />
                      <Route path="Dashboard" element={<StoreDashboard />} />
                      <Route path="MyStore" element={<MyStore />} />
                      <Route
                        path="MyStore/EditProduct/:id"
                        element={<EditProduct />}
                      />
                      <Route
                        path="MyStore/EditProduct/"
                        element={<EditProduct />}
                      />
                      <Route path="Orders" element={<SOrders />} />
                    </Route>
                  </Routes>
                </Layout>
              </ConvProvider>
            </CartProvider>
          </FavProvider>
        </ImageProvider>
      </AuthProvider>
    </SocketProvider>
  );
}

export default App;
