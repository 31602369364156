import React from "react";
import { Header } from "../../Components/Header/Header";
import { Orders } from "../../Components/Dashboard/Orders";

export const SOrders = () => {
  return (
    <div className="Deskcontainer" style={{ paddingBottom: "4rem" }}>
      <Header state={"Store Orders"} />
      {/* <div
        className="ms-panel ms-panel-fh mb-2"
        style={{
          padding: "1rem",
          border: "2px solid #11074C ",
          borderRadius: "5px",
        }}
      >
        <div className="ms-panel-header mb-4">
          <h3
            className="ps-1"
            style={{
              borderLeft: "10px solid #11074C",
              borderRadius: "5px",
              margin: "0",
            }}
          >
            Order Percentage
          </h3>
        </div>
        <div className="ms-panel-body order-circle">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-5 d-flex justify-content-center">
              <div
                className=" col-xl-10"
                style={{
                  padding: "1rem 0",
                  border: "2px solid #11074C ",
                  borderRadius: "5px",
                  backgroundColor: "#d3aca8",
                  boxShadow: "10px 10px 5px grey",
                }}
              >
                <h6 className="text-center fw-bold fs-5">Pizza</h6>
                <div className="progress-rounded progress-round-tiny">
                  <div className="progress-value">12%</div>
                  <svg>
                    <circle
                      className="progress-cicle bg-success animated"
                      cx="65"
                      cy="65"
                      r="57"
                      stroke-width="4"
                      fill="none"
                      aria-valuenow="56"
                      aria-orientation="vertical"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      role="slider"
                      style={{ strokeDashoffset: "219.183px" }}
                    ></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 d-flex justify-content-center">
              <div
                className=" col-xl-10"
                style={{
                  padding: "1rem 0",
                  border: "2px solid #11074C ",
                  borderRadius: "5px",
                  backgroundColor: "#d3aca8",
                  boxShadow: "10px 10px 5px grey",
                }}
              >
                <h6 className="text-center fw-bold fs-5">Mexican Noodels</h6>
                <div className="progress-rounded progress-round-tiny">
                  <div className="progress-value">38.8%</div>
                  <svg>
                    <circle
                      className="progress-cicle bg-primary animated"
                      cx="65"
                      cy="65"
                      r="57"
                      stroke-width="4"
                      fill="none"
                      aria-valuenow="38.8"
                      aria-orientation="vertical"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      role="slider"
                      style={{ strokeDashoffset: "219.183px" }}
                      // style={{stroke-dashoffset: "219.183px"}}
                    ></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 d-flex justify-content-center">
              <div
                className=" col-xl-10"
                style={{
                  padding: "1rem 0",
                  border: "2px solid #11074C ",
                  borderRadius: "5px",
                  backgroundColor: "#d3aca8",
                  boxShadow: "10px 10px 5px grey",
                }}
              >
                <h6 className="text-center fw-bold fs-5">Spicy Salad</h6>
                <div className="progress-rounded progress-round-tiny">
                  <div className="progress-value">78.8%</div>
                  <svg>
                    <circle
                      className="progress-cicle bg-secondary animated"
                      cx="65"
                      cy="65"
                      r="57"
                      stroke-width="4"
                      fill="none"
                      aria-valuenow="78.8"
                      aria-orientation="vertical"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      role="slider"
                      style={{ strokeDashoffset: "219.183px" }}
                    ></circle>
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 d-flex justify-content-center">
              <div
                className=" col-xl-10"
                style={{
                  padding: "1rem 0",
                  border: "2px solid #11074C ",
                  borderRadius: "5px",
                  backgroundColor: "#d3aca8",
                  boxShadow: "10px 10px 5px grey",
                }}
              >
                <h6 className="text-center fw-bold fs-5">French Fries</h6>
                <div className="progress-rounded progress-round-tiny">
                  <div className="progress-value">100%</div>
                  <svg>
                    <circle
                      className="progress-cicle bg-dark animated"
                      cx="65"
                      cy="65"
                      r="57"
                      stroke-width="4"
                      fill="none"
                      aria-valuenow="100"
                      aria-orientation="vertical"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      role="slider"
                      style={{ strokeDashoffset: "219.183px" }}
                    ></circle>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Orders />
    </div>
  );
};
