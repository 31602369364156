import React, { useEffect, useState } from "react";
import defaultProfile from "../../Components/Assets/blank-profile-pic.png";
import { useAuth } from "../../Contex/AuthProvider";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useImg } from "../../Contex/AssetProvider";
import { Header } from "../../Components/Header/Header";

export const Profile = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const logout = () => {
    const msg = {
      type: "UnRegister",
      data: auth?.user?._id,
    };
    sendmsg(JSON.stringify(msg));
    // console.log("logout");
    setAuth({
      ...auth,
      user: null,
      token: "",
    });

    localStorage.removeItem("auth");
    toast.success("Logout Successfull");
  };
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(auth.user?.ProfilePicture); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };

    fetchImage();
  }, [auth, getImage]);
  const sendmsg = async (msg) => {
    const registration = await navigator.serviceWorker.ready;
    registration.active.postMessage(msg);
  };

  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  function isAppple() {
    var userAgent = window.navigator.userAgent;
    var platform = window.navigator.platform;
    var os = null;

    if (platform.indexOf("Win") !== -1) {
      os = false;
    } else if (platform.indexOf("Mac") !== -1) {
      os = true;
    } else if (platform.indexOf("Linux") !== -1) {
      os = false;
    } else if (/Android/.test(userAgent)) {
      os = false;
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      os = true;
    }

    return os;
  }

  const handleInstallClick = () => {
    const isIos = isAppple();
    if (isIos) {
      if (window.navigator.standalone) {
        toast.success("App already installed");
      } else {
        toast.success("Please use 'Add to HomeScreen' to install the app");
      }
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    } else {
      window.location.reload();
    }
  };
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    // return () => {
    //   window.removeEventListener(
    //     "beforeinstallprompt",
    //     handleBeforeInstallPrompt
    //   );
    // };
  }, []);
  return (
    <div className="Deskcontainer pt-2" style={{ color: "#11074C" }}>
      <Header state={"Profile"} />
      <div
        className="profile m-3  row"
        style={{ backgroundColor: "white", borderRadius: "20px" }}
      >
        <div className="col-3 my-2">
          <img
            alt="profile"
            src={imgSrc ? imgSrc : defaultProfile}
            style={{ height: "80px", width: "80px", borderRadius: "50px" }}
          />
        </div>
        <div className="col my-3 text-center">
          <h4 className="fw-bold">
            {auth?.user?.name ? auth?.user?.name : "UserName"}
          </h4>
          {/* <h7>{auth?.user?.email ? auth?.user?.email : "Example@gmail.com"}</h7> */}
          <br />
          <div
            style={{
              color: "#11074C",
              fontWeight: "bold",
              borderBottom: "2px solid #11074C",
              display: "inline",
              cursor: "pointer",
            }}
            onClick={() =>
              auth.user ? navigate("/Profile/EditProfile") : navigate("/Login")
            }
          >
            Edit Profile
          </div>
        </div>
      </div>
      <div
        className="opt m-3"
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
        }}
      >
        <div
          className="row justify-content-center ms-0"
          style={{ width: "95%", cursor: "pointer" }}
          onClick={() => {
            navigate("/Orders");
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Order History</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-1 m-0 " />
        </div>
        {/* <div
          className="row justify-content-center"
          style={{ width: "95%" }}
          onClick={() => {
            auth?.user?.name ? navigate("/Messages") : navigate("/");
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Messages</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-1 m-0 " />
        </div> */}
        <div
          className="row justify-content-center ms-0 "
          style={{ width: "95%", cursor: "pointer" }}
          onClick={() => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            if (
              /iphone|ipad|ipod/.test(userAgent) &&
              !window.navigator.standalone
            ) {
              toast.success(
                "Please use 'Add to HomeScreen' to install the app and get notifications"
              );
            } else {
              navigate("/Notification");
            }
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Notificatoins</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-1 m-0 " />
        </div>
        <div
          className="row justify-content-center ms-0 "
          style={{ width: "95%", cursor: "pointer" }}
          onClick={handleInstallClick}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Install App</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-1 m-0 " />
        </div>
        <div
          className="row justify-content-center ms-0"
          style={{ width: "95%", cursor: "pointer" }}
          onClick={() => {
            navigate("/AboutUs");
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">About Us</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-1 m-0 " />
        </div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <div
              className="row justify-content-center ms-0 accordion-header accordion-button collapsed p-0 policy"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
              style={{ width: "95%", cursor: "pointer" }}
            >
              <div className="m-2 col-9 pb-1">
                <h3 className="fw-bold m-0">Policies</h3>
              </div>
              <div className="col fw-bold fs-1 text-end d-flex align-items-center">
                {">"}
              </div>
              <hr className="ms-1 m-0 " />
            </div>
            {/* <h2 class="accordion-header">
              <button
                class="accordion-button collapsed p-0 m-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <div className=" col-9">
                  <h3 className="fw-bold">Policies</h3>
                </div>
              </button>
            </h2> */}
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body p-1">
                <div
                  className="row justify-content-center ms-0"
                  style={{ width: "95%", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/ShippingPolicies");
                  }}
                >
                  <div className="m-1 ">
                    <h6 className="fw-bold m-1">
                      Shipping Policies
                    </h6>
                  </div>
                  <hr className="ms-1 m-0 " />
                </div>
                <div
                  className="row justify-content-center ms-0"
                  style={{ width: "95%", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/CancellationRefundPolicies");
                  }}
                >
                  <div className="m-1 ">
                    <h6 className="fw-bold m-1">
                      Cancelation & Refund Policies
                    </h6>
                  </div>
                  <hr className="ms-1 m-0 " />
                </div>
                <div
                  className="row justify-content-center ms-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/PrivacyPolicies");
                  }}
                >
                  <div className="m-1">
                    <h6 className="fw-bold m-1">Privacy Policies</h6>
                  </div>
                  <hr className="ms-1 m-0 " />
                </div>
                <div
                  className="row justify-content-center ms-0"
                  style={{ width: "95%", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/TermsAndConditions");
                  }}
                >
                  <div className="m-1">
                    <h6 className="fw-bold m-1">Terms and Conditions</h6>
                  </div>
                  <hr className="ms-1 m-0 " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          className="row justify-content-center ms-0"
          style={{ width: "95%" }}
          href="mailto:prithvirajindulkar2003@gmail.com?subject=Feedback"
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Send Feedback</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
        </a>
      </div>
      {auth?.user?.name ? (
        <div className="logoutBtn d-flex ">
          <button
            type="button"
            style={{
              backgroundColor: "#11074C",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
            className="btn m-auto px-5 w-75 fs-5 fw-bold"
            onClick={() => logout()}
          >
            Log Out
          </button>
        </div>
      ) : (
        <div className="loginBtn d-flex">
          <button
            type="button"
            className="btn m-auto px-5 w-75 fs-5 fw-bold"
            style={{
              backgroundColor: "#11074C",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
            onClick={() => navigate("/Login")}
          >
            Log in
          </button>
        </div>
      )}
      {/* <button
        className="mt-4 ms-1"
        onClick={() => {
          localStorage.removeItem("images");
          toast.success("all images cleared");
        }}
      >
        clear imges
      </button> */}
      {/* <button onClick={sendmsg}>send</button> */}
    </div>
  );
};
