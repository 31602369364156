import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../../Contex/AuthProvider";
import CryptoJS from "crypto-js";
import { useSocket } from "../../Contex/WebSocket";

export const Orders = () => {
  const [Sort, setSort] = useState("All");
  const [webSocket] = useSocket();
  const [printedFiles, setPrintedFiles] = useState([]);
  console.log("printedFiles", printedFiles);
  const [fileprinting, setFilePrinting] = useState({});
  const [page, setPage] = useState(1);
  const [OrdersPerPage, setOrdersPerPage] = useState(10);
  const [NewOrder, setNewOrder] = useState(null);
  const [init, setInit] = useState(page * OrdersPerPage - OrdersPerPage);

  // const [loading, setLoading] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [auth] = useAuth();
  //obtain orderId
  function objectIdToFourDigits(objectId) {
    // Create a hash of the ObjectId
    let hashed = CryptoJS.SHA256(objectId).toString();

    // Reduce the hash modulo 1000 to get a 3-digit number
    let number = parseInt(hashed, 16) % 1000;

    return number;
  }
  // Fetch orders data from the server
  const fetchData = useCallback(async () => {
    try {
      // setLoading(true);
      if (auth.user?.storeId) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/order/getStoreOrders/${auth.user?.storeId}`
        );
        // setLoading(false);
        if (res.data) {
          const array = res.data.orders;
          setAllOrders(array.slice().reverse());
        } else {
          // console.log(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [auth]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  //update order
  const UpdateOrderStatus = async (BuyerId, orderId, status, orderItems) => {
    // console.log("orderId", orderId);
    let index = allOrders.findIndex((item) => item._id === orderId);
    // console.log("inde", allOrders[index]);
    const updatedstatus =
      status === "In Process"
        ? "Prepared"
        : status === "Prepared"
        ? "Delhivered"
        : "";
    try {
      await axios.post(
        `${process.env.REACT_APP_API}/api/v1/order/UpdateOrder/${orderId}`,
        {
          status: updatedstatus,
          BuyerId: BuyerId,
          Order: orderItems,
        }
      );
      if (webSocket) {
        webSocket.emit("orderupdate", {
          buyerId: BuyerId,
          orderid: orderId,
          status: updatedstatus,
        });
        webSocket.emit("SendNotifyOrderUpdate", {
          buyerId: BuyerId,
          orderid: orderId,
          status: updatedstatus,
          items: orderItems,
        });
      }
      // console.log("Emit : ", {
      //   buyerId: Buyer,
      //   orderid: orderId,
      //   status: updatedstatus,
      // });
      setAllOrders((prevOrders) => {
        const updatedOrders = [...prevOrders];
        updatedOrders[index].status = updatedstatus;
        return updatedOrders;
      });
    } catch (error) {
      console.log(error);
    }
  };
  //websocket
  useEffect(() => {
    if (webSocket) {
      webSocket.on("NewOrder", (Order) => {
        // console.log("newOrder:", Order);
        toast.success("New Order");
        // console.log();
        setNewOrder(Order);
      });
    } else {
      // console.log("first");
    }
  }, [webSocket]);
  useEffect(() => {
    if (NewOrder) {
      const array = [NewOrder, ...allOrders];
      // console.log("new array :", array);
      setAllOrders(array);
      setNewOrder(null);
    }
  }, [NewOrder, allOrders]);
  // console.log(page);
  // console.log(init + OrdersPerPage);
  const sendNotification = async (id) => {
    const index = allOrders.findIndex((Orders) => Orders._id === id);
    toast.success(`Notification Sent `);
    // toast.success(`index ${index}`);
    await axios.post(
      `${process.env.REACT_APP_API}/api/v1/Notification/generate/${allOrders[index].BuyerId}`,
      {
        msg: `Order of ${allOrders[index].Items?.map(
          (i) => `${i?.ItmCtn} x ${i.details?.name}`
        )} is Prepared ,Please Collect it from store`,
      }
    );
    // console.log("res:", res);
  };
  const funPage = (num) => {
    setPage(num);
    setInit(num * OrdersPerPage - OrdersPerPage);
  };
  function convertTimestamp(timestamp) {
    let dateObj = new Date(timestamp);

    let options = { year: "2-digit", month: "short", day: "numeric" };

    let date = dateObj.toLocaleDateString("en-GB", options);
    let time = dateObj.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${date} , ${time}`;
  }
  return (
    <div
      className="d-flex flex-column m-2"
      style={{
        padding: "1rem",
        border: "2px solid #11074C ",
        borderRadius: "5px",
      }}
    >
      <div className="div d-flex flex-row justify-content-between mb-4">
        <h1
          className="ps-1 d-flex"
          style={{
            borderLeft: "10px solid #11074C",
            borderRadius: "5px",
            margin: "0",
            alignItems: "center",
          }}
        >
          Orders
        </h1>
        <div className="dropdown pe-4 d-flex flex-row align-items-center">
          <button
            className="btn btn-secondary dropdown-toggle ms-2 ps-3"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            // style={{width:"130px"}}
          >
            Filter
          </button>
          <ul className="dropdown-menu" style={{ width: "100px" }}>
            <li style={{ width: "100px" }}>
              <button
                className="dropdown-item "
                type="button"
                onClick={() => setSort("All")}
              >
                All
              </button>
            </li>
            <li style={{ width: "100px" }}>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setSort("In Process")}
              >
                In Process
              </button>
            </li>
            <li style={{ width: "100px" }}>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setSort("Prepared")}
              >
                Prepared
              </button>
            </li>
            <li style={{ width: "100px" }}>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => setSort("Delhivered")}
              >
                Delhivered
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="Orders pt-3" style={{ borderRadius: "20px" }}>
        <table className="table">
          <thead style={{ backgroundColor: "red" }}>
            <tr style={{ fontSize: "20px" }}>
              <th scope="col" className="text-center">
                Order Id
              </th>
              <th scope="col" className="text-center">
                Date
              </th>
              <th scope="col" className="text-center">
                Customer Name
              </th>
              <th scope="col" className="text-center">
                Items
              </th>
              <th scope="col" className="text-center">
                Amount
              </th>
              <th scope="col" className="text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {allOrders[0]
              ? allOrders
                  .filter((Orders) =>
                    Sort === "All" ? Orders : Orders.status === Sort
                  )
                  .slice(init, init + OrdersPerPage)
                  .map((order, index) => (
                    <tr style={{ fontSize: "17px" }} key={index}>
                      <th className="text-center" scope="row">
                        {objectIdToFourDigits(order._id)}
                      </th>
                      <td className="text-center">
                        {convertTimestamp(order.createdAt)}
                      </td>
                      <td className="text-center">{order.BuyerName}</td>
                      <td className="text-center">
                        {order.Items.map((i, index) => (
                          <p className=" mb-1" key={index}>
                            {i?.ItmCtn} x {i.details?.name}
                          </p>
                        ))}
                      </td>
                      <td className="text-center">
                        &#x20B9;{order.TotalPrice}j
                      </td>
                      <td className="text-center">
                        {order.status === "Delhivered" ? (
                          <h5>{order.status}</h5>
                        ) : (
                          <div className="d-flex justify-content-evenly">
                            <h5 style={{ width: "30%" }}>{order.status}</h5>
                            <button
                              type="button"
                              className="btn btn-primary w-40"
                              onClick={() => {
                                UpdateOrderStatus(
                                  order.Buyer,
                                  order._id,
                                  order.status,
                                  order.Items
                                );
                              }}
                            >
                              set
                              {order.status === "In Process"
                                ? " Prepared"
                                : order.status === "Prepared"
                                ? " Delhivered"
                                : ""}{" "}
                            </button>
                            {order.status === "Prepared" ? (
                              <i
                                className="bi bi-bell-fill text-center"
                                style={{
                                  fontSize: "22px",
                                  width: "20%",
                                }}
                                onClick={() => sendNotification(order._id)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
              : ""}
            {}
          </tbody>
        </table>
      </div> */}
      <div className="OrderBox d-flex flex-wrap justify-content-around">
        {allOrders[0] &&
          allOrders
            .filter((Orders) =>
              Sort === "All" ? Orders : Orders.status === Sort
            )
            .slice(init, init + OrdersPerPage)
            .map((order, index) =>
              order.Items.length > 0 ? (
                <div
                  className="order-card "
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    className="m-0 mb-2 fs-7 d-inline px-2 py-1"
                    style={{
                      backgroundColor: "#6c757d",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    {convertTimestamp(order.createdAt)}
                  </div>
                  <h4
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="m-0">
                      <b>Order ID :</b> {objectIdToFourDigits(order._id)}
                    </p>
                    {order?.Parcel ? (
                      <div
                        className="parcel fs-5 px-4 py-1"
                        style={{
                          backgroundColor: "#6c757d",
                          borderRadius: "20px",
                          color: "white",
                        }}
                      >
                        parcel
                      </div>
                    ) : null}
                    {order.status === "Prepared" && (
                      <>
                        <span>
                          <i
                            className="bi bi-bell-fill text-center"
                            style={{
                              fontSize: "22px",
                            }}
                            onClick={() => sendNotification(order._id)}
                          />
                        </span>
                      </>
                    )}
                  </h4>
                  <p style={{ marginBottom: "5px" }}>
                    <b>Buyer Name :</b> {order.BuyerName}
                  </p>
                  <p style={{ marginBottom: "5px" }}>
                    <b>Phone Number :</b> {order.Buyer.phone}
                  </p>
                  <div className="items">
                    <h5 style={{ marginBottom: "5px" }}>
                      <b>Items :</b>
                    </h5>
                    {order.Items.map((i, index) => (
                      <p
                        className="mb-1"
                        key={index}
                        style={{ marginLeft: "10px" }}
                      >
                        {i?.ItmCtn} x {i.details?.name}
                      </p>
                    ))}
                  </div>
                  {order.Instructions ? (
                    <div className="Instructions">
                      <h5 style={{ marginBottom: "5px" }}>
                        <b>Instructions :</b>
                      </h5>
                      <p style={{ marginLeft: "10px" }}>{order.Instructions}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {if (order.Instructions) {
                  return (
                    <div className="Instructions">
                      <h5 style={{ marginBottom: "5px" }}>
                        <b>Instructions :</b>
                      </h5>
                      <p style={{ marginLeft: "10px" }}>{order.Instructions}</p>
                    </div>
                  );
                }} */}
                  <p style={{ marginBottom: "5px" }}>
                    <b>Total Amount :</b> &#x20B9;{order.TotalPrice}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginBottom: "5px", marginRight: "5px" }}>
                      <b>Status :</b> {order.status}
                    </p>
                    {order.status === "Delhivered" ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-evenly">
                        <button
                          type="button"
                          className="btn w-40"
                          style={{ backgroundColor: "#11074C", color: "white" }}
                          onClick={() => {
                            UpdateOrderStatus(
                              order.BuyerId,
                              order._id,
                              order.status,
                              order.Items
                            );
                          }}
                        >
                          set
                          {order.status === "In Process"
                            ? " Prepared"
                            : order.status === "Prepared"
                            ? " Delhivered"
                            : ""}{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="order-card"
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    className="m-0 mb-2 fs-7 d-inline px-2 py-1"
                    style={{
                      backgroundColor: "#6c757d",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    {convertTimestamp(order.createdAt)}
                  </div>
                  <h4
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="m-0">
                      <b>Order ID :</b> {objectIdToFourDigits(order._id)}
                    </p>
                    {order?.Parcel ? (
                      <div
                        className="parcel fs-5 px-4 py-1"
                        style={{
                          backgroundColor: "#6c757d",
                          borderRadius: "20px",
                          color: "white",
                        }}
                      >
                        parcel
                      </div>
                    ) : null}
                    {order.status === "Prepared" && (
                      <>
                        <span>
                          <i
                            className="bi bi-bell-fill text-center"
                            style={{
                              fontSize: "22px",
                            }}
                            onClick={() => sendNotification(order._id)}
                          />
                        </span>
                      </>
                    )}
                  </h4>
                  <p style={{ marginBottom: "5px" }}>
                    <b>Buyer Name :</b> {order.BuyerName}
                  </p>
                  <p style={{ marginBottom: "5px" }}>
                    <b>Phone Number :</b> {order.Buyer.phone}
                  </p>
                  <div className="items">
                    <h5 style={{ marginBottom: "5px" }}>
                      <b>Files :</b>
                    </h5>
                    {order.Files.map((i, index) => (
                      <div className="ps-2 d-flex flex-row align-items-center pb-2">
                        <h5 className="mb-0">{index + 1} .</h5>
                        <h5 className="ps-2 mb-0">{i.name.split("_")[1]}</h5>
                        <button
                          className="btn  ms-3"
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "#11074C",
                            color: "white",
                            padding: "5px 10px",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          onClick={() => setFilePrinting(i)}
                        >
                          Print
                        </button>
                        {printedFiles.includes(i) ||
                        order.status !== "In Process" ? (
                          <h5 className="ms-2 mb-0" style={{ color: "green " }}>
                            {" "}
                            Printed
                          </h5>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1
                              class="modal-title fs-5"
                              id="staticBackdropLabel"
                            >
                              FileName : {fileprinting?.name?.split("_")[1]}{" "}
                            </h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <h3>Print Options :</h3>
                            <h5>
                              <b>Color : </b>
                              {fileprinting?.printOptions?.color}
                            </h5>
                            <h5>
                              <b>Sides : </b>
                              {fileprinting?.printOptions?.sides}
                            </h5>
                            <h5>
                              <b>Copies : </b>
                              {fileprinting?.printOptions?.copies}
                            </h5>
                            <h5>
                              <b>Binding : </b>
                              {fileprinting?.printOptions?.binding}
                            </h5>
                            <button
                              className="btn btn-primary w-100 pt-2"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_API}/api/v1/files/download/${fileprinting?.name}`,
                                  "_blank"
                                )
                              }
                            >
                              Open File{" "}
                            </button>
                          </div>
                          <div class="modal-footer">
                            <div className="d-flex flex-row w-100 justify-content-around align-items-center">
                              <h5 className="mb-0">Is the file Printed Successfully ? </h5>
                              <div>
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    setFilePrinting({});
                                  }}
                                >
                                  No
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary ms-3"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    setFilePrinting({});
                                    setPrintedFiles((prev) => [
                                      ...prev,
                                      fileprinting,
                                    ]);
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {order.Instructions ? (
                    <div className="Instructions">
                      <h5 style={{ marginBottom: "5px" }}>
                        <b>Instructions :</b>
                      </h5>
                      <p style={{ marginLeft: "10px" }}>{order.Instructions}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <p style={{ marginBottom: "5px" }}>
                    <b>Total Amount :</b> &#x20B9;{order.TotalPrice}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginBottom: "5px", marginRight: "5px" }}>
                      <b>Status :</b> {order.status}
                    </p>
                    {order.status === "Delhivered" ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-evenly">
                        <button
                          type="button"
                          className="btn w-40"
                          style={{ backgroundColor: "#11074C", color: "white" }}
                          onClick={() => {
                            let allUploaded = true;
                            for (let i = 0; i < order.Files.length; i++) {
                              if (!printedFiles.includes(order.Files[i])) {
                                console.log("not included",order.Files[i].name,printedFiles.includes(order.Files[i]))
                                allUploaded = false;
                                break;
                              }
                            }
                            if (allUploaded) {
                              UpdateOrderStatus(
                                order.BuyerId,
                                order._id,
                                order.status,
                                order.Items
                              );
                            } else {
                              toast.error("Please Print all files first");
                            }
                          }}
                        >
                          set
                          {order.status === "In Process"
                            ? " Prepared"
                            : order.status === "Prepared"
                            ? " Delhivered"
                            : ""}{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
      </div>
      <div className="pageNavigation d-flex flex-row justify-content-center">
        <div className="btn-group mx-3 align-items-center">
          <button
            type="button"
            className="btn dropdown-toggle ms-2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {OrdersPerPage}
          </button>
          <ul
            className="dropdown-menu px-2"
            style={{ minWidth: "fit-content", cursor: "pointer" }}
          >
            <li
              style={{ width: "fit-content" }}
              onClick={() => setOrdersPerPage(10)}
            >
              10
            </li>
            <li
              style={{ width: "fit-content" }}
              onClick={() => setOrdersPerPage(15)}
            >
              15
            </li>
            <li
              style={{ width: "fit-content" }}
              onClick={() => setOrdersPerPage(20)}
            >
              20
            </li>
          </ul>
        </div>
        <nav aria-label="Page d-flex align-items-center">
          <ul
            className="pagination justify-content-center align-items-center m-0"
            style={{ cursor: "pointer" }}
          >
            <li className="page-item">
              <p
                className="page-link mb-0"
                onClick={() => (page === 1 ? funPage(1) : funPage(page - 1))}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                {/* << */}
              </p>
            </li>
            <li className="page-item">
              <p
                className={`page-link mb-0 ${page === 1 ? "active" : ""}`}
                onClick={() => (page === 1 ? funPage(1) : funPage(page - 1))}
              >
                {page === 1 ? 1 : page - 1}
                {/* 1 */}
              </p>
            </li>
            <li className="page-item">
              <p
                className={`page-link mb-0 ${
                  page === 1 || page === Orders.length / OrdersPerPage
                    ? ""
                    : "active"
                }`}
                onClick={() =>
                  page === 1
                    ? funPage(2)
                    : page === Orders.length / OrdersPerPage
                    ? funPage(page - 1)
                    : ""
                }
              >
                {page === 1 ? 2 : page}
                {/* 2 */}
              </p>
            </li>
            <li className="page-item">
              <p
                className={`page-link mb-0 ${
                  page === Orders.length / OrdersPerPage ? "active" : ""
                }`}
                onClick={() =>
                  page === 1
                    ? funPage(3)
                    : page === Orders.length / OrdersPerPage
                    ? funPage(page)
                    : funPage(page + 1)
                }
              >
                {page === 1 ? 3 : page + 1}
                {/* 3 */}
              </p>
            </li>
            <li className="page-item">
              <p
                className="page-link mb-0"
                aria-label="Next"
                onClick={() =>
                  page === Orders.length / OrdersPerPage
                    ? funPage(page)
                    : funPage(page + 1)
                }
              >
                <span aria-hidden="true">&raquo;</span>
                {/* >> */}
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
