import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../Components/Header/Header";

export const ShippingPolicy = () => {
  return (
    <div
      className="Deskcontainer"
      style={{
        marginTop: "2rem",
        position: "relative",
        zIndex: 20,
        fontFamily: "Arial, sans-serif",
        fontSize: "1rem",
        lineHeight: "1.5",
        color: "#333",
      }}
    >
      <Header state={"Shipping Policy"} />
      <div className="ps-3 policy-back">
        <Link
          to="/Profile"
          className="d-inline back-btn"
          style={{ textDecoration: "none" }}
        >
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="mx-3 " style={{ paddingBottom: "4rem" }}>
        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          Shipping Policy
        </h2>
        <h3
          style={{
            marginTop: "1.5rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          1. No Delivery Service
        </h3>
        <p>
          College Campus Store Hub does not offer a delivery service for any
          goods ordered on the platform.
        </p>

        <h3
          style={{
            marginTop: "1.5rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          2. Order Collection
        </h3>
        <p>
          All orders must be collected directly from the respective stores on
          campus. After placing an order, customers will receive a confirmation
          with details on when and where to collect their items.
        </p>

        <h3
          style={{
            marginTop: "1.5rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          3. Collection Timeframe
        </h3>
        <p>
          Customers are advised to check the store's collection hours and pick
          up their orders within the specified timeframe. Failure to collect
          orders within the designated period may result in the order being
          canceled at the store's discretion.
        </p>

        <h3
          style={{
            marginTop: "1.5rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          4. Order Verification
        </h3>
        <p>
          Upon collection, customers must present their order Id. Stores may
          require additional verification to ensure the correct items are being
          handed over.
        </p>

        <h3
          style={{
            marginTop: "1.5rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          5. Contact Information
        </h3>
        <p>
          For any issues related to order collection, customers should directly
          contact the store from which they made the purchase.
        </p>

        <p>
          By using the College Campus Store Hub, customers agree to comply with
          this shipping policy and understand that all orders are for in-person
          collection only.
        </p>
      </div>
    </div>
  );
};
