import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../Components/Header/Header";

export const TermsAndConditions = () => {
  return (
    <div
      className="Deskcontainer"
      style={{
        marginTop: "2rem",
        position: "relative",
        zIndex: 20,
        fontFamily: "Arial, sans-serif",
        fontSize: "1rem",
        lineHeight: "1.5",
        color: "#333",
      }}
    >
      <Header state={"Terms"} />
      <div className="ps-3 policy-back">
        <Link
          to="/Profile"
          className="d-inline back-btn"
          style={{ textDecoration: "none" }}
        >
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="mx-3 " style={{ paddingBottom: "4rem" }}>
        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          1. Introduction
        </h2>
        <p>
          Welcome to sgu.collegestorehub.com ("we," "our," "us"). These Terms
          and Conditions ("Terms") govern your use of our website and services.
          By accessing or using our website, you agree to comply with and be
          bound by these Terms. If you do not agree with any part of these
          Terms, you must not use our website.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          2. User Accounts
        </h2>
        <p>
          To use certain features of our website, you may need to create an
          account. You are responsible for maintaining the confidentiality of
          your account information, including your password, and for all
          activities that occur under your account.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          3. Use of the Website
        </h2>
        <p>
          You agree to use our website only for lawful purposes and in
          accordance with the Terms.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          4. Orders and Payments
        </h2>
        <p>
          All orders placed through our website are subject to acceptance by the
          respective stores. The stores are responsible for processing payments
          and fulfilling orders. We do not guarantee the availability or quality
          of any products or services listed on our website.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          5. Cancellation and Refunds
        </h2>
        <p>
          Once an order is placed, it cannot be canceled via the website
          platform. Users must contact the store directly to cancel their order
          and request a refund. The store's refund policy will apply.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          6. Intellectual Property
        </h2>
        <p>
          All content on our website, including text, graphics, logos, images,
          and software, is our property or the property of our licensors and is
          protected by copyright and other intellectual property laws. You may
          not use, reproduce, distribute, or create derivative works of our
          content without our express written permission.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          7. Limitation of Liability
        </h2>
        <p>
          To the fullest extent permitted by law, we will not be liable for any
          indirect, incidental, special, consequential, or punitive damages, or
          any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses, resulting from your use of our website or services.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          8. Changes to These Terms
        </h2>
        <p>
          We may update these Terms from time to time. We will notify you of any
          changes by posting the new Terms on our website. You are advised to
          review these Terms periodically for any changes. Your continued use of
          the website after any changes to these Terms will constitute your
          acknowledgment and acceptance of the changes.
        </p>

        <p>
          If you have any questions, suggestions, or feedback, we would love to
          hear from you! <br />
          Please contact us at : <br />
          <br />
          <a href="tel:+919307655505"> +91 9307655505</a> <br />
          <a href="mailto:prithvirajindulkar2003@gmail.com">
            {" "}
            prithvirajindulkar2003@gmail.com
          </a>
          <br />
          Address : Incubation Center ,Sanjay Ghodawat University, Kolhapur,
          Maharashtra, India.
        </p>
      </div>
    </div>
  );
};
