import React from "react";
export const Header = (props) => {
  return (
    <div
      style={{
        height: "30px",
        zIndex: "2",
        position: "fixed",
        color: " #11074C",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "0%",
          left: "0%",
          borderTop: "40px solid #FBAD02",
          width: "100%",
        }}
      ></div>
      <div className="top-bar ">
        <h1
        className="headerTitle"
          style={{
            position: "fixed",
            top: ".5%",
            left: "2%",
            fontFamily: "sans-serif",
            fontSize: "29px",
          }}
        >
          {props.state}
        </h1>
        {/* <Link to={"/Notification"}>
          <i
            className="bi bi-bell-fill"
            style={{
              position: "fixed",
              top: "1%",
              right: "2%",
              fontSize: "22px",
            }}
          ></i>
        </Link> */}
      </div>
    </div>
  );
};
