import React from "react";

export const Rating = (props) => {
  const ratingstar = Array.from({ length: 5 }, (elem, index) => {
    let number = index + 0.5;
    return (
      <div
        className="rating-star"
        style={{ color: "#ffc107", paddingRight: "2px" }}
        key={index}
      >
        {props.rating >= index + 1 ? (
          <i className="bi bi-star-fill"></i>
        ) : props.rating >= number ? (
          <i className="bi bi-star-half"></i>
        ) : (
          <i className="bi bi-star"></i>
        )}
      </div>
    );
  });
  return (
    <div>
      <div className="icon-style d-flex ">{ratingstar}</div>
      {props.rev
        ? props.ratecount
          ? props.ratecount + "Ratings"
          : 0 + "Ratings"
        : ""}
      {}
    </div>
  );
};
