import React, { useState } from "react";
import { useCart } from "../../Contex/CartProvider";

export const CartItemCard = ({ i }) => {
  const [cart, setCart] = useCart();
  //   console.log(cart);

  const [ItmCtn, setItmCtn] = useState(
    cart[
      cart.findIndex((obj) => {
        return obj.details._id === i.details._id;
      })
    ]?.ItmCtn
      ? cart[
          cart.findIndex((obj) => {
            return obj.details._id === i.details._id;
          })
        ]?.ItmCtn
      : 0
  );
  //   console.log(
  //     cart[
  //       cart.findIndex((obj) => {
  //         return obj.details._id === i.details._id;
  //       })
  //     ]
  //   );
  const chgQnt = (s) => {
    let mycart = [...cart];
    let index = mycart.findIndex((obj) => obj.details._id === i.details._id);
    if (s === "+") {
      setItmCtn(ItmCtn + 1);
      if (index !== -1) {
        mycart[index].ItmCtn += 1;
      } else {
        mycart.push({ details: i.details, ItmCtn: 1 });
      }
    } else {
      if (ItmCtn > 1) {
        setItmCtn(ItmCtn - 1);
        mycart[index].ItmCtn -= 1;
      } else {
        mycart.splice(index, 1);
      }
    }
    setCart(mycart);
    localStorage.setItem("cart", JSON.stringify(mycart));
  };

  return (
    <div>
      <li className="d-flex flex-row align-items-center">
        <p className="mb-1 fs-5 w-50">{i.details?.name}</p>
        <div
          className="btn-group add-btn w-25 me-2"
          role="group"
          aria-label="Basic mixed styles example"
          style={{
            height: "30px",
            width: "20%",
          }}
        >
          <button
            type="button"
            style={{
              padding: "1px 3px",
              borderRadius: "10px 0 0 10px",
              background: "rgba(170, 172, 173, 0.8)",
            }}
            className=" btn "
            onClick={(e) => {
              chgQnt("-");
            }}
          >
            <b>-</b>
          </button>
          <button
            type="button"
            className="btn"
            style={{
              padding: "1px 3px",
              background: "rgba(170, 172, 173, 0.8)",
            }}
          >
            <b>{ItmCtn}</b>
          </button>
          <button
            type="button"
            style={{
              padding: "1px 3px",
              borderRadius: "0 10px 10px 0",
              background: "rgba(170, 172, 173, 0.8)",
            }}
            className="btn"
            onClick={(e) => {
              chgQnt("+");
            }}
          >
            <b>+</b>
          </button>
        </div>
        <p className="mb-1 fs-5 " style={{ width: "30%" }}>
          {ItmCtn} x &#x20B9;{i.details.price}
        </p>
      </li>
    </div>
  );
};
