import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../Components/Header/Header";
import toast from "react-hot-toast";
import { useAuth } from "../../Contex/AuthProvider";
import axios from "axios";
export const Notifications = ({state}) => {
  console.log(state)
  // const [Notifications] = useState();
  // const NotificationCard = (header, Msg, Time, unread) => {
  //   return (
  //     <div className="p-3">
  //       <div className="toast-header">
  //         <strong className="me-auto">{header}</strong>
  //         <small>{Time}</small>
  //         <button
  //           type="button"
  //           className="btn-close ms-2"
  //           data-bs-dismiss="toast"
  //           aria-label="Close"
  //         ></button>
  //       </div>
  //       <div className="toast-body">{Msg}</div>
  //     </div>
  //   );
  // };
  const [notificationPermission, setnotificationPermission] = useState(
    Notification.permission
  );
  const [serviceWorker, setServiceWorker] = useState();
  const [auth] = useAuth();
  const [Subscribe, setSubscribe] = useState(false);
  // console.log("serviceworker", serviceWorker);
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    // console.log("reg : ", registrations);
    if (!!registrations && registrations.length > 0) {
      setServiceWorker(true);
    } else {
      setServiceWorker(false);
    }
  });
  const registerSW = async () => {
    await navigator?.serviceWorker?.register("sw.js");
    // console.log("REgestraition:", registration);
  };
  const sendmsg = async (msg) => {
    const registration = await navigator.serviceWorker.ready;
    registration.active.postMessage(msg);
  };
  return (
    <div className="Deskcontainer py-3">
      <Header state={"Notifications Guide"} />

      {/* <div className="w-90 ">
        {Notifications ? (
          NotificationCard("Order Update", "dnvjhdihdfdhjf", "10:30", true)
        ) : (
          <div className="text-center pt-5 p-3 d-flex justify-content-center flex-column">
            <h2>No Notifications for you</h2>
            <button
              onClick={() => {
                if ("serviceWorker" in navigator) {
                  navigator.serviceWorker
                    .getRegistrations()
                    .then(function (registrations) {
                      for (let registration of registrations) {
                        registration.unregister();
                      }
                    });
                }
              }}
            >
              Reset Service Worker
            </button>
              <button onClick={async()=>{
                const permission =await  Notification.requestPermission();
                toast.success(permission)
                console.log(permission);
              }}>
              request permission
              </button>
          </div>
        )}
      </div> */}
      <Link to={"/"+state} className="d-inline back-btn ms-3">
        <b>
          <i className="bi bi-arrow-left"></i> Back
        </b>
      </Link>
      <div className="accordion m-3 noti-accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header ">
            <button
              className="accordion-button fs-5 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-controls="panelsStayOpen-collapseOne"
            >
              {" "}
              <div
                className="d-flex flex-row justify-content-between align-items-center"
                style={{ width: "90%" }}
              >
                <p className="mb-0">Notification Permission</p>
                {notificationPermission ? (
                  notificationPermission === "default" ? (
                    <i
                      className="bi bi-hourglass-split ms-3 p-1"
                      style={{
                        backgroundColor: "#11074C",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    ></i>
                  ) : notificationPermission === "granted" ? (
                    <i
                      className="bi bi-check-lg ms-3 p-1"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    ></i>
                  ) : (
                    <i
                      className="bi bi-x-lg ms-3 p-1"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    ></i>
                  )
                ) : (
                  ""
                )}
              </div>
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse px-2 py-4"
          >
            Check Notification permission :{" "}
            <button
              style={{ border: "1px solid #11074C", borderRadius: "10px" }}
              onClick={async () => {
                const permission = Notification.permission;
                setnotificationPermission(permission);
                toast.success("Notification permission :" + permission);
                // console.log(permission);
              }}
            >
              Check
            </button>
            {notificationPermission === "denied" ||
            notificationPermission === "default" ? (
              <div>
                <br />
                <p className="mb-0">
                  {notificationPermission === "denied" ? (
                    `Notification Permission is ${notificationPermission} ,Please allow notifications for this site from your browser settings.`
                  ) : (
                    <div>
                      Notification Permission is {notificationPermission}{" "}
                      <button
                        style={{
                          border: "1px solid #11074C",
                          borderRadius: "10px",
                        }}
                        onClick={async () => {
                          const permission =
                            await Notification.requestPermission();
                          setnotificationPermission(permission);
                          toast.success(
                            "Notification permission :" + permission
                          );
                          // console.log("per", permission);
                        }}
                      >
                        Request Notification Permission
                      </button>
                    </div>
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header ">
            <button
              className="accordion-button fs-5 collapsed d-flex justify-content-between"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panels2"
              aria-controls="panels2"
            >
              <div
                className="div d-flex flex-row justify-content-between align-items-center"
                style={{ width: "90%" }}
              >
                <p className="mb-0">Service Worker</p>
                {serviceWorker ? (
                  <i
                    className="bi bi-check-lg ms-3 p-1"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  ></i>
                ) : (
                  <i
                    className="bi bi-x-lg ms-3 p-1"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  ></i>
                )}
              </div>
            </button>
          </h2>
          {!serviceWorker ? (
            <div id="panels2" className="accordion-collapse collapse px-2 py-4">
              Register Service Worker :{" "}
              <button
                style={{ border: "1px solid #11074C", borderRadius: "10px" }}
                onClick={async () => {
                  await registerSW();
                  setServiceWorker(true)
                }}
              >
                Register
              </button>
            </div>
          ) : (
            <div id="panels2" className="accordion-collapse collapse px-2 py-4">
              Service Worker is Registered
            </div>
          )}
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header ">
            <button
              className="accordion-button fs-5 collapsed d-flex justify-content-between align-items-center"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panels3"
              aria-controls="panels3"
            >
              <div
                className="div d-flex flex-row justify-content-between"
                style={{ width: "90%" }}
              >
                <p className="mb-0">Subscription</p>
              </div>
            </button>
          </h2>
          {!Subscribe ? (
            <div id="panels3" className="accordion-collapse collapse px-2 py-4">
              Subscribe for Push Notifications : {" "}
              <button
                style={{ border: "1px solid #11074C", borderRadius: "10px" }}
                onClick={() => {
                  if (notificationPermission === "denied"||notificationPermission==="default") {
                    // toast.success(notificationPermission);
                    toast.error("Grant Notification Permission first");
                  }
                  else if (!serviceWorker) {
                    toast.error("Register Service Worker first");
                  }
                  else if(!auth?.user?._id){
                    toast.error("Please Log in first to Subscribe for Push Notification")
                  }
                  else if (
                    auth?.user?._id &&
                    notificationPermission === "granted" &&
                    serviceWorker
                  ) {
                    const msg = {
                      type: "Register",
                      data:
                        auth?.user?.role === 0
                          ? auth?.user?._id
                          : auth?.user?.storeId,
                    };

                    if (!window.ReactNativeWebView) {
                      sendmsg(JSON.stringify(msg));
                      setSubscribe(true);
                      toast.success("Subscribed for push notification");
                    }
                  }
                }}
              >
                Subscribe
              </button>
            </div>
          ) : (
            <div id="panels3" className="accordion-collapse collapse px-2 py-4">
              Send Test Notifications : {" "}
              <button
                style={{ border: "1px solid #11074C", borderRadius: "10px" }}
                onClick={() => {
                  axios.post(
                    `${
                      process.env.REACT_APP_API
                    }/api/v1/Notification/generate/${
                      auth?.user?.role === 0 ? auth?.user?._id : auth?.user?.storeId
                    }`,
                    {
                      msg: "Test Message",
                    }
                  );
                  toast.success(
                    "If Notification is not received Report a Issue"
                  );
                  toast.success("Test Notification Sent Successfully! ");
                }}
              >
                Test
              </button>
            </div>
          )}
        </div>
      </div>
      {/* <h3 className="text-center pb-4 pt-3">To Enable Push Notifications</h3> */}
      {/* <div className="ps-3 ">
      <div className="d-flex flex-row">
      <h4 style={{ width: "30%" }}>
      <b>Step 1 :</b>{" "}
      </h4>
          <h5>Give notification permission to website</h5>
        </div>
      </div>
      <div className="w-100 text-center pb-3">
        <button
          style={{ border: "1px solid #11074C", borderRadius: "10px" }}
          onClick={async () => {
            const permission = await Notification.requestPermission();
            toast.success("Notification permission " + permission);
            // console.log(permission);
          }}
        >
          Check Notification permission
        </button>
      </div>
      <div className="ps-3 pb-3">
        <div className="d-flex flex-row">
          <h4 style={{ width: "30%" }}>
            <b>Step 2 :</b>{" "}
          </h4>
          <h5>Logout Current Account</h5>
        </div>
      </div>
      <div className="ps-3 pb-3">
        <div className="d-flex flex-row">
          <h4 style={{ width: "30%" }}>
            <b>Step 3 :</b>{" "}
          </h4>
          <h5>Log In Again</h5>
        </div>
      </div> */}
    </div>
  );
};
