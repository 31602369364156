import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Itemcard } from "../Components/cards/Itemcard";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export const FItems = () => {
  const params = useParams();
  const [store, setStore] = useState({});
  const [activeCat, setActiveCat] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  //   console.log("store", store);
  // console.log("items", items);
  const fetchData = async () => {
    try {
      setLoading(true);
      if (params.id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${params.id}`
        );
        if (res.data.success) {
          setStore(res.data.store);
          setActiveCat(res.data.store.catogaries[0]);
          // console.log("res:", res.data);
          setItems(res.data.Items);
          setLoading(false);
        } else {
          // console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  // const isOpen = () => {
  //   const now = new Date();
  //   const hours = now.getHours().toString().padStart(2, "0");
  //   const minutes = now.getMinutes().toString().padStart(2, "0");
  //   const currentTime = `${hours}:${minutes}`;
  //   console.log("time:",currentTime)
  // };
  // isOpen();
  function isOpen(openTime, closeTime) {
    const now = new Date();
    const open = new Date(now);
    const close = new Date(now);

    open.setHours(openTime.split(":")[0], openTime.split(":")[1], 0, 0);
    close.setHours(closeTime.split(":")[0], closeTime.split(":")[1], 0, 0);

    return now >= open && now < close;
  }

  const currentlyOpen = isOpen(String(store.OpenTime), String(store.CloseTime));
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeCategoryElement = scrollContainerRef.current.querySelector(
        `.scrollItem[data-category="${activeCat}"]`
      );
      if (activeCategoryElement) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const activeCategoryOffset = activeCategoryElement.offsetLeft;
        const activeCategoryWidth = activeCategoryElement.offsetWidth;
        const scrollLeft =
          activeCategoryOffset - (containerWidth - activeCategoryWidth) / 2;
        scrollContainerRef.current.scrollLeft = scrollLeft;
      }
    }
  }, [activeCat]);
  // const [visibleItems, setVisibleItems] = useState(5);
  // const handleScroll = (e) => {
  //   const { scrollTop, clientHeight, scrollHeight } = e.target;
  //   console.log(scrollTop, clientHeight, scrollHeight)
  //   if (scrollHeight - scrollTop > clientHeight-2) {
  //     setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  //   }
  // };
  return (
    <div className="Deskcontainer" style={{ marginTop: "2rem" }}>
      {/* <Header /> */}
      <div className=" ps-3 ">
        <Link to="/" className="d-inline back-btn">
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      {loading ? (
        <SkeletonTheme
          baseColor="rgb(205 213 220 / 64%)"
          highlightColor="white"
        >
          <div className="mx-4">
            <div className="storeInfo my-3 mx-5 ">
              <h1 className="storeName mb-0">
                <Skeleton width={"100px"} />
              </h1>
              <h5 className="mb-1">
                <Skeleton count={2} width={"150px"} />
              </h5>
              <div className="rating">
                <Skeleton width={"100px"} />
              </div>
            </div>
            <hr />
            <div className="text-center d-flex flex-column">
              <h5 className="mb-1 text-center mx-5">
                <b>
                  <Skeleton />
                </b>{" "}
              </h5>
              <p className="my-2">
                <Skeleton count={2} />
              </p>
            </div>
            <hr />
            <h1 className="text-center mx-5">
              <Skeleton width={"90px"} />
            </h1>
            <div className="menu m-2">
              <Skeleton count={5} />
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="" style={{ paddingBottom: "100px" }}>
          <div className="storeInfo w-100 pt-3 text-center">
            <h1 className="storeName mb-0">{store.StoreName} </h1>
            <h5 className="mb-1">
              <i className="bi bi-dot fs-3"></i>
              {store.Spec1} <i className="bi bi-dot fs-3"></i>
              {store.Spec2}
            </h5>
            {/* <div
              className="rating "
              style={{
                width: "fit-content",
                padding: "0px 10px",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            >
              <Rating rating={store.rating} ratecount={"334"} />
            </div> */}
          </div>
          <hr></hr>
          <div className="text-center d-flex flex-column">
            <h5 className="mb-1 text-center">
              <b>Open Time </b>{" "}
              <p className="my-2">
                {store.OpenTime} to {store.CloseTime}{" "}
              </p>
            </h5>
            <div className="text-center d-flex justify-content-center">
              <div
                className=" d-inline d-flex text-center fw-bold"
                style={{
                  padding: "2px 10px",
                  borderRadius: "20px",
                  backgroundColor: `${currentlyOpen ? "green" : "red"}`,
                  fontSize: "15px",
                  color: "white",
                  justifyContent: "center",
                  width: "fit-content",
                }}
              >
                {currentlyOpen ? "Currently Open" : "Currently Closed"}
              </div>
            </div>
          </div>
          <hr></hr>
          {/* <h1 className="text-center">Menu</h1> */}
          <div className="">
            <div
              className="scrollbar p-2  m-2"
              style={{
                display: "flex",
                overflowX: "auto",
                whiteSpace: "nowrap",
                scrollbarWidth: "0",
                borderRadius: "10px",
              }}
              ref={scrollContainerRef}
            >
              {/* <div
              className="scrollItem fs-5 mx-1 p-1 px-3"
              onClick={() => {
                setActiveCat("Fav");
              }}
              data-category="All"
              style={{
                flex: "0 0 auto",
                backgroundColor: `${activeCat === "Fav" ? "#909ca7" : "white"}`,
                fontWeight: `${activeCat === "All" ? "bold" : ""}`,
                borderRadius: "15px",
              }}
            >
              Favourite
            </div> */}
              {store.catogaries?.map((category, index) => (
                <div
                  key={index}
                  className="scrollItem fs-5 mx-1 p-1 px-2 "
                  onClick={() => {
                    setActiveCat(category);
                  }}
                  data-category={category}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    fontWeight: `${activeCat === category ? "" : "bold"}`,
                    flex: "0 0 auto",
                    color: `${activeCat === category ? "white" : "#11074C"}`,
                    // fontWeight: `${activeCat === category ? "bold" : ""}`,
                    borderRadius: "15px",
                    backgroundColor: `${
                      activeCat === category ? "#11074C" : "white"
                    }`,
                  }}
                >
                  {category}
                </div>
              ))}
            </div>
            <p className="mb-2 text-center">
              * Images are only for representation
            </p>

            <div
              className="cont menu m-2 pb-5 mt-0 mb-2 p-2"
              // onScroll={handleScroll}
              style={{}}
            >
              {items
                ?.filter((i) => i.catogary === activeCat)
                .map((i) => (
                  <Itemcard Id={i._id} items={i} key={i._id} />
                ))}
              {/* Existing code */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
