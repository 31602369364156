import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../Contex/AuthProvider";
import toast from "react-hot-toast";
import { useImg } from "../../Contex/AssetProvider";
import Skeleton from "react-loading-skeleton";
export const EditStoreDetails = () => {
  const [name, setName] = useState("");
  const [Spec1, setSpec1] = useState("");
  const [Spec2, setSpec2] = useState("");
  const [openTime, setOpenTime] = useState("00:00");
  const [closeTime, setCloseTime] = useState("00:00");
  const [PrepTime, setPrepTime] = useState("");
  const [category, setCategory] = useState([]);
  const [StoreImg, setStoreImg] = useState("");
  const [newImg, setNewImg] = useState(null);
  const [newImgUrl, setNewImgUrl] = useState(null);
  const [auth] = useAuth();
  // const [store, setStore] = useState([]);
  // console.log("store", store);
  // console.log(loading);

  const fetchData = async () => {
    try {
      if (auth?.user.storeId) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${auth?.user.storeId}`
        );
        // console.log("res:", res.data);
        if (res.data.store) {
          setName(res.data.store.StoreName);
          setSpec1(res.data.store.Spec1);
          setSpec2(res.data.store.Spec2);
          setOpenTime(res.data.store.OpenTime);
          setCloseTime(res.data.store.CloseTime);
          setPrepTime(res.data.store.PrepTime);
          setStoreImg(res.data.store.img);
          setCategory(res.data.store.catogaries);
          // console.log("res:", res.data.store);
        } else {
          // console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [auth]);
  const UpdateStore = async () => {
    let newimgId = null;
    // console.log(key);
    if (newImg) {
      const formData = new FormData();
      formData.append("Name", "StoreImg");
      formData.append("img", newImg);
      // console.log("formdata : ", newImg);
      newimgId = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/assets/ReplaceImg/${StoreImg}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    try {
      await toast.promise(
        axios.post(
          `${process.env.REACT_APP_API}/api/v1/store/updatestore/${auth?.user.storeId}`,
          {
            StoreName: name,
            Spec1: Spec1,
            Spec2: Spec2,
            OpenTime: openTime,
            catogaries: category,
            CloseTime: closeTime,
            PrepTime: PrepTime,
            img: newimgId ? newimgId.data.ImgId : StoreImg,
          }
        ),
        {
          loading: "Updating store details...",
          success: "Details Updated Successfully",
          error: "Failed to update store details",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(StoreImg); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };

    fetchImage();
  }, [StoreImg,getImage]);

  return (
    <div>
      <div className="StoreDetails text-center">
        <h1>
          <b>Store Details</b>
        </h1>
        <div className=" change-Img w-100 my-4">
          <div
            className=" storeImage w-100 d-flex align-items-center justify-content-center p-0 mb-4"
            style={{ position: "relative" }}
          >
            {imgSrc ? (
              <img
              className="storeImg"
                src={newImgUrl ? newImgUrl : imgSrc}
                alt=""
                style={{
                  display: "block",
                  borderRadius: "20px",
                }}
              />
            ) : (
              <Skeleton
                style={{
                  width: "90%",
                  display: "block",
                  borderRadius: "20px",
                }}
              />
            )}

            <div className="edit d-flex justify-content-center align-items-center">
              <i className="bi bi-pen me-2"></i>
              <h2 style={{ borderBottom: "2px solid #11074C" }}>Edit Img</h2>
              <input
                type="file"
                className="storeImg"
                accept=".png,.jpg,.jpeg"
                id="inputGroupFile01"
                onChange={(e) => {
                  setNewImgUrl(URL.createObjectURL(e.target.files[0]));
                  setNewImg(e.target.files[0]);
                }}
              ></input>
            </div>
            {/* <button
              className="p-1 change-btn fw-bold"
              style={{
                width: "90%",
                border: "none",
                opacity: 0.6,
                position: "absolute",
                bottom: "0",
                borderRadius: "0px 0px 20px 20px ",
              }}
            >
              Change Image
            </button> */}
          </div>
          <div className="change-Details d-flex justify-content-center">
            <form className="needs-validation clearfix" noValidate="" style={{width:"80%"}}>
              <div className="form-row mx-2">
                <div className=" mb-3 d-flex ">
                  <label
                    htmlFor="Name"
                    className="col-md-4"
                    style={{ width: "40%" }}
                  >
                    Name :
                  </label>
                  <div className="input-group w-50">
                    <input
                      type="text"
                      className="form-control "
                      id="Name"
                      placeholder="Product Name"
                      required=""
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                  </div>
                </div>
                <div className="mb-3 d-flex ">
                  <label
                    htmlFor="spes1"
                    className="col-md-4"
                    style={{ width: "40%" }}
                  >
                    Speciality 1 :
                  </label>
                  <div className="input-group w-50">
                    <input
                      type="text"
                      className="form-control col-md-4"
                      id="spes1"
                      placeholder="Speciality 1"
                      required=""
                      value={Spec1}
                      onChange={(e) => setSpec1(e.target.value)}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <label
                    htmlFor="spes2"
                    className="col-md-4"
                    style={{ width: "40%" }}
                  >
                    Speciality 2 :
                  </label>
                  <div className="input-group w-50">
                    <input
                      type="text"
                      className="form-control col-md-4"
                      id="spes2"
                      placeholder="Speciality 2"
                      required=""
                      value={Spec2}
                      onChange={(e) => setSpec2(e.target.value)}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                  </div>
                </div>
              </div>
              <div className="form-row pt-3">
                <div className=" mb-3 d-flex ">
                  <label htmlFor="openTime" className="col-md-4 ">
                    {" "}
                    Open Time:{" "}
                  </label>
                  <div
                    className="input-group align-items-center"
                    style={{ width: "80%" }}
                  >
                    <input
                      type="time"
                      className="form-control col-md-12 mx-2"
                      id="openTime"
                      placeholder="Product Name"
                      required=""
                      value={openTime}
                      onChange={(e) => {
                        setOpenTime(e.target.value);
                        // console.log(e.target.value);
                      }}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                    To
                    <input
                      type="time"
                      className="form-control col-md-12 mx-2"
                      id="openTime"
                      required=""
                      value={closeTime}
                      onChange={(e) => setCloseTime(e.target.value)}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                  </div>
                </div>

                <div className=" mb-3 d-flex">
                  <label htmlFor="PrepTime" className="col-md-4 w-50">
                    Preperation Time :
                  </label>
                  <div
                    className="input-group ccol-md-10 "
                    style={{ width: "40%" }}
                  >
                    <input
                      type="text"
                      className="form-control col-md-4"
                      id="PrepTime"
                      placeholder="eg. 10-15"
                      required=""
                      value={PrepTime}
                      onChange={(e) => setPrepTime(e.target.value)}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                    <span className="input-group-text" id="basic-addon2">
                      Min
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={() => UpdateStore()}
          >
            Update Store Details
          </button>
        </div>
      </div>
    </div>
  );
};
