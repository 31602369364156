import React from "react";
import FoodImg from "../Components/Assets/FoodLogo.png";
import PrintImg from "../Components/Assets/PrintLogo.png";
import { Link } from "react-router-dom";
import setState from "../Components/layout/Layout";

export const LandingPage = () => {
  // const data = localStorage.getItem("auth");
  // const Ldata = JSON.parse(data);
  return (
    <div>
      {/* <Header state={"SGU Store"} /> */}
      <div className="mt-5 mx-1">
        <h2 className="text-center fw-bolder ">
          Choose from below services to Continue..{" "}
        </h2>
        <Link
          to="/Home/FoodStore"
          className="text-center"
          onClick={(e) => {
            setState("Food Store");
          }}
        >
          <img
            src={FoodImg}
            alt=""
            style={{ border: "2px solid #11074C", borderRadius: "30px" }}
            className="img-thumbnail  mx-auto d-block mb-3"
          ></img>
        </Link>
        <Link to="/Home/PrintStore">
          <img
            alt=""
            src={PrintImg}
            style={{ border: "2px solid #11074C", borderRadius: "30px" }}
            className="img-thumbnail  mx-auto d-block mb-3"
          ></img>
        </Link>
      </div>
    </div>
  );
};
