import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { useAuth } from "./AuthProvider";
import axios from "axios";

const ConvContenxt = createContext();
const ConvProvider = ({ children }) => {
  const [Conv, setConv] = useState([]);
  // console.log("conversatioin:", Conv);

  const [auth] = useAuth();

  // webSocket.on("getMessage", ({message}) => {
  //   // console.log("getMessage:",message)
  // });
  const fetchData = useCallback(async () => {
    try {
      if (auth.user?.role === 0) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/conv/GetConvCust/${auth.user?._id}`
        );

        if (res.data) {
          setConv(res.data.Conversation);
        } else {
          // console.log(res.data);
        }
      }
      if (auth.user?.role === 1) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/conv/GetConvStore/${auth.user?.storeId}`
        );
        if (res.data) {
          setConv(res.data.Conversation);
        } else {
          // console.log(res.data);
        }
      }
      // webSocket.on("getMessage", ({message}) => {
      //   console.log("getMessage:",message)
      // });
    } catch (error) {
      console.log(error);
    }
  }, [auth]); // Add dependencies here

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Now fetchData is a dependency

  return (
    <ConvContenxt.Provider value={[Conv, setConv]}>
      {children}
    </ConvContenxt.Provider>
  );
};

const useConv = () => useContext(ConvContenxt);

export { useConv, ConvProvider };
