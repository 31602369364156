import React from "react";
import plane from "../Assets/plain.png";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as chartjs,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
chartjs.register(
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip
);

const BarGraph = ({ TimeFrame }) => {
  //console.log(TimeFrame);
  const MonthlyData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Income",
        data: [
          5000, 7000, 6500, 4500, 5500, 7500, 9000, 10000, 8500, 6500, 7500,
          8000,
        ],
        borderColor: "#11074C",
        backgroundColor: "#11074C",
        borderWidth: 0.7,
        barPercentage: 0.7,
      },
      {
        label: "Visits",
        data: [
          4003, 6030, 5250, 3400, 5200, 5600, 3800, 6900, 5750, 3550, 4600,
          3700,
        ],
        borderColor: "grey",
        backgroundColor: "grey",
        borderWidth: 0.7,
        barPercentage: 0.7,
      },
      {
        label: "Orders",
        data: [
          4043, 6040, 6250, 4400, 6200, 3600, 4800, 5800, 6750, 4550, 4400,
          6500,
        ],
        borderColor: "#587eb5",
        backgroundColor: "#587eb5",
        borderWidth: 0.7,
        barPercentage: 0.7,
      },
    ],
  };
  const WeeklyData = {
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Tuesday",
      "Friday",
      "Saturday",
    ],
    datasets: [
      {
        label: "Income",
        data: [5000, 7000, 6500, 4500, 5500, 7500, 9000],
        borderColor: "#11074C",
        backgroundColor: "#11074C",
        borderWidth: 0.7,
        barPercentage: 0.7,
      },
      {
        label: "Visits",
        data: [4003, 6030, 5250, 3400, 5200, 5600, 3800],
        borderColor: "grey",
        backgroundColor: "grey",
        borderWidth: 0.7,
        barPercentage: 0.7,
      },
      {
        label: "Orders",
        data: [4043, 6040, 6250, 4400, 6200, 3600, 4800],
        borderColor: "#587eb5",
        backgroundColor: "#587eb5",
        borderWidth: 0.7,
        barPercentage: 0.7,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <div>
      {TimeFrame === "Weekly" ? (
        <Bar data={WeeklyData} options={options} />
      ) : (
        <Bar data={MonthlyData} options={options} />
      )}
    </div>
  );
};

const LineGraph = ({ OrderData }) => {
  console.log("oder",OrderData)
  const newdata = OrderData?.slice(0, Math.min(OrderData.length, 5));
  // console.log(
  //   newdata?.map((newdata) => {
  //     return newdata?.TotalOrders;
  //   })
  // );

  const data = {
    labels: newdata?.reverse().map((newdata) => {
      return String(newdata?.date);
    }),
    // String(OrderData[5]?.date),
    // String(OrderData[4]?.date),
    // String(OrderData[3]?.date),
    // String(OrderData[2]?.date),
    // String(OrderData[1]?.date),
    // String(OrderData[0]?.date),

    datasets: [
      {
        label: "Orders",
        data: newdata?.map((newdata) => {
          return newdata?.TotalOrders;
        }),
        // OrderData[5]?.TotalOrders,
        // OrderData[4]?.TotalOrders,
        // OrderData[3]?.TotalOrders,
        // OrderData[2]?.TotalOrders,
        // OrderData[1]?.TotalOrders,
        // OrderData[0]?.TotalOrders,

        fill: false,
        backgroundColor: "red",
        borderColor: "red",
        PointBorderColor: "red",
        PointBorderWidth: 4,
        tension: 0.4,
      },
    ],
  };
  let min =
    Math.min(
      ...newdata.map((newdata) => {
        return newdata?.TotalOrders;
      })
    ) - 10;
  let max =
    Math.max(
      ...newdata?.map((newdata) => {
        return newdata?.TotalOrders;
      })
    ) + 10;
  // console.log("min:", min, "max:", max);
  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        min: min,
        max: max,
        display: false,
      },
    },
    plugins: {
      legend: false,
      background: {
        image: plane,
        repeat: "repeat",
        position: "center center",
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export { LineGraph, BarGraph };
