import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { useAuth } from "./AuthProvider";
import axios from "axios";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [auth] = useAuth();

  const [cart, setcart] = useState([]);
  const [Fav, setFav] = useState([]);
  const [Notification, setNotification] = useState([]);
  // console.log("Notification: ", Notification);
  const getNot = useCallback(async () => {
    try {
      if (auth?.user?._id) {
        // console.log("id", auth?.user?._id);
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/Notification/get/${auth?.user?._id}`
        );
        // console.log("not:", res);
        setNotification(res?.data?.Notifications?.Message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [auth]); // Add dependencies here

  useEffect(() => {
    getNot();
  }, [getNot]); // Now getNot is a dependency

  useEffect(() => {
    let existingCartItem = localStorage.getItem("cart");
    // console.log("c",JSON.parse(existingCartItem))
    if (existingCartItem) setcart(JSON.parse(existingCartItem));

    let existingFav = localStorage.getItem("Fav");
    if (existingFav) setFav(JSON.parse(existingFav));
  }, [auth]);

  return (
    <CartContext.Provider
      value={[cart, setcart, Fav, setFav, Notification, setNotification]}
    >
      {children}
    </CartContext.Provider>
  );
};

// custom hook
const useCart = () => useContext(CartContext);

export { useCart, CartProvider };
