import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../Components/Header/Header";

export const AboutUs = () => {
  return (
    <div
      className="Deskcontainer"
      style={{
        marginTop: "2rem",
        position: "relative",
        zIndex: 20,
        fontFamily: "Arial, sans-serif",
        fontSize: "1rem",
        lineHeight: "1.5",
        color: "#333",
      }}
    >
      <Header state={"About Us"} />
      <div className="ps-3 policy-back">
        <Link
          to="/Profile"
          className="d-inline back-btn"
          style={{ textDecoration: "none" }}
        >
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="mx-3 " style={{ paddingBottom: "4rem" }}>
        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          1. Our Mission
        </h2>
        <p>
          Welcome to sgu.collegestorehub.com! Our mission is to provide a
          convenient and efficient platform for students and stores on the
          college campus. We aim to bring all campus stores together in one
          place, making it easier for students to find and purchase the products
          and services they need.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          2. What We Offer
        </h2>
        <p>
          Our platform offers a wide range of products and services, including
          food items, printing services, and more. By combining all the stores
          in a single place, we ensure that students have easy access to
          everything they need on campus.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          3. Our Values
        </h2>
        <ul>
          <li className="li">
            <strong>Convenience:</strong> We strive to make the shopping
            experience as easy and hassle-free as possible for students.
          </li>
          <li className="li">
            <strong>Support Local Stores:</strong> We support campus stores by
            providing them with a platform to reach more students and grow their
            business.
          </li>
          <li className="li">
            <strong>Customer Satisfaction:</strong> We are committed to
            providing excellent customer service and ensuring that our users are
            satisfied with their experience.
          </li>
        </ul>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          4. Our Team
        </h2>
        <p>
          Our team is dedicated to creating a seamless and enjoyable shopping
          experience for students and supporting the growth of campus stores. We
          work hard to ensure that our platform meets the needs of both students
          and store owners.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          5. Contact Us
        </h2>
        <p>
          If you have any questions, suggestions, or feedback, we would love to
          hear from you! <br />
          Please contact us at : <br />
          <br />
          <a href="tel:+919307655505"> +91 9307655505</a> <br />
          <a href="mailto:prithvirajindulkar2003@gmail.com">
            {" "}
            prithvirajindulkar2003@gmail.com
          </a>
          <br />
          Address : Incubation Center , Sanjay Ghodawat University , Kolhapur,
          Maharashtra , India.
        </p>
      </div>
    </div>
  );
};
