import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../Contex/AuthProvider";

export const CreateAccount = () => {
  const [click, setClick] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword && click) {
      setClick(false);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/auth/register`,
          {
            name,
            email,
            phone,
            password,
          }
        );
        if (res && res.data.success) {
          toast.success(res.data && res.data.message);
          // console.log(res.data && res.data.message);
          // console.log(res.data.user, res.data.token);
          setAuth({
            ...auth,
            user: res.data.user,
            token: res.data.token,
          });
          navigate("/");
        } else if (res.data.status === 500) {
          toast.error(res.data.message);
          // console.log(res.data.message);
        } else {
          toast.error(res.data.message);
          // console.log(res.data.message);
        }
        setClick(true);
      } catch (error) {
        setClick(true);
        // console.log(error);
        toast.error("Something went Wrong");
      }
    } else {
      toast.error("Passwords doesn't match");
    }
  };
  return (
    <div className="Deskcontainer">
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div className=" d-flex justify-content-center flex-column cont">
        <h1 className=" mt-3 mb-3 text-center">
          WELCOME TO COLLEGE STORE HUB - SGU
        </h1>
        <div className="f-cont p-3 fw-bold">
          <h2 className=" mt-2 text-center fw-bold">Create Account</h2>
          <div className="d-flex justify-content-center mt-4">
            <form onSubmit={handleSubmit} style={{ width: "90%" }}>
              <div className="mb-3">
                <label htmlFor="exampleInputname1" className="form-label">
                  Full Name
                </label>
                <input
                  type="name"
                  className="form-control"
                  id="exampleInputname1"
                  placeholder="Enter Name"
                  autoComplete="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  aria-describedby="nameHelp"
                ></input>
                <div id="nameHelp" className="form-text"></div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter Email"
                  aria-describedby="emailHelp"
                  autoComplete="username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <div id="emailHelp" className="form-text"></div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPNumber1" className="form-label">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputPNumber1"
                  placeholder="Enter Phone Number"
                  aria-describedby="PNumberHelp"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
                <div id="PNumberHelp" className="form-text"></div>
              </div>
              <div className="mb-1">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                  id="exampleInputPassword1"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputCPassword1" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                  id="exampleInputCPassword1"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></input>
              </div>
              <div className="d-flex  link-cont my-3 ">
                Already User?
                <Link to="/Login" className="ms-2 fw-bold ">
                  Login
                </Link>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="sub-btn fw-bolder fs-5 ">
                  {click ? (
                    "Sign in"
                  ) : (
                    <div
                      className="spinner-border"
                      style={{ height: "25px", width: "25px" }}
                      role="status"
                    />
                  )}
                </button>
              </div>

              {/* <div className="orCont d-flex justify-content-center mt-3">
                <div className="or">
                  <hr></hr>
                  OR
                  <hr></hr>
                </div>
              </div>
              <div className="mt-3 mb-3 d-flex justify-content-center">
                <GoogleOAuthProvider clientId="447754552165-mc1bmq3qnb8hd4ps1bu4tqh3uis9dmdn.apps.googleusercontent.com">
                  <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                      const decoded = jwtDecode(credentialResponse.credential);
                      const email = decoded.email;
                      const name = decoded.name;

                      // console.log(name, email);
                      const res = await axios.post(
                        `${process.env.REACT_APP_API}/api/v1/auth/Glogin`,
                        {
                          name,
                          email,
                        }
                      );
                      if (res && res.data.success) {
                        toast.success(res.data && res.data.message);
                        // console.log(res.data && res.data.message);
                        setAuth({
                          ...auth,
                          user: res.data.user,
                          token: res.data.token,
                        });
                        const lauth = {
                          user: res.data.user,
                          token: res.data.token,
                          isauthenticated: true,
                        };
                        localStorage.setItem("auth", JSON.stringify(lauth));
                      } else {
                        toast.error(res.data.message);
                        // console.log(res.data.message);
                      }
                    }}
                    onError={() => {
                      toast.error("Login Failed");
                      // console.log("creaste account ine :190");
                    }}
                  />
                </GoogleOAuthProvider>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
