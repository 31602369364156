import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useCart } from "../../Contex/CartProvider";
import { useFav } from "../../Contex/FavProvider";
import { useImg } from "../../Contex/AssetProvider";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const Itemcard = ({ Id, items }) => {
  // console.log("item:", items);
  const [Fav, setFav] = useFav([]);
  // console.log("Fav : ", Fav);

  // console.log("item : ", item);

  const [cart, setCart] = useCart([]);
  // console.log("cart: ", cart);
  const [itemDetails] = useState(items);
  // console.log("c", cart);
  // setItemDetails(items);

  const [loading] = useState(false);

  // const [item] = useState({
  //   storeId: props.storeId,
  //   Items: {
  //     itemId: props.itemId,
  //     quantity: 0,
  //   },
  // });
  // const fetchData = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     if (Id) {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_API}/api/v1/store/item/${Id}`
  //       );
  //       setLoading(false);
  //       if (res?.data) {
  //         setItemDetails(res?.data);
  //         console.log("itemd:", res?.data);
  //       } else {
  //         console.log(res?.data);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [Id]);
  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);
  // const [item, setItem] = useState({

  //   ctn:0
  // });
  // console.log("itemcart:",item)
  // if(cart.some(obj=>obj.id===Id)){
  //   let index=cart.findIndex(obj=>obj.id===Id);
  //   setItem(cart[index]);
  // }
  // cart.map((cart)=>console.log("map : ",cart))

  const [ItmCtn, setItmCtn] = useState(
    cart[
      cart.findIndex((obj) => {
        return obj.details._id === Id;
      })
    ]?.ItmCtn
      ? cart[
          cart.findIndex((obj) => {
            return obj.details._id === Id;
          })
        ]?.ItmCtn
      : 0
  );
  // const [Fav, setFav] = useState("");
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(itemDetails?.img); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };

    fetchImage();
  });
  // cart.map((c) => {
  //   console.log(c)
  //   console.log(c.storeId);
  //   console.log(Number(props.storeId));
  //   if(c.storeId === Number(props.storeId)){

  //   }else{
  //     console.log("ff")
  //   }
  // });
  // console.log(cart[0].iId);
  // console.log(iId)
  // let index = myCart.findIndex(item => item.iId === iId);
  const chgQnt = (s) => {
    if (s === "+") {
      setItmCtn(ItmCtn + 1);
      let mycart = [...cart];
      console.log("mycart", mycart);
      if (mycart.some((obj) => obj.details._id === Id)) {
        mycart[
          mycart.findIndex((obj) => {
            return obj.details._id === Id;
          })
        ].ItmCtn += 1;
        setCart(mycart);
        localStorage.setItem("cart", JSON.stringify(mycart));
      } else {
        mycart = [...cart, { ItmCtn: 1, details: itemDetails }];
        setCart(mycart);
        localStorage.setItem("cart", JSON.stringify(mycart));
      }
      console.log("mycart:", mycart);
    } else {
      if (ItmCtn > 1) {
        setItmCtn(ItmCtn - 1);
        let mycart = [...cart];
        mycart[
          mycart.findIndex((obj) => {
            return obj.details._id === Id;
          })
        ].ItmCtn -= 1;
        setCart(mycart);
        localStorage.setItem("cart", JSON.stringify(mycart));
      } else {
        setItmCtn(ItmCtn - 1);
        let mycart = [...cart];
        mycart.splice(
          mycart.findIndex((obj) => {
            return obj.details._id === Id;
          }),
          1
        );
        setCart(mycart);
        localStorage.setItem("cart", JSON.stringify(mycart));
      }
    }

    // setItem(
    //   (item) => (
    //     { ...item, Items: { ...item, items: { quantity: ItmCtn + 1 } } },
    //     console.log("itm", item)
    //   )
    // item.Items.map((Items) =>
    // )
    // )
    // setCart(
    //   cart.map((i) => {
    //     console.log(i)
    //     if(i.storeId === props.storeId){
    //       return({ ...cart, Items: { quantity: item.Items.quantity } });
    //       console.log("tr")
    //     }else{
    //       return({cart})
    //       console.log("")
    //     }
    //       ? console.log("t")
    //       : //  { ...cart, Items: { quantity: item.Items.quantity } }
    //         console.log("f"),
    //       console.log("c", i.storeId, "p", props.storeId);
    //   })
    // );
  };
  const [showFullDescription, setshowFullDescription] = useState(false);
  const toggleDescription = () => {
    setshowFullDescription(!showFullDescription);
  };
  return itemDetails?.availability ? (
    <div
      className="store-card mb-4 mx-2"
      style={{
        marginTop: "20px",
        margin: "auto",
        border: "2px",
        borderRadius: "20px",
        boxShadow: "10px 10px 5px grey",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        color: "#11074C",
        backgroundColor: "white",
      }}
    >
      {loading ? (
        <SkeletonTheme
          baseColor="rgb(205 213 220 / 64%)"
          highlightColor="white"
        >
          <div className="d-flex justify-content-between">
            <div className="item-info">
              <h5>
                <Skeleton height={30} width={100} />
              </h5>
              <div className="d-flex">
                <h1 className="text-left me mb-1">
                  <Skeleton />
                </h1>
                <div className="btn" style={{ padding: "0" }}>
                  <Skeleton height={23} width={200} />
                </div>
              </div>
              <div className="rating">
                <Skeleton />
              </div>
              <div className="price  fs-4">
                <Skeleton />
              </div>
            </div>
            <div className="w-50"></div>
            <div className="item-img text-center w-50 d-flex flex-end flex-column align-items-center">
              <Skeleton height={90} width={"100%"} />
              <div>
                <div
                  className="btn-group add-btn"
                  role="group"
                  aria-label="Basic mixed styles example"
                  style={{ marginTop: "-20px" }}
                >
                  <button type="button" className="ctn btn ">
                    <Skeleton />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="">
          {imgSrc ? (
            <img
              // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${item?.img}`}
              src={imgSrc}
              alt=""
              style={{ width: "100%", borderRadius: "20px" }}
            />
          ) : (
            <Skeleton
              style={{ width: "100%", borderRadius: "20px", height: "170px" }}
            />
          )}
          {itemDetails?.quantity === 0 ? (
            <p
              style={{
                color: "red",
                fontSize: "large",
                marginBottom: "0",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Out of Stock
            </p>
          ) : (
            ""
          )}
          <div className="d-flex justify-content-between">
            <div className="item-info w-100 m-2">
              <div className="topbar d-flex flex-row justify-content-between me-3">
                <div className="d-flex flex-column">
                  <h5 className="mb-1">{itemDetails?.catogary}</h5>
                  <h3 className="text-left me-4 mb-1 fw-bold">
                    {itemDetails?.name}
                  </h3>
                </div>
                <div
                  className="bn"
                  onClick={() => {
                    if (Fav.some((obj) => obj._id === Id)) {
                      let myfav = [...Fav];
                      myfav?.splice(
                        Fav?.findIndex((obj) => {
                          return obj?._id === Id;
                        }),
                        1
                      );
                      setFav(myfav);
                      localStorage.setItem("Fav", JSON.stringify(myfav));
                    } else {
                      let myfav = [...Fav, itemDetails];
                      setFav(myfav);
                      localStorage.setItem("Fav", JSON.stringify(myfav));
                    }
                  }}
                  style={{ padding: "0" }}
                >
                  <i
                    className={`bi bi-heart${
                      Fav?.some((obj) => obj?._id === Id) ? "-fill" : ""
                    }`}
                    style={{
                      fontSize: "25px",
                      color: "red",
                    }}
                  />
                </div>
              </div>
              <div
                className="discription"
                style={{
                  marginBottom: `${itemDetails.Description ? "10px" : ""}`,
                }}
              >
                {showFullDescription
                  ? itemDetails.Description
                  : itemDetails.Description?.slice(0, 27)}
                <span
                  onClick={toggleDescription}
                  className="fw-bold"
                  style={{
                    display: `${
                      itemDetails?.Description?.length > 30
                        ? "contents"
                        : "none"
                    }`,
                  }}
                >
                  {showFullDescription ? " . . . Less" : " . . . More"}
                </span>
              </div>
              {/* <Rating rating={"4"} /> */}
              <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                <div className="price ms-3 fs-4">
                  <b>&#x20B9; {itemDetails?.price}</b>
                </div>
                {ItmCtn === 0 ? (
                  <div
                    className="btn-group me-3"
                    role="group"
                    aria-label="Basic mixed styles example"
                    style={{
                      zIndex: "1",
                    }}
                  >
                    <button
                      type="button"
                      className="btn "
                      style={{
                        padding: "7px",
                        borderRadius: "10px",
                        background: "rgba(170, 172, 173, 0.8)",
                      }}
                      onClick={() => {
                        itemDetails?.quantity === 0
                          ? toast.error(`${itemDetails?.name} is out of stock`)
                          : chgQnt("+");
                      }}
                    >
                      <b>Add To Cart</b>
                    </button>
                  </div>
                ) : (
                  <div>
                    <div
                      className="btn-group me-3 "
                      role="group"
                      aria-label="Basic mixed styles example"
                      style={{
                        zIndex: "1",
                      }}
                    >
                      <button
                        type="button"
                        className="btn d-flex align-items-center fw-bold fs-6"
                        style={{
                          // padding: "10px",
                          borderRadius: "10px 0 0 10px",
                          background: "rgba(170, 172, 173, 0.8)",
                        }}
                        onClick={() => {
                          chgQnt("-");
                        }}
                      >
                        -
                      </button>
                      <div
                        className="btn d-flex align-items-center fs-6"
                        style={{
                          // padding: "2px 10px",
                          background: "rgba(170, 172, 173, 0.8)",
                          borderRadius: "0",
                          fontWeight: "bold",
                        }}
                      >
                        {ItmCtn}
                      </div>
                      <button
                        type="button"
                        className="btn fw-bold d-flex align-items-center fs-6"
                        style={{
                          // padding: "2px 2px",
                          borderRadius: "0 10px 10px 0",
                          background: "rgba(170, 172, 173, 0.8)",
                        }}
                        onClick={() => {
                          chgQnt("+");
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="w-50"></div> */}
        </div>
      )}
    </div>
  ) : (
    ""
  );
};
