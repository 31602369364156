import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Contex/AuthProvider";
import axios from "axios";
import { useImg } from "../../Contex/AssetProvider";
export const CustomerMessages = () => {
  const [stores, setstores] = useState([]);
  // console.log("stores: ", stores);
  const [auth] = useAuth();

  // webSocket.on("getMessage", ({message}) => {
  //   // console.log("getMessage:",message)
  // });
  const fetchData = useCallback(async () => {
    try {
      if (auth.user?.role === 0 && auth.user?._id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/conv/GetConvCust/${auth.user?._id}`
        );
        if (res.data.Conversation) {
          // console.log("res : ", res.data.Conversation);
          setstores(res.data.Conversation);
          // setConv(res.data.Conversation);
        } else {
          // console.log("res : ", res);
        }
      }
      if (auth.user?.role === 1 && auth.user?.storeId) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/conv/GetConvStore/${auth.user?.storeId}`
        );
        if (res.data.Conversation) {
          // console.log("res : ", res.data.Conversation);
          // setConv(res.data.Conversation);
        } else {
          // console.log("res : ", res);
        }
      }
      // webSocket.on("getMessage", ({message}) => {
      //   console.log("getMessage:",message)
      // });
    } catch (error) {
      console.log(error);
    }
  }, [auth]); // Add dependencies here

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Now fetchData is a dependency

  const { images, getImage } = useImg();
  useEffect(() => {
    stores.forEach(async (s) => {
      await getImage(s?.StoreImg);
    });
  }, [getImage, stores]);
  return (
    <div className="Deskcontainer">
      {/* <Header state={"Messages"} /> */}
      <div className="ms-content-wrapper m-2">
        {/* Chat Sidebar */}

        <div className=" ">
          <div className="ms-panel ">
            <h2 className="text-center">Stores</h2>
            <div className="px-3 chat-ls overflow-y-scroll overflow-x-hidden">
              <div>
                <ul className=" ps-0 " style={{ listStyle: "none" }}>
                  {stores.map((Conv, i) => (
                    <Link to={`/Messages/${Conv._id}`} key={i}>
                      <li
                        className="row p-2 "
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          borderBottom: "1px solid rgba(0,0,0,0.1)",
                        }}
                        onClick={() => {
                          // setActive(item);
                        }}
                      >
                        <div
                          className=" new-msg  mr-3 align-self-center col-3"
                          style={{ position: "relative" }}
                        >
                          <span className="msg-count">3</span>
                          <img
                            // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${images[Conv?.StoreImg]}`}
                            src={images[Conv?.StoreImg]}
                            alt="people"
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "100px",
                            }}
                          />
                        </div>
                        <div className=" mt-1 col p-0">
                          <h6 style={{ display: "inline" }}>
                            {Conv?.StoreName}
                          </h6>{" "}
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#aeaeae",
                              float: "right",
                            }}
                          >
                            2 Hours ago
                          </span>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#aeaeae",
                              marginBottom: 0,
                              width: "85%",
                            }}
                          >
                            {/* {Conv.message} */}
                          </p>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Chat Body */}
        </div>
      </div>
    </div>
  );
};
