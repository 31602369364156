import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Rating } from "../Rating";
// import {crypto} from"crypto";
import CryptoJS from "crypto-js";
import { useAuth } from "../../Contex/AuthProvider";
import { useSocket } from "../../Contex/WebSocket";
import toast, { Toaster } from "react-hot-toast";
import { useImg } from "../../Contex/AssetProvider";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Navigate } from "react-router-dom";
// const crypto = require('crypto');
export const OrderCard = ({ i }) => {
  const [click, setClick] = useState(true);
  const [webSocket] = useSocket();
  const [instructions, setInstructions] = useState(i?.Instructions);
  const [auth] = useAuth();
  const [parcel] = useState(i.Parcel ? i.Parcel : false);
  // console.log("i",i)
  console.log("parsel", parcel);
  // console.log("dsv", i);
  function objectIdToFourDigits(objectId) {
    // Create a hash of the ObjectId
    let hashed = CryptoJS.SHA256(objectId).toString();

    // Reduce the hash modulo 1000 to get a 3-digit number
    let number = parseInt(hashed, 16) % 1000;

    return number;
  }
  // const [newOrder, setNewOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState();
  // console.log("store", store);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (i?.StoreId) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${i?.StoreId}`
        );
        if (res.data.success) {
          setStore(res.data.store);
          // console.log("id", res.data.store);
          setLoading(false);
        } else {
          // console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  function isOpen(openTime, closeTime) {
    const now = new Date();
    const open = new Date(now);
    const close = new Date(now);

    open.setHours(openTime.split(":")[0], openTime.split(":")[1], 0, 0);
    close.setHours(closeTime.split(":")[0], closeTime.split(":")[1], 0, 0);

    return now >= open && now < close;
  }
  const currentlyOpen = isOpen(
    String(store?.OpenTime),
    String(store?.CloseTime)
  );
  function convertTimestamp(timestamp) {
    let dateObj = new Date(timestamp);

    let options = { year: "2-digit", month: "short", day: "numeric" };

    let date = dateObj.toLocaleDateString("en-GB", options);
    let time = dateObj.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${date} , ${time}`;
  }
  var OrderDetails = {
    items: i.Items,
    option: {
      amount: i.TotalPrice * 100,
      currency: "INR",
      receipt: `${i.Items.map((i) => {
        return i?.ItmCtn + "x" + i?.details?.name;
      })}:${auth?.user?.name}`.substring(0, 40),
      notes: {
        Items: `${i?.Items?.map((i) => {
          return i?.ItmCtn + " x " + i?.details?.name + " ";
        })}`,
        Customer_Name: `${auth?.user?.name}`,
        Parcel: parcel,
      },
    },
  };
  function calculateTotalValue(array, parcel) {
    let total = 0;
    let items = 0;
    array.forEach((item) => {
      let price = item.details.price;
      total += item.ItmCtn * price;
      if (parcel) {
        total += 5 * item.ItmCtn;
        items += item.ItmCtn;
      }
    });
    return { items, total }; // Return items and total as an object
  }
  const { items } = calculateTotalValue(i.Items, parcel);
  const pay = async () => {
    setClick(false);
    const res = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/Payment/order`,
      OrderDetails
    );
    setClick(true);
    const order = res?.data.data;
    // console.log(order);
    // toast.error(JSON.stringify(order));
    var opti = {
      key: store.paymentKey, // Enter the Key ID generated from the Dashboard
      amount: i.TotalPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: store.StoreName, //your business name
      description: instructions || "Order Description",
      image: imgSrc,
      order_id: order?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function async(response) {
        // console.log("res : ", response);
        const Order = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/order/CreateOrder`,
          {
            StoreId: i?.StoreId,
            Items: i.Items,
            Parcel: parcel,
            Instructions: instructions,
            TotalPrice: i.TotalPrice,
            Buyer: auth.user,
            Payment: response,
          }
        );
        <Toaster
          containerStyle={{
            top: 20,
          }}
        />;
        toast.success("Order Placed Successfully", {
          style: { zIndex: 9999 },
        });
        window.location.reload();
        if (webSocket) {
          webSocket.emit("CreateOrder", Order.data?.Order);
          // console.log("sent");
        }
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: auth.user.name, //your customer's name
        email: auth.user.email, //your customer's email
        contact: auth.user.phone, //Provide the customer's phone number for better conversion rates
      },
      // config: {
      //   display: {
      //     blocks: {
      //       banks: {
      //         name: "Pay via UPI",
      //         instruments: [
      //           {
      //             method: "upi",
      //           },
      //         ],
      //       },
      //     },
      //     sequence: ["block.banks"],
      //     preferences: {
      //       show_default_blocks: false,
      //     },
      //   },
      // },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#11074C",
      },
    };

    var rzp1 = new window.Razorpay(opti);

    rzp1.on("payment.failed", function (response) {
      // console.log(res);
      toast.error("error:", response);
      toast.error("Payment Failed ");
      toast.error("If money is deducted wait for some time or contect Store");
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    if (res.status === 200) {
      rzp1.open();
    }
    if (res.status === 220) {
      toast.error(res.data?.errorMessage);
    }
  };
  // const createOrder = async () => {
  //   const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/order/CreateOrder`, {
  //     StoreId: i?.StoreId,
  //     Items: i.Items,
  //     TotalPrice: i.TotalPrice,
  //     Buyer: auth.user,
  //   });
  //   console.log("res", res.data.Order);
  //   if (webSocket) {
  //     webSocket.emit("CreateOrder", res.data?.Order);
  //   }
  //   toast.success("Order Placed Successfully");
  //   window.location.reload();
  //   // console.log(res.data);
  // };
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(store?.img); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };
    fetchImage();
  }, [store?.img, getImage]);
  return (
    <div>
      {loading ? (
        <SkeletonTheme
          baseColor="rgb(205 213 220 / 64%)"
          highlightColor="white"
        >
          <div
            className="mx-2 mb-3"
            style={{ border: "1px solid #11074C", borderRadius: "20px" }}
          >
            <div
              className="row mx-0 pt-1"
              style={{
                backgroundColor: "#98c5d4",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <h4 className="col m-0 fw-bold">
                <Skeleton />
              </h4>
              <h3
                className="col fw-bold me-3 m-auto"
                style={{
                  width: "fit-content",
                  fontSize: "17px",
                  padding: "0px 10px",
                  borderRadius: "20px",
                }}
              >
                <Skeleton />
              </h3>
            </div>
            <div
              className="row mx-0 pt-2 pb-3"
              style={{
                borderBottom: "1px solid #11074C",
                backgroundColor: "#98c5d4",
                borderRadius: "0px",
              }}
            >
              <div className="col-5">
                <Skeleton height={80} width={"100%"} />
              </div>
              <div className="col">
                <h1>
                  <Skeleton />
                </h1>
                <h5 className="mb-1">
                  <Skeleton count={2} />
                </h5>
              </div>
            </div>
            <div
              className="justify-content-between align-items-center mx-0 p-2"
              style={{
                backgroundColor: "white",
                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <ul
                className="mb-2"
                style={{
                  listStyle: "circle",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                <li className="row">
                  <Skeleton />
                </li>
              </ul>
              <hr className="m-0 pt-1" />
              <div className="row px-1 m-0">
                <div className="text-start d-flex align-items-center col-7">
                  <h6 className="fw-bold">
                    <Skeleton />
                  </h6>
                </div>
                <div className="text-end col-5">
                  <h6 className="fw-bold">
                    <Skeleton />
                  </h6>
                </div>
              </div>
              <div>
                <button type="button" className="btn w-100">
                  <Skeleton />
                </button>
              </div>
            </div>
          </div>
          <div
            className="mx-2 mb-3"
            style={{ border: "1px solid #11074C", borderRadius: "20px" }}
          >
            <div
              className="row mx-0 pt-1"
              style={{
                backgroundColor: "#98c5d4",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <h4 className="col m-0 fw-bold">
                <Skeleton />
              </h4>
              <h3
                className="col fw-bold me-3 m-auto"
                style={{
                  width: "fit-content",
                  fontSize: "17px",
                  padding: "0px 10px",
                  borderRadius: "20px",
                }}
              >
                <Skeleton />
              </h3>
            </div>
            <div
              className="row mx-0 pt-2 pb-3"
              style={{
                borderBottom: "1px solid #11074C",
                backgroundColor: "#98c5d4",
                borderRadius: "0px",
              }}
            >
              <div className="col-5">
                <Skeleton height={80} width={"100%"} />
              </div>
              <div className="col">
                <h1>
                  <Skeleton />
                </h1>
                <h5 className="mb-1">
                  <Skeleton count={2} />
                </h5>
              </div>
            </div>
            <div
              className="justify-content-between align-items-center mx-0 p-2"
              style={{
                backgroundColor: "white",
                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <ul
                className="mb-2"
                style={{
                  listStyle: "circle",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                <li className="row">
                  <Skeleton />
                </li>
              </ul>
              <hr className="m-0 pt-1" />
              <div className="row px-1 m-0">
                <div className="text-start d-flex align-items-center col-7">
                  <h6 className="fw-bold">
                    <Skeleton />
                  </h6>
                </div>
                <div className="text-end col-5">
                  <h6 className="fw-bold">
                    <Skeleton />
                  </h6>
                </div>
              </div>
              <div>
                <button type="button" className="btn w-100">
                  <Skeleton />
                </button>
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div
          className="mx-2 mb-3 "
          style={{
            border: "1px solid #11074C",
            borderRadius: "20px",
          }}
        >
          <div
            className="px-2 pt-1 d-flex flex-row flex-wrap justify-content-between align-items-center"
            style={{
              backgroundColor: "#98c5d4",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <h4 className="m-0 fw-bold" style={{ width: "55%" }}>
              Order Id : {objectIdToFourDigits(String(i._id))}
            </h4>
            <h3
              className="fw-bold"
              style={{
                width: "45%",
                fontSize: "17px",

                padding: "0px 10px",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            >
              {i.status}
            </h3>
          </div>
          <div
            className="row mx-0 pt-2 pb-3"
            style={{
              borderBottom: "1px solid #11074C",
              backgroundColor: "#98c5d4",
              borderRadius: "0px",
            }}
          >
            <div className="col-5">
              {imgSrc ? (
                <img
                  alt="img"
                  // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${
                  //   store?.img ? store?.img : "hh"
                  // }`}
                  src={imgSrc}
                  style={{ height: "100px", borderRadius: "15px" }}
                />
              ) : (
                <Skeleton style={{ height: "80px", borderRadius: "15px" }} />
              )}
            </div>
            <div className="col ps-4">
              <h1>{store?.StoreName}</h1>
              <h5 className="mb-1">
                <i className="bi bi-dot fs-5"></i>
                <b>{store?.Spec1}</b>
                <br /> <i className="bi bi-dot fs-5"></i>
                <b>{store?.Spec2}</b>
              </h5>
              {/* <div
                className="rating "
                style={{
                  width: "fit-content",
                  padding: "0px 10px",
                  borderRadius: "20px",
                  backgroundColor: "white",
                }}
              >
                <Rating rating={store?.rating} />
              </div> */}
            </div>
          </div>
          <div
            className=" justify-content-between align-items-center mx-0 p-2"
            style={{
              backgroundColor: "white",
              borderRadius: "0px 0px 20px 20px",
            }}
          >
            <ul
              className="mb-2 ps-2"
              style={{
                listStyle: "circle",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {i.Items.length > 0
                ? i.Items.map((i) => (
                    <li
                      className="d-flex"
                      key={i._id}
                      style={{ fontSize: "18px", listStyle: "none" }}
                    >
                      <p className=" mb-1" style={{ width: "60%" }}>
                        {i?.ItmCtn} x {i.details?.name}
                      </p>
                      <p className="mb-1" style={{ width: "40%" }}>
                        {i?.ItmCtn} x {i.details?.price} ={" "}
                        {i?.ItmCtn * i.details?.price}
                      </p>
                    </li>
                  ))
                : i.Files.map((f) => (
                    <li
                      className="d-flex"
                      key={f._id}
                      style={{ fontSize: "18px", listStyle: "none" }}
                    >
                      <p className=" mb-1" style={{ width: "60%" }}>
                        {f?.printOptions.copies} x {f.name.split("_")[1]}
                      </p>
                      <p className="mb-1 text-center" style={{ width: "40%" }}>
                        &#x20B9;{f.price}
                      </p>
                    </li>
                  ))}
            </ul>
            {parcel ? (
              <div>
                <p
                  className="fw-bold mb-1 ps-2 d-flex flex-row justify-content-between"
                  style={{ fontSize: "18px" }}
                >
                  Parcel Charegs:{" "}
                  <p className="pe-4">&#x20B9;{parcel ? 5 * items : 0}</p>
                </p>
              </div>
            ) : null}
            <hr className="m-0 pt-1" />
            <div className="row px-3">
              <div className="text-start  d-flex align-items-center col-7">
                <h6 className="fw-bold">{convertTimestamp(i.createdAt)}</h6>
              </div>
              <div className="text-end  col-5">
                <h5 className="fw-bold">Total : &#x20B9;{i.TotalPrice}</h5>
              </div>
            </div>
            {i.Items.length > 0 ? (
              <div>
                <div className="mb-2">
                  <textarea
                    className="form-control"
                    placeholder="Instructions : "
                    style={{
                      height: "40px",
                      overflow: "hidden",
                    }}
                    value={instructions} // Set the value of the textarea to instructions
                    onChange={(e) => {
                      setInstructions(e.target.value);
                      e.target.style.height = "auto";
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                  ></textarea>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn w-100 fs-3 mb-2"
                    style={{ backgroundColor: "#11074C", color: "white" }}
                    onClick={() => {
                      if (auth.user) {
                        if (currentlyOpen) {
                          if (click) {
                            pay();
                          }
                        } else {
                          toast.error(
                            `${store.StoreName} is Currently Closed , Opens at ${store.OpenTime}`
                          );
                        }
                      } else {
                        toast.error("Please Login First");
                        Navigate("/Login");
                      }
                    }}
                  >
                    {click ? (
                      <b>Reorder &#x20B9;{i.TotalPrice}</b>
                    ) : (
                      <div
                        className="spinner-border"
                        style={{ height: "25px", width: "25px" }}
                        role="status"
                      />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};
