import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
// import { Rating } from "../Rating";
import { CartItemCard } from "./CartItemCard";
import { useAuth } from "../../Contex/AuthProvider";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../Contex/WebSocket";
import { useImg } from "../../Contex/AssetProvider";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useCart } from "../../Contex/CartProvider";
// import Razorpay from 'razorpay';

export const CartCard = ({ i }) => {
  const [click, setClick] = useState(true);
  // console.log("i", i);
  const [webSocket] = useSocket();
  const [cart, setCart] = useCart([]);
  const navigate = useNavigate();
  const [Instructions, setInstructions] = useState("");
  const [auth] = useAuth();
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log("store", store);
  const [parcel, setParcel] = useState(false);
  // console.log("storeId:", String(i[0]?.details?.StoreId));
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (String(i[0]?.details?.StoreId)) {
        // console.log("idjkhk:", String(i[0]?.details?.StoreId));
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${String(
            i[0]?.details?.StoreId
          )}`
        );

        setLoading(false);
        if (res?.data?.success) {
          setStore(res?.data?.store);
          // console.log("id", res?.data?.store);
        } else {
          // console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [i]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function calculateTotalValue(array, parcel) {
    let total = 0;
    let items = 0;
    array.forEach((item) => {
      let price = item.details.price;
      total += item.ItmCtn * price;
      if (parcel) {
        total += 5 * item.ItmCtn;
        items += item.ItmCtn;
      }
    });
    return { items, total }; // Return items and total as an object
  }
  const { total } = calculateTotalValue(i, parcel);
  const { items } = calculateTotalValue(i, parcel);
  // const createOrder = async () => {
  //   // console.log("order");
  //   if (currentlyOpen) {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_API}/api/v1/order/CreateOrder`,
  //       {
  //         StoreId: String(i[0].details?.StoreId),
  //         Items: i,
  //         TotalPrice: calculateTotalValue(i),
  //         Buyer: auth.user,
  //       }
  //     );
  //     console.log("res:", res.data.Order);
  //     webSocket.emit("CreateOrder", res.data.Order);
  //     toast.success("Order Placed Successfully");
  //   } else {
  //     // toast.error(`${store.StoreName} `)
  //     toast.error(
  //       `${store.StoreName} is Currently Closed , Opens at ${store.OpenTime}`
  //     );
  //   }

  //   // console.log("Order:",Order.Order);
  // };
  function isOpen(openTime, closeTime) {
    const now = new Date();
    const open = new Date(now);
    const close = new Date(now);

    open.setHours(openTime.split(":")[0], openTime.split(":")[1], 0, 0);
    close.setHours(closeTime.split(":")[0], closeTime.split(":")[1], 0, 0);

    return now >= open && now < close;
  }
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(store?.img); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };

    fetchImage();
  });
  const currentlyOpen = isOpen(String(store.OpenTime), String(store.CloseTime));
  // console.log("StoreIdKDNVK,SDV", i[0].details?.StoreId);
  var OrderDetails = {
    items: i,
    option: {
      amount: total * 100,
      currency: "INR",
      receipt: `${i.map((i) => {
        return i.ItmCtn + "x" + i.details?.name;
      })}:${auth?.user?.name}`.substring(0, 40),
      notes: {
        Items: `${i.map((i) => {
          return i.ItmCtn + " x " + i.details?.name + " ";
        })}`,
        Customer_Name: `${auth?.user?.name}`,
        Parcel: parcel,
      },
    },
  };
  // console.log("key:", store.paymentKey);
  // console.log("rzp_test_q3ZRZgBGI7YULj");
  const pay = async () => {
    setClick(false);
    // console.log("OrderDetails:", OrderDetails);
    const res = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/Payment/order`,
      OrderDetails
    );
    // console.log("res:", res.data.data);
    const order = res.data.data;
    // console.log("data:", order);
    // toast.error(JSON.stringify(order));
    var opti = {
      key: store.paymentKey, // Enter the Key ID generated from the Dashboard
      amount: total * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: store.StoreName, //your business name
      description: Instructions || "Order Description",
      image: imgSrc,
      order_id: order?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function async(response) {
        // console.log("res : ", response);
        const Order = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/order/CreateOrder`,
          {
            StoreId: String(i[0].details?.StoreId),
            Items: i,
            Parcel: parcel,
            Instructions,
            TotalPrice: total,
            Buyer: auth.user,
            Payment: response,
          }
        );
        <Toaster
          containerStyle={{
            top: 20,
          }}
        />;
        toast.success(
          "Order Placed Successfully,Collect your order from counter after some time.",
          {
            style: { zIndex: 9999 },
          }
        );

        let mycart = [...cart];
        for (let j = 0; j < i?.length; j++) {
          mycart.splice(
            mycart.findIndex((obj) => {
              return obj.details._id === i?.details?._id;
            }),
            1
          );
          setCart(mycart);
          localStorage.setItem("cart", JSON.stringify(mycart));
        }
        if (webSocket) {
          webSocket.emit("CreateOrder", Order.data?.Order);
          // console.log("sent");
        }
        navigate("/Orders");
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: auth.user?.name, //your customer's name
        email: auth.user?.email, //your customer's email
        contact: auth.user?.phone, //Provide the customer's phone number for better conversion rates
      },
      // config: {
      //   display: {
      //     blocks: {
      //       banks: {
      //         name: "Pay via UPI",
      //         instruments: [
      //           {
      //             method: "upi",
      //           },
      //         ],
      //       },
      //     },
      //     sequence: ["block.banks"],
      //     preferences: {
      //       show_default_blocks: false,
      //     },
      //   },
      // },
      // notes: {
      //   address: "Razorpay Corporate Office",
      // },
      theme: {
        color: "#11074C",
      },
    };
    var rzp1 = new window.Razorpay(opti);

    rzp1.on("payment.failed", function (response) {
      // console.log(res);
      setClick(true);
      // toast.error("error:", response);
      toast.error("If money is deducted wait for some time or contect Store");
      toast.error("Payment Failed ");
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    if (res.status === 200) {
      rzp1.open();
    }
    if (res.status === 220) {
      toast.error(res.data?.errorMessage);
    }

    setClick(true);
  };
  return (
    <div>
      {loading ? (
        <SkeletonTheme
          baseColor="rgb(205 213 220 / 64%)"
          highlightColor="white"
        >
          <div
            className="mx-2 mb-3 cart-card"
            style={{ border: "1px solid #11074C", borderRadius: "20px" }}
          >
            <div
              className="row mx-0 pt-3 pb-3"
              style={{
                borderBottom: "1px solid #11074C",
                backgroundColor: "#98c5d4",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <div className="col-5">
                <Skeleton height={80} width={"100%"} />
              </div>
              <div className="col">
                <h1>
                  <Skeleton />
                </h1>
                <h5 className="mb-1">
                  <Skeleton count={2} />
                </h5>
              </div>
            </div>
            <div
              className="justify-content-between align-items-center mx-0 p-2"
              style={{
                backgroundColor: "white",
                borderRadius: "0px 0px 20px 20px",
              }}
            >
              <ul
                className="mb-2 ps-2"
                style={{
                  listStyle: "circle",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                <Skeleton count={2} />
              </ul>
              <hr className="m-0 pt-1" />
              <div className="text-end me-4">
                <h3 className="fw-bold">
                  <Skeleton width={"150px"} />
                </h3>
              </div>
              <button type="button" className="btn w-100 fs-3 mb-2">
                <Skeleton />
              </button>
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div
          className="mx-2 cart-card"
          style={{
            border: "1px solid #11074C",
            borderRadius: "20px",
            color: "#11074C",
          }}
        >
          {/* {index=storeInfo.find((s) => s.storeId === c.storeId)} */}
          {/* <div className="d">{index}</div> */}
          <div
            className="row mx-0 pt-2 pb-1"
            style={{
              borderBottom: "1px solid #11074C",
              backgroundColor: "#98c5d4",
              borderRadius: "20px 20px 0px 0px",
            }}
          >
            <div className="col-5">
              {imgSrc ? (
                <img
                  alt="profile"
                  // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${store?.img}`}
                  src={imgSrc}
                  style={{ height: "80px", borderRadius: "15px" }}
                />
              ) : (
                <Skeleton style={{ height: "80px", borderRadius: "15px" }} />
              )}
            </div>
            <div className="col">
              <h1>{store?.StoreName}</h1>
              <h5 className="mb-1">
                <i className="bi bi-dot fs-5"></i>
                <b>{store?.Spec1}</b> <i className="bi bi-dot fs-5"></i>
                <b>{store?.Spec2}</b>
              </h5>
              {/* <div
                className="rating "
                style={{
                  width: "fit-content",
                  padding: "0px 10px",
                  borderRadius: "20px",
                  backgroundColor: "white",
                }}
              >
                <Rating rating={store?.rating} />
              </div> */}
            </div>
          </div>
          <div
            className=" justify-content-between align-items-center mx-0 p-2"
            style={{
              backgroundColor: "white",
              borderRadius: "0px 0px 20px 20px",
            }}
          >
            <ul
              className="mb-1 ps-2"
              style={{
                listStyle: "circle",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {i?.map((i, index) => (
                <CartItemCard i={i} key={index} />
              ))}
            </ul>
            {parcel ? (
              <div>
                <p className="fw-bold mb-1 ps-2 fs-5 d-flex flex-row justify-content-between align-items-center">
                  Parcel Charegs:{" "}
                  <p className="pe-4 align-items-center mb-0">
                    &#x20B9;{parcel ? 5 * items : 0}
                  </p>
                </p>
              </div>
            ) : null}
            <hr className="m-0 pt-1" />
            <div className=" mb-2 d-flex flex-row justify-content-between align-items-center">
              <select
                className="form-select"
                defaultValue={parcel ? "true" : "false"}
                onChange={(e) =>
                  setParcel(e.target.value === "true" ? true : false)
                }
                style={{ color: "grey", width: "50%" }}
              >
                <option value="false">Dine In</option>
                <option value="true">Parcel +&#x20B9;5/item</option>
              </select>
              <h3 className="fw-bold pe-1">Total : &#x20B9;{total}</h3>
            </div>
            <div className="mb-2">
              <textarea
                className="form-control"
                placeholder="Instructions : "
                style={{
                  height: "40px",
                  overflow: "hidden",
                }}
                onChange={(e) => {
                  setInstructions(e.target.value);
                  e.target.style.height = "auto";
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
              ></textarea>
            </div>
            <button
              type="button"
              className="btn w-100 fs-3 mb-2"
              style={{ backgroundColor: "#11074C", color: "white" }}
              onClick={() => {
                if (auth.user) {
                  if (currentlyOpen) {
                    if (click) {
                      pay();
                    }
                  } else {
                    toast.error(
                      `${store.StoreName} is Currently Closed , Opens at ${store.OpenTime}`
                    );
                  }
                } else {
                  toast.error("Please Create Account First");
                  navigate("/CreateAccount");
                }
              }}
            >
              {click ? (
                <b>Pay {total}</b>
              ) : (
                <div
                  className="spinner-border"
                  style={{ height: "25px", width: "25px" }}
                  role="status"
                />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
