import React from "react";
import { Header } from "../../Components/Header/Header";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contex/AuthProvider";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
export const Setting = () => {
  // const [Notification, setNotification] = useState(true);
  // console.log(Notification);
  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     It will play sound when opened in App,When New order is Received
  //   </Tooltip>
  // );
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const logout = () => {
    const msg = {
      type: "UnRegister",
      data: auth?.user?._id,
    };
    sendmsg(JSON.stringify(msg));
    // console.log("logout");
    setAuth({
      ...auth,
      user: null,
      token: "",
    });

    localStorage.removeItem("auth");
    toast.success("Logout Successfull");
  };
  const sendmsg = async (msg) => {
    const registration = await navigator.serviceWorker.ready;
    registration.active.postMessage(msg);
  };
  return (
    <div className="Deskcontainer mb-5">
      <Header state={"Setting"} />
      <div
        className="opt m-3 mt-5 "
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
        }}
      >
        {/* <div className="row justify-content-center" style={{ width: "95%" }}>
          <div className="m-2 col-8">
            <h3 className="fw-bold">Notification</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              defaultChecked={Notification}
              onClick={() => {
                setNotification(!Notification);
                window.ReactNativeWebView.postMessage({ Notification: !Notification });
                localStorage.setItem("Notification", !Notification);
                toast.success("Notification " + (!Notification ? "On" : "Off"));
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
          <hr className="ms-5 m-0 " />
        </div> */}
        
        <div
          className="row justify-content-center"
          style={{ width: "95%" }}
          onClick={() => {
            navigate("/Admin/Setting/Notification");
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Notification</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-5 m-0 " />
        </div>
        {/* <div
          className="row justify-content-center"
          style={{ width: "95%" }}
          onClick={() => {
            auth?.user?.name ? navigate("/Messages") : navigate("/");
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Messages</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-5 m-0 " />
        </div> */}
        {/* <div
          className="row justify-content-center"
          style={{ width: "95%" }}
          onClick={() => {
            // navigate("/Notification");
          }}
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Notificatoins</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-5 m-0 " />
        </div>
        <a
          className="row justify-content-center"
          style={{ width: "95%" }}
          href="mailto:prithvirajindulkar2003@gmail.com?subject=Feedback"
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Send Feedback</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-5 m-0 " />
        </a> */}
        <a
          className="row justify-content-center"
          style={{ width: "95%" }}
          href="mailto:prithvirajindulkar2003@gmail.com?subject=Report Bug"
        >
          <div className="m-2 col-9">
            <h3 className="fw-bold">Report a Bug</h3>
          </div>
          <div className="col fw-bold fs-1 text-end d-flex align-items-center">
            {">"}
          </div>
          <hr className="ms-5 m-0 " />
        </a>
      </div>
      {auth?.user?.name ? (
        <div className="logoutBtn d-flex ">
          <button
            type="button"
            style={{
              backgroundColor: "#11074C",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
            className="btn m-auto px-5 w-75 fs-5 fw-bold"
            onClick={() => logout()}
          >
            Log Out
          </button>
        </div>
      ) : (
        <div className="loginBtn d-flex">
          <button
            type="button"
            className="btn m-auto px-5 w-75 fs-5 fw-bold"
            style={{
              backgroundColor: "#11074C",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
            onClick={() => navigate("/Login")}
          >
            Log in
          </button>
        </div>
      )}
    </div>
  );
};
