import React from "react";
import { Header } from "../../Components/Header/Header";
import { Itemcard } from "../../Components/cards/Itemcard";
import { useFav } from "../../Contex/FavProvider";

export const Favourite = () => {
  const [Fav] = useFav();
  function groupItemsByStoreId(array) {
    const grouped = array.reduce((acc, item) => {
      acc[item.StoreId] = acc[item.StoreId] || [];
      acc[item.StoreId].push(item);
      return acc;
    }, {});
    return Object.values(grouped);
  }
  // console.log(Fav.length);
  const grouped = groupItemsByStoreId(Fav);
  // console.log("new:", grouped);

  // const asyncfunc = async(i)=>{
  //   console.log("i",i)
  //   const res = await axios.get(
  //     `${process.env.REACT_APP_API}/api/v1/store/store/${i[0].sId}`
  //   );
  //   console.log(res.data.store.StoreName);
  //   return (
  //     <div
  //       className="store m-2 p-2"
  //       style={{
  //         border: "1px solid #11074C",
  //         borderRadius: "20px",
  //         backgroundColor: "#98c5d4",
  //       }}
  //     >
  //       <h1>{res.data.store.StoreName}</h1>
  //       <div className="items m-2">{/* <Itemcard /> */}</div>
  //     </div>
  //   )
  // }
  return (
    <div className="Deskcontainer"style={{paddingBottom:"90px"}} >
      <Header state={"Favourite"} />
      {Fav.length === 0 ? (
        <div className="text-center pt-5">
          <h2>Your Favourites is empty</h2>
          <p>
            You have no items in your Favourite list. Add items to your
            Favourite list !
          </p>
        </div>
      ) : (
        grouped.map((i, j) => (
          <div
            className="store mx-2 py-1"
            style={{
              border: "1px solid #11074C",
              borderRadius: "20px",
              backgroundColor: "#98c5d4",
            }}
            key={j}
          >
            <div>
              <h1 className="ms-3 text-center">{i[0]?.Storename}</h1>
              <div className="items mx-1 my-3 d-flex justify-content-around flex-wrap">
                {i.map((j) => (
                  <Itemcard Id={j._id} items={j} key={j._id} />
                ))}
              </div>
            </div>
          </div>
        ))
      )}

      {/* {Fav.map((i)=>(
        console.log("i:",i),
        <Itemcard Id={i.id}/>
      ))} */}

      {/* <div
        className="store m-2 p-2"
        style={{
          border: "1px solid #11074C",
          borderRadius: "20px",
          backgroundColor: "#98c5d4",
        }}
      >
        <h1>Pizza Store</h1>
        <div className="items m-2">
          <Itemcard />
          <Itemcard />
          <Itemcard />
        </div>
      </div> */}
    </div>
  );
};
