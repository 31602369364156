import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../Components/Header/Header";

export const PrivacyPolicies = () => {
  return (
    <div
      className="Deskcontainer"
      style={{
        marginTop: "2rem",
        position: "relative",
        zIndex: 20,
        fontFamily: "Arial, sans-serif",
        fontSize: "1rem",
        lineHeight: "1.5",
        color: "#333",
      }}
    >
      <Header state={"Policy"} />
      <div className="ps-3 policy-back">
        <Link
          to="/Profile"
          className="d-inline back-btn"
          style={{ textDecoration: "none" }}
        >
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="mx-3 " style={{ paddingBottom: "4rem" }}>
        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          1. Introduction
        </h2>
        <p>
          Welcome to sgu.collegestorehub.com ("we," "our," "us"). We are
          committed to protecting your privacy. This Privacy Policy outlines how
          we collect, use, disclose, and safeguard your information when you
          visit our website and use our services.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          2. Information We Collect
        </h2>
        <p>We collect the following information when you use our website:</p>
        <ul>
          <li className="li">
            <strong>Personal Information:</strong> When you register on our
            website, we collect your name, phone number, email address, and
            password.
          </li>
          <li className="li">
            <strong>Usage Data:</strong> We may collect information on how you
            access and use the website. This can include your IP address,
            browser type, operating system, and other usage details.
          </li>
        </ul>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          3. Use of Your Information
        </h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li className="li">
            <strong>To Provide and Maintain Our Service:</strong> To manage your
            account and provide you with the requested products and services.
          </li>
          <li className="li">
            <strong>To Communicate with You:</strong> To send you updates,
            notifications, and information regarding your orders and our
            services.
          </li>
          <li className="li">
            <strong>To Improve Our Website:</strong> To understand how our
            website is used and to enhance your user experience.
          </li>
        </ul>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          4. Disclosure of Your Information
        </h2>
        <p>
          We do not share your personal information with third parties, except
          in the following circumstances:
        </p>
        <ul>
          <li className="li">
            <strong>With Your Consent:</strong> We may disclose your personal
            information with your explicit consent.
          </li>
          <li className="li">
            <strong>Legal Requirements:</strong> We may disclose your
            information if required to do so by law or in response to valid
            requests by public authorities.
          </li>
        </ul>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          5. Security of Your Information
        </h2>
        <p>
          We implement security measures to protect your personal information
          from unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee absolute security.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          6. Data Retention
        </h2>
        <p>
          We will retain your personal information only for as long as is
          necessary to fulfill the purposes outlined in this Privacy Policy
          unless a longer retention period is required or permitted by law.
        </p>

        <p>
          If you have any questions, suggestions, or feedback, we would love to
          hear from you! <br />
          Please contact us at : <br />
          <br />
          <a href="tel:+919307655505"> +91 9307655505</a> <br />
          <a href="mailto:prithvirajindulkar2003@gmail.com">
            {" "}
            prithvirajindulkar2003@gmail.com
          </a>
          <br />
          Address : Incubation Center ,Sanjay Ghodawat University, Kolhapur,
          Maharashtra, India.
        </p>
      </div>
    </div>
  );
};
