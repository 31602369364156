import {React} from 'react'
import { Footer } from '../footer/Footer'
import { Toaster } from 'react-hot-toast'

  
  const Layout = ({children } ) => {
  return (
    <div style={{
      backgroundColor: "#d4d2db",
    }}>
      <Toaster position="top-right" reverseOrder={false} />
      {/* <Header /> */}
        <main >
            {children}
        </main>
        {}
        <Footer/>
    </div>
  )
}

export default Layout