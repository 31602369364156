import React from "react";
import { NavLink } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export const DailyTarget = ({ data }) => {
  const today = new Date().toISOString().slice(0, 10);
  console.log(today)

  const Rs=data[0]?.date===today?data[0]?.TotalIncome:0
  console.log("data",data)
  const target = Rs < 5000 ? 5000 : 10000;
  const progress = Rs ? Math.trunc((Rs / target) * 100) : 0;
  // toast.success(progress);
  // var rightProgress = 0;
  // var LeftProgress = 0;

  // progress < 5 ? rightProgress = progress : LeftProgress = progress - 5;
  // rightProgress = 5;

  // var rightTimer = rightProgress * 0.15;
  // var leftTimer = LeftProgress * 0.15;

  return (
    <div>
      <div
        className="p-3 m-2 d-flex justify-content-center flex-column align-items-center"
        style={{
          border: "2px solid #11074C ",
          borderRadius: "5px",
        }}
      >
        <h2
          className="text-left text-xl font-bold ps-1 pt-1 pb-1"
          style={{ borderLeft: "10px solid #11074C", borderRadius: "5px" }}
        >
          <b>Daily Target Income</b>
        </h2>
        <CircularProgressbar
          value={progress}
          styles={buildStyles({
            textColor: "#11074C",
            pathColor: "#11074C",

            trailColor: "white",
          })}
          text={`${progress}%`}
        />
        {/* <div className="progress-circle ">
          <div className="progress blue">
            <span className="progress-right">
              <span
                className="progress-bar"
                style={{
                  animation: `loading-${rightProgress} ${rightTimer}s linear forwards`,
                }}
              ></span>
            </span>
            <span className="progress-left">
              <span
                className="progress-bar"
                style={{
                  animation: `loading-${LeftProgress} ${leftTimer}s linear forwards ${rightTimer}s`,
                }}
              ></span>
            </span>
            <div className="progress-value">{Math.trunc((income / target) * 100)}%</div>
          </div>
        </div> */}
        <h2>
          <b>&#x20B9; {Rs}</b>
        </h2>
        <h5>
          <b>OUT OF &#x20B9; {target}</b>
        </h5>
        <button type="button" className="btn btn-secondary">
          <NavLink to="/Admin/Orders">
            <span style={{ color: "white" }}>Learn More.</span>
          </NavLink>
        </button>
      </div>
    </div>
  );
};
//
//
