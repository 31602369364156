import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltFoodImg from "../../Components/Assets/FoodImg.png";
import { Link, useParams } from "react-router-dom";
import { Rating } from "../../Components/Rating";
import axios from "axios";
import { useAuth } from "../../Contex/AuthProvider";
import { useImg } from "../../Contex/AssetProvider";

export const EditProduct = () => {
  const [auth] = useAuth();
  const [isActive, setIsActive] = useState(false);
  const [category, setCategory] = useState([]);
  const [text, setText] = useState(
    Array.from({ length: category?.length }, () => false)
  );
  const [editingCategory, setEditingCategory] = useState(
    Array.from({ length: category?.length }, () => false)
  );
  const params = useParams();
  const [newImgUrl, setNewImgUrl] = useState(null);
  const [Img, setImg] = useState(null);

  // const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({
    name: null,
    quantity: null,
    Description: null,
    catogary: null,
    price: null,
    img: null,
    availability: true,
  });
  const fetchStoreData = async () => {
    try {
      if (auth?.user.storeId) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${auth?.user.storeId}`
        );
        // console.log("res:", res.data);
        if (res.data.store) {
          setCategory(res.data.store.catogaries);
        } else {
          // console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStoreData();
    // eslint-disable-next-line
  }, [auth]);
  const fetchData = useCallback(async () => {
    try {
      // setLoading(true);
      if (params.id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/item/${params.id}`
        );
        // console.log("item res : ", res);
        // setLoading(false);
        if (res.data) {
          setItem(res.data);
          // console.log("item", res.data);
        } else {
          // console.log(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [params.id]); // Add dependencies here

  useEffect(() => {
    if (params?.id) {
      // console.log("first");
      fetchData();
    }
  }, [fetchData, params?.id]); // Now fetchData is a dependency

  const AddItem = async () => {
    let newimgId = null;
    if (Img) {
      const formData = new FormData();
      formData.append("Name", item.name);
      formData.append("img", Img);
      newimgId = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/assets/addImg`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    // console.log(newimgId);
    if (
      item.name &&
      item.catogary &&
      item.price &&
      newimgId &&
      auth?.user.storeId
    ) {
      // console.log("item");
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/store/${auth?.user.storeId}`
      );

      await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/addproduct`,
        {
          name: item.name,
          catogary: item.catogary,
          quantity: item.quantity,
          price: item.price,
          Description: item.Description,
          img: newimgId.data.ImgId,
          availability: item.availability,
          StoreId: auth?.user.storeId,
          Storename: res.data.store.StoreName,
        }
      );
      toast.success("Product Added Successfully");
      // console.log("Product : ", Product);
    } else {
      toast.error("Fill Required Fields");
    }
  };
  const EditItem = async () => {
    let newimgId = null;
    if (Img) {
      const formData = new FormData();
      formData.append("Name", item.name);
      formData.append("img", Img);
      newimgId = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/assets/ReplaceImg/${item.img}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    // console.log(newimgId);
    if (
      params.id &&
      item.name &&
      item.catogary &&
      item.price &&
      auth?.user.storeId
    ) {
      // console.log("item");
      // const res = await axios.get(
      //   `${process.env.REACT_APP_API}/api/v1/store/store/${auth?.user.storeId}`
      // );
      // console.log("res : ", res.data.store.StoreName);
      await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/editproduct/${params.id}`,
        {
          name: item.name,
          catogary: item.catogary,
          quantity: item.quantity,
          price: item.price,
          Description: item.Description,
          availability: item.availability,
          img: newimgId ? newimgId.data.ImgId : item.img,
        }
      );
      toast.success("Product updated Successfully");
      // console.log("Product : ", Product);
    } else {
      toast.error("Fill Required Fields");
    }
  };
  // const handleImageChange = (e) => {
  //   setImg(URL.createObjectURL(e.target.files[0]));
  // };
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(item?.img); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };

    fetchImage();
  }, [item?.img,getImage]);
  return (
    <div className="Deskcontainer">
      {/* <Header state={params.id ? "Edit Product" : "Add Product"} /> */}
      <div className=" ps-3 ">
        <Link to="/Admin/MyStore" className="d-inline back-btn">
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="row">
        <div
          className="col-xl-5 col-md-12 p-4 mt-3 m-auto col"
          style={{ backgroundColor: "white" }}
        >
          <div className="ms-panel-header  border-bottom p-2 ">
            <h4 className="fw-bold text-center">
              {params.id ? "Edit " : "Add "} Product{" "}
            </h4>
          </div>
          <div className="ms-panel-body ">
            <form className="needs-validation clearfix" noValidate="">
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="Name">Product Name : </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      placeholder="Product Name"
                      required=""
                      value={item.name}
                      onChange={(e) => {
                        setItem((prevState) => ({
                          ...prevState,
                          name: e.target.value,
                        }));
                      }}
                      spellCheck="false"
                      data-ms-editor="true"
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="Name">Catogary</label>
                  <div className="input-group dropdown w-100 d-flex flex-column justify-content-between align-items-center form-control">
                    <div
                      onClick={(e) => {
                        setIsActive(!isActive);
                      }}
                      className="dropdown-btn d-flex align-items-center justify-content-between"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                    >
                      {item.catogary ? item.catogary : "Select Catogary"}
                      <i className="bi bi-chevron-down"></i>
                    </div>
                    <div
                      className="dropdown-content mt-3"
                      style={{
                        width: "100%",
                        textAlign: "left",
                        cursor: "pointer",
                        backgroundColor: "white",
                        display: isActive ? "block" : "none",
                        borderTop: "1px solid #11074C",
                      }}
                    >
                      {category?.map((cat, i) => (
                        <div
                          className="item py-1 d-flex align-items-center justify-content-between"
                          style={{ borderBottom: "1px solid #11074C" }}
                          key={i}
                        >
                          {!editingCategory[i] ? (
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div
                                className="text "
                                style={{ width: "-webkit-fill-available" }}
                                onClick={() => {
                                  setItem((prevState) => ({
                                    ...prevState,
                                    catogary: cat,
                                  }));
                                  toast.success("Catogary Selected");
                                  // console.log("clicked",cat)
                                  setIsActive(false);
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "17px",
                                    marginBottom: "0",
                                  }}
                                >
                                  {cat}
                                </p>
                              </div>
                              <div className="d-flex">
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const updatedEditingCategory = [
                                      ...editingCategory,
                                    ];
                                    updatedEditingCategory[i] = true;
                                    setEditingCategory(updatedEditingCategory);
                                    const updatedText = [...text];
                                    updatedText[i] = cat;
                                    setText(updatedText);
                                  }}
                                >
                                  <i className="bi bi-pencil fs-4"></i>
                                </span>

                                <span
                                  style={{ marginLeft: "20px" }}
                                  onClick={() => {
                                    const updatedCategory = [...category];
                                    updatedCategory.splice(i, 1);
                                    setCategory(updatedCategory);
                                  }}
                                >
                                  <i className="bi bi-trash fs-4"></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <input
                                type="text"
                                value={text[i]}
                                onChange={(e) => {
                                  const updatedText = [...text];
                                  updatedText[i] = e.target.value;
                                  setText(updatedText);
                                }}
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  border: "none",
                                  fontSize: "17px",
                                }}
                              />
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "white",
                                }}
                                onClick={() => {
                                  setEditingCategory(false);
                                  const updatedCategory = [...category];
                                  updatedCategory[i] = text[i];
                                  setCategory(updatedCategory);
                                  const updatedEditingCategory = [
                                    ...editingCategory,
                                  ];
                                  updatedEditingCategory[i] = false;
                                  setEditingCategory(updatedEditingCategory);
                                  // Add logic to confirm/set the edit here
                                }}
                              >
                                <i className="bi bi-check-lg fs-1"></i>
                              </button>
                            </>
                          )}
                        </div>
                      ))}
                      <div className="addnewcatogary">
                        <input
                          type="text"
                          style={{
                            width: "80%",
                            height: "30px",
                            border: "none",
                            fontSize: "18px",
                          }}
                          placeholder="Add New Catogary"
                          value={text[text.length]}
                          onChange={(e) => {
                            const updatedText = [...text];
                            updatedText[text.length - 1] = e.target.value;
                            setText(updatedText);
                          }}
                        />
                        <button
                          style={{ border: "none", backgroundColor: "white" }}
                          onClick={(e) => {
                            e.preventDefault();
                            const updatedCategory = [...category];
                            updatedCategory.push(text[text.length - 1]);
                            setCategory(updatedCategory);
                            const updatedText = [...text];
                            updatedText[text.length] = "";
                            setText(updatedText);
                          }}
                        >
                          <i className="bi bi-plus-lg fs-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="Quantity">Quantity : </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="Quantity"
                        placeholder="e.g. 50"
                        spellCheck="false"
                        data-ms-editor="true"
                        fdprocessedid="0y8c1v"
                        value={item.quantity}
                        onChange={(e) => {
                          setItem((prevState) => ({
                            ...prevState,
                            quantity: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="Price">Price : </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="Price"
                        placeholder="&#x20B9;10"
                        required=""
                        spellCheck="false"
                        data-ms-editor="true"
                        value={item.price}
                        onChange={(e) => {
                          setItem((prevState) => ({
                            ...prevState,
                            price: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="validationCustom12">Description : </label>
                  <div className="input-group">
                    <textarea
                      rows="2"
                      id="validationCustom12"
                      className="form-control"
                      placeholder="Description"
                      required=""
                      spellCheck="false"
                      data-ms-editor="true"
                      value={item.Description}
                      onChange={(e) => {
                        setItem((prevState) => ({
                          ...prevState,
                          Description: e.target.value,
                        }));
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mb-3 row">
                  <label htmlFor="Product-Image" className="col-4">
                    Product Image :{" "}
                  </label>
                  <div className="custom-file col ">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="Product-Image"
                      accept=".png,.jpg,.jpeg"
                      // value={item.Img}
                      onChange={(event) => {
                        setImg(event.target.files[0]);
                        setNewImgUrl(
                          URL.createObjectURL(event.target.files[0])
                        );
                      }}
                      // onChange={handleImageChange}
                    />
                    <div className="invalid-feedback">
                      Example invalid custom file feedback
                    </div>
                  </div>
                </div>
                <div className="form-check d-flex align-items-center flex-row">
                  <input
                    className="form-check-input "
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={item.availability}
                    onClick={(event) => {
                      setItem((prevState) => ({
                        ...prevState,
                        availability: event.target.checked,
                      }));
                      // console.log(event.target.checked);
                    }}
                  />
                  <label
                    className="form-check-label m-0 ms-3"
                    for="flexCheckChecked"
                  >
                    Make Available to Customers
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="col-xl-5 col-md-12 p-4 mt-3 mb-5 m-auto col"
          style={{ backgroundColor: "Wheat" }}
        >
          <div className="ms-panel-header  border-bottom p-2 ">
            <h4 className="fw-bold text-center">Preview Product</h4>
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "7px 5px 5px grey",
              marginBottom: "5px",
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="item-info " style={{ width: "60%" }}>
                <h5>{item ? item.catogary : "veg"}</h5>
                <div className="d-flex">
                  <h1 className="text-left me-4 mb-1">
                    {item.name ? item.name : "Product Name"}
                  </h1>
                </div>
                <Rating rating={"4"} />
                <div className="price ms-3 fs-4">
                  <b>&#x20B9; {item.price ? item.price : "Price"}</b>
                </div>
              </div>
              <div className="item-img text-center d-flex flex-end flex-column align-items-center">
                <img
                  src={newImgUrl ? newImgUrl : params.id ? imgSrc : AltFoodImg}
                  alt="Food"
                  style={{
                    height: "90px",
                    borderRadius: "10px",
                  }}
                />
                <div>
                  <div
                    className="btn-group add-btn"
                    role="group"
                    aria-label="Basic mixed styles example"
                    style={{
                      marginTop: "-20px",
                    }}
                  >
                    <button
                      type="button"
                      className=" btn "
                      style={{
                        padding: "2px 10px",
                        borderRadius: "10px",
                        background: "rgba(170, 172, 173, 0.8)",
                      }}
                    >
                      <b>Add</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <button
              className="btn btn-primary "
              type="button"
              onClick={() => (params.id ? EditItem() : AddItem())}
            >
              {params.id ? "Save Changes" : "Add To Menu"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
