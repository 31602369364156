import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../Components/Header/Header";

export const CancellationRefundPolicies = () => {
  return (
    <div
      className="Deskcontainer"
      style={{
        marginTop: "2rem",
        position: "relative",
        zIndex: 20,
        fontFamily: "Arial, sans-serif",
        fontSize: "1rem",
        lineHeight: "1.5",
        color: "#333",
      }}
    >
      <Header state={"Policy"} />
      <div className="ps-3 policy-back">
        <Link
          to="/Profile"
          className="d-inline back-btn"
          style={{ textDecoration: "none" }}
        >
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      <div className="mx-3 " style={{ paddingBottom: "4rem" }}>
        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          1. Introduction
        </h2>
        <p>
          At sgu.collegestorehub.com, we aim to ensure customer satisfaction.
          This Cancellation and Refund Policy outlines the circumstances under
          which you can cancel your order and request a refund.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          2. Order Cancellation
        </h2>
        <p>
          Once an order is placed, it cannot be canceled via the website
          platform. If you need to cancel your order, you must contact the store
          directly. The contact details of the store can be found on the store's
          page on our website.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          3. Refund Requests
        </h2>
        <p>
          To request a refund, you must contact the store from which you
          purchased the product or service. The store's refund policy will
          apply, and any refund requests will be subject to the store's terms
          and conditions.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          4. Store Contact Information
        </h2>
        <p>
          Each store on our platform is responsible for handling its own
          cancellations and refunds. You can find the contact information for
          each store on the store's page on our website.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          5. Processing Refunds
        </h2>
        <p>
          Once a refund is approved by the store, the refund will be processed
          using the payment method preffered by the store (original payment
          method / cash). The time it takes for the refund to be processed and
          appear in your account may vary depending on your payment provider and
          store.
        </p>

        <h2
          style={{ marginTop: "2rem", fontSize: "1.5rem", fontWeight: "bold" }}
        >
          6. Contact Us
        </h2>
        <p>
          If you have any questions, suggestions, or feedback, we would love to
          hear from you! <br />
          Please contact us at : <br />
          <br />
          <a href="tel:+919307655505"> +91 9307655505</a> <br />
          <a href="mailto:prithvirajindulkar2003@gmail.com">
            {" "}
            prithvirajindulkar2003@gmail.com
          </a>
          <br />
          Address : Incubation Center ,Sanjay Ghodawat University, Kolhapur,
          Maharashtra, India.
        </p>
      </div>
    </div>
  );
};
