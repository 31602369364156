import React from "react";
import { Header } from "../../Components/Header/Header";
import { useCart } from "../../Contex/CartProvider";
import { CartCard } from "../../Components/cards/CartCard";

export const Cart = () => {
  const [cart] = useCart();
  function groupItemsByStoreId(array) {
    const grouped = array.reduce((acc, item) => {
      acc[item.details.StoreId] = acc[item.details.StoreId] || [];
      acc[item.details.StoreId].push(item);
      return acc;
    }, {});
    return Object.values(grouped);
  }

  const grouped = groupItemsByStoreId(cart);
  // console.log("gcart:", grouped);

  // console.log(itm)
  // console.log(cart);
  // const [ItmCtn, setItmCtn] = useState(1);
  return (
    <div className="Deskcontainer">
      <Header state={"Cart"} />
      {cart.length === 0 ? (
        <div className="text-center pt-5">
          <h2>Your cart is empty</h2>
          <p>
            You have no items in your shopping cart. Add items to your cart!
          </p>
        </div>
      ) : (
        grouped.map((i, index) => <CartCard i={i} key={index} />)
      )}
      {
        //   cart.map((c) => {
        //     const store=storeInfo.find((s) => s.storeId === c.storeId);
        //     console.log(store);
        //     // let total=0;
        //     return(<div
        //       className="mx-2"
        //       style={{
        //         border: "1px solid #11074C",
        //         borderRadius: "20px",
        //       }}
        //     >
        //     {/* {index=storeInfo.find((s) => s.storeId === c.storeId)} */}
        //     {/* <div className="d">{index}</div> */}
        //       <div
        //         className="row mx-0 pt-3 pb-3"
        //         style={{
        //           borderBottom: "1px solid #11074C",
        //           backgroundColor: "#98c5d4",
        //           borderRadius: "20px 20px 0px 0px",
        //         }}
        //       >
        //         <div className="col-5">
        //           <img
        //             alt="profile"
        //             // src={store.img}
        //             style={{ height: "80px", borderRadius: "15px" }}
        //           />
        //         </div>
        //         <div className="col">
        //           <h1>{store?.storeName}</h1>
        //           <h5 className="mb-1">
        //             <i className="bi bi-dot fs-5"></i>
        //             <b>Indian</b> <i className="bi bi-dot fs-5"></i>
        //             <b>Veg</b>
        //           </h5>
        //           <div
        //             className="rating "
        //             style={{
        //               width: "fit-content",
        //               padding: "0px 10px",
        //               borderRadius: "20px",
        //               backgroundColor: "white",
        //             }}
        //           >
        //             <Rating rating={store?.rating} />
        //           </div>
        //         </div>
        //       </div>
        //       <div
        //         className=" justify-content-between align-items-center mx-0 p-2"
        //         style={{
        //           backgroundColor: "white",
        //           borderRadius: "0px 0px 20px 20px",
        //         }}
        //       >
        //         <ul
        //           className="mb-2 ps-2"
        //           style={{
        //             listStyle: "circle",
        //             fontWeight: "bold",
        //             fontSize: "20px",
        //           }}
        //         >
        //           {c.Items?.map((i)=>{
        //             const itemDetails=store.Items?.find((s) => s.itemId === i.itemId);
        //             console.log(itemDetails);
        //             return(
        //               <li className="row">
        //             <p className="col mb-1">{itemDetails.name}</p>
        //             <div
        //               className="col btn-group add-btn"
        //               role="group"
        //               aria-label="Basic mixed styles example"
        //               style={{
        //                 height: "30px",
        //               }}
        //             >
        //               <button
        //                 type="button"
        //                 className="ctn btn "
        //                 onClick={(e) => {
        //                   setItmCtn(ItmCtn - 1);
        //                 }}
        //               >
        //                 <b>-</b>
        //               </button>
        //               <div className="ctn " style={{ borderRadius: "0" }}>
        //                 <b>{ItmCtn}</b>
        //               </div>
        //               <button
        //                 type="button"
        //                 className="ctn btn"
        //                 onClick={(e) => {
        //                   setItmCtn(ItmCtn + 1);
        //                 }}
        //               >
        //                 <b>+</b>
        //               </button>
        //             </div>
        //             <p className="col mb-1">&#x20B9;{itemDetails.prize}</p>
        //           </li>
        //             )
        //           })}
        //         </ul>
        //         <hr className="m-0 pt-1" />
        //         <div className="text-end pe-4">
        //           <h3 className="fw-bold">Total : &#x20B9;300</h3>
        //         </div>
        //         <button
        //           type="button"
        //           className="btn w-100 fs-3 mb-2"
        //           style={{ backgroundColor: "#ff5757" }}
        //         >
        //           <b>Pay 300</b>
        //         </button>
        //       </div>
        //     </div>)
        //   }
        //     // <div className="div">
        //     //   {c.storeId}
        //     //   {c.Items.map((i) => (
        //     //     <div className="d">{i.itemId}</div>
        //     //   ))}
        //     //   {console.log(c.Items)}
        //     // </div>
        // )
      }
      {/* <div
        className="mx-2"
        style={{
          border: "1px solid #11074C",
          borderRadius: "20px",
        }}
      >
        <div
          className="row mx-0 pt-3 pb-3"
          style={{
            borderBottom: "1px solid #11074C",
            backgroundColor: "#98c5d4",
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <div className="col-5">
            <img
              alt="profile"
              src={pizza}
              style={{ height: "80px", borderRadius: "15px" }}
            />
          </div>
          <div className="col">
            <h1>pizza Store</h1>
            <h5 className="mb-1">
              <i className="bi bi-dot fs-5"></i>
              <b>Indian</b> <i className="bi bi-dot fs-5"></i>
              <b>Veg</b>
            </h5>
            <div
              className="rating "
              style={{
                width: "fit-content",
                padding: "0px 10px",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            >
              <Rating rating={"4.4"} />
            </div>
          </div>
        </div>
        <div
          className=" justify-content-between align-items-center mx-0 p-2"
          style={{
            backgroundColor: "white",
            borderRadius: "0px 0px 20px 20px",
          }}
        >
          <ul
            className="mb-2 ps-2"
            style={{
              listStyle: "circle",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            <li className="row">
              <p className="col mb-1">Pizza</p>
              <div
                className="col btn-group add-btn"
                role="group"
                aria-label="Basic mixed styles example"
                style={{
                  height: "30px",
                }}
              >
                <button
                  type="button"
                  className="ctn btn "
                  onClick={(e) => {
                    setItmCtn(ItmCtn - 1);
                  }}
                >
                  <b>-</b>
                </button>
                <div className="ctn " style={{ borderRadius: "0" }}>
                  <b>{ItmCtn}</b>
                </div>
                <button
                  type="button"
                  className="ctn btn"
                  onClick={(e) => {
                    setItmCtn(ItmCtn + 1);
                  }}
                >
                  <b>+</b>
                </button>
              </div>
              <p className="col mb-1">&#x20B9;100</p>
            </li>
            <li className="row">
              <p className="col mb-1">Pizza</p>
              <div
                className="col btn-group add-btn"
                role="group"
                aria-label="Basic mixed styles example"
                style={{
                  height: "30px",
                }}
              >
                <button
                  type="button"
                  className="ctn btn "
                  onClick={(e) => {
                    setItmCtn(ItmCtn - 1);
                  }}
                >
                  <b>-</b>
                </button>
                <div className="ctn " style={{ borderRadius: "0" }}>
                  <b>{ItmCtn}</b>
                </div>
                <button
                  type="button"
                  className="ctn btn"
                  onClick={(e) => {
                    setItmCtn(ItmCtn + 1);
                  }}
                >
                  <b>+</b>
                </button>
              </div>
              <p className="col mb-1">&#x20B9;100</p>
            </li>
          </ul>
          <hr className="m-0 pt-1" />
          <div className="text-end pe-4">
            <h3 className="fw-bold">Total : &#x20B9;300</h3>
          </div>
          <button
            type="button"
            className="btn w-100 fs-3 mb-2"
            style={{ backgroundColor: "#ff5757" }}
          >
            <b>Pay 300</b>
          </button>
        </div>
      </div> */}
    </div>
  );
};
