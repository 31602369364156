import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../../Components/Header/Header";
import { DailyTarget } from "../../Components/Dashboard/DailyTarget";
import { OrdersCard } from "../../Components/Dashboard/OrdersCard";
import { Orders } from "../../Components/Dashboard/Orders";
import { useSocket } from "../../Contex/WebSocket";
import toast from "react-hot-toast";
import axios from "axios";
import { useAuth } from "../../Contex/AuthProvider";

export const StoreDashboard = () => {
  const [DailyData, setDailyData] = useState([]);
  // console.log("dailydata:", DailyData);
  const [auth] = useAuth();
  const [webSocket] = useSocket();
  const getdata = useCallback(async () => {
    if (auth?.user?.storeId) {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/getDailyData/${auth?.user?.storeId}`
      );
      setDailyData(res?.data?.Data);
      // console.log("data:", res?.data?.Data);
    }
  }, [auth]);
  useEffect(() => {
    getdata();
  }, [getdata]);
  useEffect(() => {
    if (webSocket) {
      webSocket.on("NewOrder", (Order) => {
        // console.log(Order);
        getdata();
        toast.success("New Order");
      });
    } else {
      // console.log("first");
    }
  }, [webSocket, getdata]);
  return (
    <div className="Deskcontainer" style={{ paddingBottom: "1rem" }}>
      <Header state="Dashboard" />
      <div className="container ">
        <div className="row">
          <div className="col-md-5 col-sm-12 mt-3">
            <DailyTarget data={DailyData} />
          </div>
          <div className="col-md-5 col-sm-12 mt-5">
            <OrdersCard data={DailyData} />
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-5 col-sm-12 mt-5 m-1">
          <TopMenu data={DailyData[0]} />
        </div>
        <div className="col-md-7 col-sm-12 mt-5 m-1">
          <CustomerMap />
        </div>
      </div> */}
      <div className="row py-5 m-1" style={{ width: "95%" }}>
        <Orders />
      </div>
    </div>
  );
};
