import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import defaultProfile from "../../Components/Assets/blank-profile-pic.png";
import { useAuth } from "../../Contex/AuthProvider";
import axios from "axios";
import { useImg } from "../../Contex/AssetProvider";
import toast from "react-hot-toast";
import { Header } from "../../Components/Header/Header";
export const EditProfile = () => {
  const [auth, setAuth] = useAuth();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setphone] = useState();
  const [img, setImg] = useState();
  const [newImg, setNewImg] = useState(null);
  const [newImgUrl, setNewImgUrl] = useState(null);
  useEffect(() => {
    setName(auth?.user?.name);
    setEmail(auth?.user?.email);
    setphone(auth?.user?.phone);
    setImg(auth?.user?.ProfilePicture);
  }, [auth]);
  const updateProfile = async () => {
    let newimgId = null;
    if (newImg) {
      const formData = new FormData();
      formData.append("Name", "ProfileImg");
      formData.append("img", newImg);
      // console.log("formdata : ", newImg);
      newimgId = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/assets/${
          img ? `ReplaceImg/${img}` : "addImg"
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    const res = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/auth/UpdateProfile/${auth?.user?._id}`,
      {
        name,
        email,
        phone,
        ProfilePicture: newimgId ? newimgId.data.ImgId : img,
      }
    );
    // console.log(res.data);
    await setAuth({
      ...auth,
      user: res?.data?.updatedUser,
      name: res?.data?.updatedUser.name,
    });
    const lauth = {
      user: res.data.updatedUser,
      token: auth?.token,
      name: res?.data?.updatedUser.name,
    };
    localStorage.setItem("auth", JSON.stringify(lauth));
    toast.success("Profile Updated");
  };
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const src = await getImage(img); // Fetch the image for this item
      setImgSrc(src); // Set the fetched image as the source
    };

    fetchImage();
  }, [img,getImage]);
  return (
    <div className="Deskcontainer h-100" ><Header state={"Edit Profile"} />
      <div className="  pt-3">
        <div className="d-flex flex-row justify-content-between mt-2">
          <Link to="/profile" className="d-inline back-btn ms-3">
            <b>
              <i className="bi bi-arrow-left"></i> Back
            </b>
          </Link>
        </div>
        <div className="profile text-center mb-4">
          <div className="col-3 my-2 w-100 d-flex justify-content-center">
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                alt="profile"
                src={newImgUrl ? newImgUrl : imgSrc ? imgSrc : defaultProfile}
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "150px",
                  zIndex: 1,
                }}
              />
              <i
                className="bi bi-camera d-inline p-1 fw-bold fs-4"
                style={{
                  backgroundColor: "red",
                  borderRadius: "20px",
                  height: "40px",
                  width: "40px",
                  color: "white",
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  zIndex: 2,
                }}
              >
                <input
                  style={{
                    opacity: 0,
                    position: "absolute",
                    left: 0,
                    width: "100%",
                  }}
                  type="file"
                  display="none"
                  accept=".png,.jpg,.jpeg"
                  id="inputGroupFile01"
                  onChange={(e) => {
                    setNewImgUrl(URL.createObjectURL(e.target.files[0]));
                    setNewImg(e.target.files[0]);
                    // toast.success(e.target.files[0].size);
                  }}
                ></input>
              </i>
            </div>
          </div>
        </div>
        <div
          className="details pt-3 px-3 pb-3 mx-2"
          style={{
            backgroundColor: "white",
            borderTop: "1px",
            borderRadius: "40px",
          }}
        >
          <form>
            <div className="form-group" style={{ marginBottom: "10px" }}>
              <label
                htmlFor="username"
                style={{ fontWeight: "bold", color: "grey", marginBottom: "0" }}
              >
                Name :
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="Enter username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  padding: "10px",
                  border: "0",
                  borderBottom: "2px solid #11074C",
                }}
              />
            </div>

            <div className="form-group" style={{ marginBottom: "10px" }}>
              <label
                htmlFor="email m-0"
                style={{ fontWeight: "bold", color: "grey", marginBottom: "0" }}
              >
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                style={{
                  padding: "10px",
                  border: "0",
                  borderBottom: "2px solid #11074C",
                }}
              />
            </div>

            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label
                htmlFor="phone"
                style={{ fontWeight: "bold", color: "grey", marginBottom: "0" }}
              >
                Phone
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                placeholder="Enter phone number"
                style={{
                  padding: "10px",
                  border: "0",
                  borderBottom: "2px solid #11074C",
                }}
              />
            </div>
          </form>
          <div className=" d-flex">
          <button
            type="button"
            className="btn m-auto px-5 w-75 fs-5 fw-bold"
            style={{
              backgroundColor: "#11074C",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
            onClick={updateProfile}
          >
            Update Profile
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};
