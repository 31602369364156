import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useImg } from "../../Contex/AssetProvider";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
export const MenuItem = ({ id }) => {
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/item/${id}`
        );
        // console.log("item res : ", res);
        setLoading(false);
        if (res?.data) {
          setItem(res.data);
          // console.log("item", res.data);
        } else {
          // console.log(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [id]);
  // console.log("Item:",item)
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  // const changeAvailability = async () => {
  //   const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/store/item/${id}`);
  // };
  const { getImage } = useImg(); // Get the getImage function from the context
  const [imgSrc, setImgSrc] = useState(null);

  const fetchImage = useCallback(async () => {
    const src = await getImage(item?.img); // Fetch the image for this item
    setImgSrc(src); // Set the fetched image as the source
  }, [getImage, item?.img]);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);
  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-content-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          className="store-card mt-4"
          style={{
            marginTop: "20px",
            margin: "auto",
            border: "2px",
            borderRadius: "20px",
            boxShadow: "10px 10px 5px grey",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            color: "#11074C",
            backgroundColor: "white",
          }}
        >
          {imgSrc ? (
            <img
              // src={`${process.env.REACT_APP_API}/api/v1/assets/getImg/${item?.img}`}
              src={imgSrc}
              alt=""
              style={{ width: "100%", borderRadius: "20px" }}
            />
          ) : (
            <Skeleton
              style={{ width: "100%", borderRadius: "20px", height: "90px" }}
            />
          )}

          <div className="store-disc p-3">
            <div className="d-flex flex-row justify-content-between">
              <h5 style={{ fontWeight: "bold", marginBottom: "1%" }}>
                {item?.name}
              </h5>
              <h6>Price: &#x20B9;{item?.price}</h6>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <h6 style={{ marginBottom: "1%" }}>
                <b>Quantity :</b>{" "}
                {item?.quantity
                  ? item?.quantity
                  : item?.quantity === 0
                  ? "0"
                  : "Null"}
              </h6>
              {item?.quantity === null || item?.quantity ? (
                <div
                  style={{
                    backgroundColor: "green",
                    padding: "3px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  In Stock
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "red",
                    padding: "3px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  Out of Stock
                </div>
              )}
            </div>
            <p>{item?.descrption}</p>
            <div className="d-flex flex-row justify-content-between">
              <button
                type="button"
                className={`btn btn-${
                  item?.availability ? "danger" : "primary"
                }`}
                onClick={async () => {
                  try {
                    if (item?._id) {
                      const res = await axios.post(
                        `${process.env.REACT_APP_API}/api/v1/store/ItemAvailability/${item?._id}`,
                        { availability: !item?.availability }
                      );
                      // console.log("res:", res?.data?.item);
                      // console.log(res?.data?.item?.availability);
                      setItem(res?.data?.item);
                      toast.success("updated");
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {item?.availability ? "Make Unavailable" : "Make Available"}
              </button>
              <Link to={`/Admin/MyStore/EditProduct/${item?._id}`}>
                <div type="button" className="btn btn-dark">
                  Edit.
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export const AddItem = () => {
  return (
    <div>
      <Link to={`/Admin/MyStore/EditProduct`}>
        <div
          className="store-card mt-4"
          style={{
            marginTop: "20px",
            margin: "auto",
            padding: "auto",
            border: "2px",
            borderRadius: "20px",
            boxShadow: "10px 10px 5px grey",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "#11074C",
            height: "150px",
            backgroundColor: "white",
          }}
        >
          <h1 style={{ fontSize: "80px" }}>+</h1>
          <h2>
            <b>Add Item</b>
          </h2>
        </div>
      </Link>
    </div>
  );
};
