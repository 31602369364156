import React, { useState, useEffect, createContext, useContext } from "react";
import axios from "axios";

// Create Context
const ImageContext = createContext();

// Provider component
const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const createIndexedDB = async () => {
    let db;
    let request = indexedDB.open("imageDB", 1);

    request.onupgradeneeded = function (event) {
      db = event.target.result;
      db.createObjectStore("images");
    };
  };

  const loadImagesFromIndexedDB = async () => {
    const request = indexedDB.open("imageDB", 1);
    request.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("images", "readonly");
      const store = transaction.objectStore("images");
      const getAllRequest = store.getAll();
      getAllRequest.onsuccess = function (event) {
        const images = event.target.result;
        setImages(images);
      };
    };
  };

  useEffect(() => {
    // Create IndexedDB if it doesn't exist
    createIndexedDB();
    // Load images from IndexedDB
    loadImagesFromIndexedDB();
  }, []);

  useEffect(() => {
    // Save images to IndexedDB whenever they change
    const saveImagesToIndexedDB = async () => {
      const request = indexedDB.open("imageDB", 1);
      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction("images", "readwrite");
        const store = transaction.objectStore("images");

        images.forEach((image) => {
          store.put(image, image.id);
        });

        transaction.oncomplete = function () {
          db.close();
        };
      };
    };

    saveImagesToIndexedDB();
  }, [images]);

  function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const [fetchingIds, setFetchingIds] = useState([]);

  const getImage = async (id) => {
    if (id && images && !fetchingIds.includes(id)) {
      const index = images?.findIndex((image) => image?.id === id);
      if (index === 0) {
        return images[0].imgData;
      }
      if (index === -1) {
        setFetchingIds((prevIds) => [...prevIds, id]);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/assets/sendImg/${id}`
        );
        const data = response.data;
        if (data && data.img && data.img.data) {
          let imageUrl = arrayBufferToBase64(data.img.data?.data);
          let base64Image = `data:${data.img.contentType};base64,${imageUrl}`;

          setImages((prevImages) => [
            ...prevImages,
            { id:id, imgData: base64Image },
          ]);
          setFetchingIds((prevIds) =>
            prevIds.filter((prevId) => prevId !== id)
          );
          return base64Image;
        }
      }
      if (index !== -1) {
        return images[index]?.imgData;
      }
    }
  };

  return (
    <ImageContext.Provider value={{ images, getImage }}>
      {children}
    </ImageContext.Provider>
  );
};

const useImg = () => useContext(ImageContext);

export { useImg, ImageProvider };
