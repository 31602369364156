import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Document, Page, pdfjs } from "react-pdf";
import toast from "react-hot-toast";
import { useAuth } from "../Contex/AuthProvider";
// import color from "../Components/Assets/color.png";
// import blackwhite from "../Components/Assets/b&w.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
export const PItems = () => {
  const params = useParams();
  const [auth] = useAuth();
  const [click, setClick] = useState(true);
  const [printOptionsScreen, setPrintOptionsScreen] = useState(false);
  const pagesPerSheetArray = [1, 2, 4, 6, 9, 16];
  const setOverflow = (bool) => {
    if (bool) {
      setPrintOptionsScreen(true);
      document.body.style.overflowY = "auto";
    } else {
      setPrintOptionsScreen(false);
      document.body.style.overflowY = "hidden";
    }
    console.log("Overflow", document.body.style.overflowY);
  };
  const [Pages, setPages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState([]);
  const [printOptions, setPrintOptions] = useState([]);
  const navigate = useNavigate();
  const [store, setStore] = useState({});
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState();
  //   console.log("store", store);
  // console.log("items", items);
  const fetchData = async () => {
    try {
      setLoading(true);
      if (params.id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${params.id}`
        );
        if (res.data.success) {
          setStore(res.data.store);
          setLoading(false);
        } else {
          // console.log(res);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  // const isOpen = () => {
  //   const now = new Date();
  //   const hours = now.getHours().toString().padStart(2, "0");
  //   const minutes = now.getMinutes().toString().padStart(2, "0");
  //   const currentTime = `${hours}:${minutes}`;
  //   console.log("time:",currentTime)
  // };
  // isOpen();
  function isOpen(openTime, closeTime) {
    const now = new Date();
    const open = new Date(now);
    const close = new Date(now);

    open.setHours(openTime.split(":")[0], openTime.split(":")[1], 0, 0);
    close.setHours(closeTime.split(":")[0], closeTime.split(":")[1], 0, 0);

    return now >= open && now < close;
  }

  const currentlyOpen = isOpen(String(store.OpenTime), String(store.CloseTime));

  // const [visibleItems, setVisibleItems] = useState(5);
  // const handleScroll = (e) => {
  //   const { scrollTop, clientHeight, scrollHeight } = e.target;
  //   console.log(scrollTop, clientHeight, scrollHeight)
  //   if (scrollHeight - scrollTop > clientHeight-2) {
  //     setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  //   }
  // };
  const getFileSize = (size) => {
    if (size >= 1024 && size < 1048576) {
      return (size / 1024).toFixed(2) + " KB";
    } else if (size >= 1048576) {
      return (size / 1048576).toFixed(2) + " MB";
    } else {
      return size + " Bytes";
    }
  };
  const uploadFiles = async () => {
    if (!auth.user) {
      toast.error("Please Login to upload files");
      navigate("/Login");
      return;
    }
    const formData = new FormData();
    let empty = true;
    const updatedFileUploadStatus = [...fileUploadStatus];
    for (let i = 0; i < files.length; i++) {
      console.log(files[i].name, files[i]);
      if (files[i].type !== "application/pdf") {
        toast.error("Only PDF files are allowed");
        return;
      }
      if (fileUploadStatus[i] === "selected") {
        formData.append(files[i].name, files[i]);
        empty = false;
        updatedFileUploadStatus[i] = "uploading";
        console.log("updatedFileUploadStatus", updatedFileUploadStatus);
      }
    }
    setFileUploadStatus(updatedFileUploadStatus);
    try {
      if (!empty) {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/files/upload`,
          formData
        );
        empty = true;
        const updatedFileUploadStatus = [...fileUploadStatus];
        updatedFileUploadStatus.fill("uploaded");
        setFileUploadStatus(updatedFileUploadStatus);
        if (uploadedFiles.length > 0) {
          setUploadedFiles([...uploadedFiles, ...res.data.files]);
        } else {
          setUploadedFiles([...res.data.files]);
        }
        toast.success("Files Uploaded Successfully");
        console.log(res);
      } else {
        toast.error("All Files Already Uploaded");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Uploading Files");
      const updatedFileUploadStatus = [...fileUploadStatus];
      updatedFileUploadStatus.fill("selected");
      setFileUploadStatus(updatedFileUploadStatus);
    }
  };
  const createOrder = async () => {
    setClick(false);
    let Files = [];
    for (let i = 0; i < files.length; i++) {
      Files.push({
        name: uploadedFiles[i].filename,
        printOptions: printOptions[i],
        price: calculatePrice(printOptions[i], Pages[i]),
      });
    }
    console.log("send files: ", Files);
    console.log("env: ", process.env.REACT_APP_LOCALHOST_API);
    try {
      const Order = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/order/PrintOrder`,
        {
          StoreId: store._id,
          Files: Files,
          TotalPrice: calculateTotalPrice(printOptions, Pages),
          Buyer: auth.user,
        }
      );
      console.log("order : ", Order);
      document.body.style.overflowY = "auto";
      toast.success(
        "Order Placed Successfully , Collect your order from counter after some time."
      );
      navigate("/orders");
      setClick(true);
    } catch (error) {
      setClick(true);
      setOverflow(false);
      document.body.style.overflowY = "auto";
      console.error("Error : ", error);
    }
  };
  const calculatePrice = (printOptions, pages) => {
    const ColorXSide = `${printOptions.color} X ${printOptions.sides}`;
    console.log("ColorXSide", ColorXSide);
    console.log("printOptions", printOptions, pages);

    const pricePerPage = store.PrintOptions.colorXside.find(
      (option) => option.name === ColorXSide
    ).price;
    console.log("pricePerPage", pricePerPage);

    let price = 0;
    let newPages = Math.ceil(parseInt(pages) / printOptions.pagesPerSheet);
    console.log("new pages", newPages);
    console.log("binfing", printOptions.binding, store.PrintOptions.binding);
    for (let i = 0; i < store.PrintOptions.binding.length; i++) {
      if (store.PrintOptions.binding[i].name === printOptions.binding) {
        price += store.PrintOptions.binding[i].price;
      }
    }
    if (printOptions.sides === "Double Side") {
      const ColorXSingle = `${printOptions.color} X Single Side`;
      const pricePerPageSingle = store.PrintOptions.colorXside.find(
        (option) => option.name === ColorXSingle
      ).price;
      if (newPages % 2 === 1) {
        price += pricePerPage * (newPages - 1);
        price += pricePerPageSingle * 1;
      } else {
        price += pricePerPage * newPages;
      }
    } else {
      price += pricePerPage * newPages;
    }
    price *= printOptions.copies;
    return price;
  };
  const calculatePages = (printOptions, pages) => {
    let newPages = Math.ceil(parseInt(pages) / printOptions.pagesPerSheet);
    return newPages;
  };
  const calculateTotalPrice = (printOptions, pages) => {
    let TotalPrice = 0;
    for (let i = 0; i < printOptions.length; i++) {
      TotalPrice += calculatePrice(printOptions[i], pages[i]);
    }
    return TotalPrice;
  };
  return (
    <div
      className="Deskcontainer"
      style={{ marginTop: "2rem", position: "relative", zIndex: 20 }}
    >
      {/* <Header /> */}
      <div className=" ps-3 ">
        <Link to="/" className="d-inline back-btn">
          <b>
            <i className="bi bi-arrow-left"></i> Back
          </b>
        </Link>
      </div>
      {loading ? (
        <SkeletonTheme
          baseColor="rgb(205 213 220 / 64%)"
          highlightColor="white"
        >
          <div className="mx-4">
            <div className="storeInfo my-3 mx-5 ">
              <h1 className="storeName mb-0">
                <Skeleton width={"100px"} />
              </h1>
              <h5 className="mb-1">
                <Skeleton count={2} width={"150px"} />
              </h5>
              <div className="rating">
                <Skeleton width={"100px"} />
              </div>
            </div>
            <hr />
            <div className="text-center d-flex flex-column">
              <h5 className="mb-1 text-center mx-5">
                <b>
                  <Skeleton />
                </b>{" "}
              </h5>
              <p className="my-2">
                <Skeleton count={2} />
              </p>
            </div>
            <hr />
            <h1 className="text-center mx-5">
              <Skeleton width={"90px"} />
            </h1>
            <div className="menu m-2">
              <Skeleton count={5} />
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="" style={{ paddingBottom: "100px" }}>
          <div className="storeInfo w-100 pt-3 text-center">
            <h1 className="storeName mb-0">{store.StoreName} </h1>
            <h5 className="mb-1">
              <i className="bi bi-dot fs-3"></i>
              {store.Spec1} <i className="bi bi-dot fs-3"></i>
              {store.Spec2}
            </h5>
            {/* <div
              className="rating "
              style={{
                width: "fit-content",
                padding: "0px 10px",
                borderRadius: "20px",
                backgroundColor: "white",
              }}
            >
              <Rating rating={store.rating} ratecount={"334"} />
            </div> */}
          </div>
          <hr></hr>
          <div className="text-center d-flex flex-column">
            <h5 className="mb-1 text-center">
              <b>Open Time </b>{" "}
              <p className="my-2">
                {store.OpenTime} to {store.CloseTime}{" "}
              </p>
            </h5>
            <div className="text-center d-flex justify-content-center">
              <div
                className=" d-inline d-flex text-center fw-bold"
                style={{
                  padding: "2px 10px",
                  borderRadius: "20px",
                  backgroundColor: `${currentlyOpen ? "green" : "red"}`,
                  fontSize: "15px",
                  color: "white",
                  justifyContent: "center",
                  width: "fit-content",
                }}
              >
                {currentlyOpen ? "Currently Open" : "Currently Closed"}
              </div>
            </div>
          </div>
          <hr></hr>
          {/* <h1 className="text-center">Menu</h1> */}

          <div
            className="mx-2 p-2 "
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
            }}
          >
            <h2 className="mb-0">
              <b>Upload Files</b>
            </h2>
            {/* <h6 className="ps-2">Only PDF files allowed</h6> */}
            <div
              className="upload-box m-2 "
              style={{
                border: "2px dashed black",
                backgroundColor: "#dedbdb",
                height: "200px",
                position: "relative",
                borderRadius: "15px",
              }}
            >
              <div
                className="upload-content"
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  flexDirection: "column",
                }}
                onClick={() => {
                  if (!auth.user) {
                    toast.error("Please Create Account First");
                    navigate("/CreateAccount");
                    return;
                  }
                }}
              >
                <input
                  type="file"
                  style={{
                    opacity: "0",
                    position: "absolute",
                    height: "100%",
                    display: `${auth.user ? "block" : "none"}`,
                  }}
                  accept=".pdf"
                  id="inputGroupFile01"
                  multiple
                  onChange={(e) => {
                    console.log(e.target.files);
                    if (files?.length > 0) {
                      setFiles([...files, ...e.target.files]);
                      setFileUploadStatus([
                        ...fileUploadStatus,
                        ...new Array(e.target.files.length).fill("selected"),
                      ]);
                      setPrintOptions([
                        ...printOptions,
                        ...new Array(e.target.files.length).fill({
                          color: "B & W",
                          sides: "Single Side",
                          copies: 1,
                          layout: "Portrait",
                          pagesPerSheet: 1,
                          binding: "Loose leafs",
                        }),
                      ]);
                    } else {
                      setFiles([...e.target.files]);
                      setFileUploadStatus(
                        new Array(e.target.files.length).fill("selected")
                      );
                      setPrintOptions(
                        new Array(e.target.files.length).fill({
                          color: "B & W",
                          sides: "Single Side",
                          copies: 1,
                          layout: "Portrait",
                          pagesPerSheet: 1,
                          binding: "Loose leafs",
                        })
                      );
                    }
                  }}
                ></input>

                <i class="bi bi-cloud-upload fs-1"></i>
                <h6 className="fw-bold mb-0">
                  <span style={{ color: "blue" }}>Click to upload </span>
                </h6>
                <h8>Only PDF files are allowed</h8>
              </div>
            </div>
            <div className="files pt-2">
              {files?.length > 0 ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="fw-bold w-100 ps-2">
                    {files?.length} {files?.length === 1 ? "File" : "Files"}{" "}
                    Selected
                  </h3>
                  {Object.entries(files ? files : "").map(
                    ([key, value], index) => {
                      const uploadStatus = fileUploadStatus[index];
                      console.log("uploadStatus", uploadStatus);
                      return (
                        <div
                          className="d-flex  align-items-center flex-column"
                          style={{
                            width: "100%",
                            borderRadius: "15px",
                            border: "1px solid black",
                            padding: "10px",
                            paddingTop: "15px",
                            margin: "10px",
                            position: "relative",
                          }}
                        >
                          <Document
                            file={files[index]}
                            onLoadSuccess={({ numPages }) => {
                              const num = [...Pages];
                              num[index] = numPages;
                              setPages(num);
                            }}
                          ></Document>
                          <i
                            className="bi bi-x fs-1 fw-bold"
                            style={{
                              position: "absolute",
                              zIndex: "10",
                              top: "-5px",
                              right: "0px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const updatedFiles = [...files];
                              updatedFiles.splice(index, 1);
                              setFiles(updatedFiles);
                              const num = [...Pages];
                              num.splice(index, 1);
                              setPages(num);
                              const updatedUploadedFiles = [...uploadedFiles];
                              updatedUploadedFiles.splice(index, 1);
                              setUploadedFiles(updatedUploadedFiles);
                              const updatedFileUploadStatus = [
                                ...fileUploadStatus,
                              ];
                              updatedFileUploadStatus.splice(index, 1);
                              setFileUploadStatus(updatedFileUploadStatus);
                              const updatedPrintOptions = [...printOptions];
                              updatedPrintOptions.splice(index, 1);
                              setPrintOptions(updatedPrintOptions);
                            }}
                          ></i>
                          <div className="w-100 d-flex flex-row">
                            <i
                              class="bi bi-file-earmark-pdf px-1"
                              style={{
                                color: "red",
                                fontSize: "30px",
                              }}
                            ></i>
                            <span
                              className=""
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <b
                                style={{
                                  color: "black",
                                  fontSize: "15px",
                                }}
                              >
                                {uploadStatus === "uploading"
                                  ? "Uploading"
                                  : uploadStatus === "uploaded"
                                  ? "Upload Completed"
                                  : files[index]?.name}
                              </b>

                              <p
                                className="mb-0"
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                }}
                              >
                                {uploadStatus !== "selected" ? (
                                  <span>
                                    {uploadStatus === "uploaded" ? (
                                      <i
                                        class="bi bi-check-circle-fill px-1"
                                        style={{ color: "green" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    {files[index]?.name}
                                  </span>
                                ) : (
                                  <span>
                                    <b>Pages : </b>
                                    {Pages[index]}
                                    <b className="ps-2">Size</b> :{" "}
                                    {getFileSize(files[index]?.size)}
                                  </span>
                                )}
                              </p>
                            </span>
                          </div>
                          {uploadStatus === "uploading" ? (
                            <div
                              class="progress mt-2"
                              style={{ height: "10px", width: "95%" }}
                            >
                              <div
                                class="progress-bar progress-bar-striped progress-bar-animated"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: "100%" }}
                              ></div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                  )}
                  <div className="button-row d-flex flex-row w-100 mt-2 ">
                    <button
                      className="w-50 mx-1 py-2"
                      style={{
                        color: "red",
                        border: "2px solid red",
                        backgroundColor: "white",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setFiles([]);
                        setPages([]);
                        setUploadedFiles([]);
                        setFileUploadStatus([]);
                        setPrintOptions([]);
                      }}
                    >
                      <b>Discard All Files</b>
                    </button>

                    <button
                      className="w-50 mx-1 py-2"
                      style={{
                        color: "white",
                        border: "2px solid #11074c",
                        backgroundColor: "#11074c",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        fileUploadStatus.includes("selected") ||
                        fileUploadStatus.includes("uploading")
                          ? uploadFiles()
                          : setOverflow(true);
                      }}
                    >
                      <b>
                        {fileUploadStatus.includes("selected") ||
                        fileUploadStatus.includes("uploading")
                          ? "Upload Files"
                          : "Next"}
                      </b>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {printOptionsScreen && (
            <div
              className="overlay"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <div
                className=""
                style={{
                  backgroundColor: "#dfe3ea",
                  borderRadius: "15px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="d-flex flex-row align-items-center pt-1 justify-content-between"
                  style={{
                    borderRadius: "15px 15px 0 0",
                    backgroundColor: "white",
                    borderBottom: "2px solid #e0e0e0",
                    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <h2 className="mb-0 ps-3">
                    <b>Printout preview</b>
                  </h2>
                  <button
                    className="btn py-0 ps-3"
                    onClick={() => {
                      setPrintOptionsScreen(false);
                      document.body.style.overflowY = "auto";
                    }}
                  >
                    <i className="bi bi-x fs-1"></i>
                  </button>
                </div>
                <div className="horrizontal-scroll">
                  <div
                    className="d-flex flex-row align-items-center px-1 scrollbar"
                    style={{
                      width: "100%",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {files?.map((file, index) => (
                      <div
                        className="d-flex flex-column align-items-center justify-content-evenly"
                        style={{
                          width: "100%",
                          height: "72vh",
                          margin: "5px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                        }}
                      >
                        <div
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                            backgroundColor: "white",
                            border: "1px solid black",
                          }}
                        >
                          <Document file={file}>
                            <Page
                              pageNumber={1}
                              width={120}
                              renderMode="canvas"
                            />
                          </Document>
                        </div>
                        <div className="d-flex">
                          <p
                            style={{
                              color: "black",
                              textWrap: "wrap",
                              textAlign: "center",
                              margin: 0,
                              fontSize: "13px",
                            }}
                          >
                            {file.name}
                          </p>
                        </div>
                        <div
                          className="print-options mt-2 px-3 d-flex flex-column "
                          style={{ width: "max-content" }}
                        >
                          <div
                            className="copies pb-2"
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div
                                className="text-wrap fw-bold me-1"
                                style={{
                                  width: "65%",
                                  fontSize: "14px",
                                }}
                              >
                                <p className="mb-0">Number of copies : </p>
                              </div>
                              <div
                                className="btn-group add-btn "
                                role="group"
                                aria-label="Basic mixed styles example"
                                style={{
                                  height: "30px",
                                  width: "20%",
                                }}
                              >
                                <button
                                  type="buttoncenter"
                                  style={{
                                    padding: "1px 3px",
                                    borderRadius: "10px 0 0 10px",
                                    background: "rgba(170, 172, 173, 0.8)",
                                  }}
                                  className=" btn "
                                  onClick={() => {
                                    if (printOptions[index].copies > 1) {
                                      setPrintOptions([
                                        ...printOptions.slice(0, index),
                                        {
                                          ...printOptions[index],
                                          copies:
                                            printOptions[index].copies - 1,
                                        },
                                        ...printOptions.slice(index + 1),
                                      ]);
                                    } else {
                                      toast.error("Minimum 1 copy required");
                                    }
                                  }}
                                >
                                  <b>-</b>
                                </button>
                                <button
                                  type="button "
                                  className="btn "
                                  style={{
                                    padding: "1px 3px",
                                    background: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                >
                                  <b>{printOptions[index].copies}</b>
                                </button>
                                <button
                                  type="button "
                                  style={{
                                    padding: "1px 3px",
                                    borderRadius: "0 10px 10px 0",
                                    background: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                  className="btn"
                                  onClick={() => {
                                    setPrintOptions([
                                      ...printOptions.slice(0, index),
                                      {
                                        ...printOptions[index],
                                        copies: printOptions[index].copies + 1,
                                      },
                                      ...printOptions.slice(index + 1),
                                    ]);
                                  }}
                                >
                                  <b>+</b>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="color py-2"
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div
                                className="text-wrap fw-bold me-1"
                                style={{
                                  width: "65%",
                                  fontSize: "14px",
                                }}
                              >
                                <p className="mb-0">Color option : </p>
                              </div>
                              <div class="dropdown d-flex justify-content-center align-items-center ">
                                <button
                                  class="btn  dropdown-toggle py-1 px-2"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    backgroundColor: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {printOptions[index].color}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            color: "Color",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      Color
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            color: "B & W",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      B & W
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* <div className="d-flex flex-row pt-2 justify-content-around">
                                  <div
                                    className="d-flex flex-column  align-items-center pt-1"
                                    style={{
                                      borderRadius: "10px ",
                                      border: "1px solid black",
                                      fontSize: "14px",
                                      width: "45%",
                                    }}
                                  >
                                    <img
                                      src={blackwhite}
                                      alt="blackwhite"
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <b>B & W</b>
                                    <div
                                      className="price d-flex justify-content-center w-100"
                                      style={{
                                        backgroundColor:
                                          "rgba(170, 172, 173, 0.4)",
                                        borderRadius: "0 0 10px 10px",
                                      }}
                                    >
                                      <p className="m-0">₹ 3/Page</p>
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex flex-column  align-items-center pt-1"
                                    style={{
                                      borderRadius: "10px ",
                                      border: "1px solid black",
                                      fontSize: "14px",
                                      width: "45%",
                                    }}
                                  >
                                    <img
                                      src={color}
                                      alt="color"
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <b>Coloured</b>
                                    <div
                                      className="price d-flex justify-content-center w-100"
                                      style={{
                                        backgroundColor:
                                          "rgba(170, 172, 173, 0.4)",
                                        borderRadius: "0 0 10px 10px",
                                      }}
                                    >
                                      <p className="m-0">₹ 10/Page</p>
                                    </div>
                                  </div>
                                </div> */}
                          </div>
                          <div
                            className="print-sides py-2"
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div
                                className="text-wrap fw-bold "
                                style={{
                                  width: "65%",
                                  fontSize: "14px",
                                }}
                              >
                                <p className="mb-0">Print sides : </p>
                              </div>
                              <div class="dropdown d-flex justify-content-center align-items-center">
                                <button
                                  class="btn  dropdown-toggle py-1 px-2"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    backgroundColor: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {printOptions[index].sides}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            sides: "Single Side",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      Single Side
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            sides: "Double Side",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      Double Side
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="print-layout py-2"
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div
                                className="text-wrap fw-bold "
                                style={{
                                  width: "65%",
                                  fontSize: "14px",
                                }}
                              >
                                <p className="mb-0">Print layout : </p>
                              </div>
                              <div class="dropdown d-flex justify-content-center align-items-center">
                                <button
                                  class="btn  dropdown-toggle py-1 px-2"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    backgroundColor: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {printOptions[index].layout}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            layout: "Portrait",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      Portrait
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            layout: "Landscape",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      Landscape
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="pages-per-sheet py-2"
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div
                                className="text-wrap fw-bold "
                                style={{
                                  width: "65%",
                                  fontSize: "14px",
                                }}
                              >
                                <p className="mb-0">Pages per Sheet : </p>
                              </div>
                              <div class="dropdown d-flex justify-content-center align-items-center">
                                <button
                                  class="btn  dropdown-toggle py-1 px-2"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    backgroundColor: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {printOptions[index].pagesPerSheet}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  {pagesPerSheetArray.map((num) => (
                                    <li>
                                      <button
                                        class="dropdown-item"
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        onClick={() =>
                                          setPrintOptions([
                                            ...printOptions.slice(0, index),
                                            {
                                              ...printOptions[index],
                                              pagesPerSheet: num,
                                            },
                                            ...printOptions.slice(index + 1),
                                          ])
                                        }
                                      >
                                        {num}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            className="binding py-2"
                            style={{
                              borderBottom: "1px solid black",
                            }}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <div
                                className="text-wrap fw-bold me-1"
                                style={{
                                  width: "65%",
                                  fontSize: "14px",
                                }}
                              >
                                <p className="mb-0">Bind option : </p>
                              </div>
                              <div class="dropdown d-flex justify-content-center align-items-center">
                                <button
                                  class="btn  dropdown-toggle py-1 px-2"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    backgroundColor: "rgba(170, 172, 173, 0.8)",
                                    fontSize: "14px",
                                  }}
                                >
                                  {printOptions[index].binding}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      onClick={() =>
                                        setPrintOptions([
                                          ...printOptions.slice(0, index),
                                          {
                                            ...printOptions[index],
                                            binding: "Loose leafs",
                                          },
                                          ...printOptions.slice(index + 1),
                                        ])
                                      }
                                    >
                                      Loose leafs
                                    </button>
                                  </li>
                                  {store.PrintOptions.binding.map((bind) => (
                                    <li>
                                      <button
                                        class="dropdown-item"
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        onClick={() =>
                                          setPrintOptions([
                                            ...printOptions.slice(0, index),
                                            {
                                              ...printOptions[index],
                                              binding: bind.name,
                                            },
                                            ...printOptions.slice(index + 1),
                                          ])
                                        }
                                      >
                                        {bind.name}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* {uploadedFiles?.length > 1 && (
                            <div className=" text-center py-2">
                              <button
                                className="btn"
                                style={{
                                  border: "2px solid #11074c",
                                  backgroundColor: "#11074c",
                                  color: "white",
                                  width: "80vw",
                                }} 
                                onClick={() => {
                                  setPrintOptions(
                                    new Array(files.length).fill(
                                      printOptions[index]
                                    )
                                  );
                                  toast.success(
                                    "Print settings applied to all files"
                                  );
                                  console.log("printOptions", printOptions);
                                }}
                              >
                                <b>Apply print settings to all files</b>
                              </button>
                            </div>
                          )} */}
                          <div className="price pt-1 pb-2">
                            <div className="d-flex flex-row justify-content-between">
                              <div>
                                <b>
                                  Pages :{" "}
                                  {calculatePages(
                                    printOptions[index],
                                    Pages[index]
                                  )}
                                </b>
                              </div>
                              <div>
                                <b>
                                  Price : ₹
                                  {calculatePrice(
                                    printOptions[index],
                                    Pages[index]
                                  )}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-100 text-center pt-3">
                  <div className="pay">
                    <button
                      className="btn w-75"
                      style={{
                        backgroundColor: "#11074c",
                        color: "white",
                      }}
                      onClick={() => {
                        if (auth.user) {
                          if (currentlyOpen) {
                            if (click) {
                              createOrder();
                            }
                          } else {
                            toast.error(
                              `${store.StoreName} is Currently Closed , Opens at ${store.OpenTime}`
                            );
                          }
                        } else {
                          setOverflow(true);
                          toast.error("Please Create Account First");
                          navigate("/CreateAccount");
                        }
                      }}
                    >
                      {click ? (
                        <b>Pay ₹ {calculateTotalPrice(printOptions, Pages)}</b>
                      ) : (
                        <div
                          className="spinner-border"
                          style={{ height: "25px", width: "25px" }}
                          role="status"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
