import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../../Components/Header/Header";
import axios from "axios";
import { useAuth } from "../../Contex/AuthProvider";
import { OrderCard } from "../../Components/cards/OrderCard";
import { useSocket } from "../../Contex/WebSocket";

export const COrders = () => {
  const [auth] = useAuth();
  const [webSocket] = useSocket();
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [orderscount] = useState(10);
  const [allOrders, setAllOrders] = useState([]);
  const [todayOrders, setTodayOrders] = useState([]);
  const [oldOrders, setOldOrders] = useState([]);
  // console.log(allOrders);
  const fetchData = useCallback(async () => {
    try {
      if (auth.user?._id) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/order/getOrders/${auth.user?._id}`
        );
        if (res.data) {
          setAllOrders(res.data.orders.reverse());
          setTodayOrders(separateOrdersByDate(res.data.orders).ordersToday);
          setOldOrders(separateOrdersByDate(res.data.orders).ordersOtherDays);
          // console.log("id", res.data);
        } else {
          // console.log(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [auth, setAllOrders, setTodayOrders, setOldOrders]); // Add dependencies here

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Now fetchData is a dependency

  useEffect(() => {
    if (webSocket) {
      webSocket.on("orderupdated", (Order) => {
        setUpdatedOrder(Order);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify(Order));
        }
      });
    } else {
      // console.log("false");
    }
  }, [webSocket]);

  useEffect(() => {
    // console.log();
    if (updatedOrder) {
      let index = allOrders.findIndex(
        (obj) => obj._id === updatedOrder.orderid
      );
      // console.log("i:",index)
      const array = [...allOrders];
      array[index].status = updatedOrder.status;
      // console.log("new array :", array);
      setAllOrders(array);
      setUpdatedOrder(null);
    }
  }, [updatedOrder, allOrders]);
  function separateOrdersByDate(orders) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let ordersToday = [];
    let ordersOtherDays = [];

    orders.forEach((order) => {
      let orderDate = new Date(order.createdAt);
      orderDate.setHours(0, 0, 0, 0);

      if (orderDate.getTime() === today.getTime()) {
        ordersToday.push(order);
      } else {
        ordersOtherDays.push(order);
      }
    });

    return { ordersToday, ordersOtherDays };
  }
  const [orders, setOrders] = useState("Orders");
  return (
    <div className="Deskcontainer">
      <Header state={"Orders"} />
      <div className="justify-content-center py-3">
        <ul
          className="orderNav ps-0"
          style={{ borderRadius: "50px", border: "2px solid #11074C" }}
        >
          <li className="nav-item">
            <div
              className={`orderNav-link fs-3 ${
                orders === "Orders" ? "active" : ""
              } text-center`}
              style={{ color: "#11074C", cursor: "pointer" }}
              onClick={() => setOrders("Orders")}
            >
              Orders
            </div>
          </li>
          <li className="nav-item">
            <div
              className={`orderNav-link fs-3 ${
                orders === "History" ? "active" : ""
              } text-center`}
              style={{ color: "#11074C", cursor: "pointer" }}
              onClick={() => setOrders("History")}
            >
              History
            </div>
          </li>
        </ul>
      </div>
      {orders === "Orders" ? (
        todayOrders.length === 0 ? (
          <div className="text-center py-5 py-2">
            <h2>You have no orders</h2>
            <p>
              Looks like you haven't placed any orders today. Start ordering and
              place your first order!
            </p>
          </div>
        ) : (
          <div className="todayOrders " style={{ paddingBottom: "60px" }}>
            {todayOrders?.map((i, index) => (
              <OrderCard i={i} key={index} />
            ))}{" "}
          </div>
        )
      ) : oldOrders.length === 0 ? (
        <div className="text-center py-5 py-2">
          <h2>You have no orders</h2>
          <p>
            Looks like you haven't placed any orders yet. Start ordering and
            place your first order!
          </p>
        </div>
      ) : (
        <div
          className="orders text-center pt-2"
          // onScroll={handleScroll}
          style={{ color: "#11074C", paddingBottom: "4.5rem" }}
        >
          {/* {console.log("old:", oldOrders)} */}
          {oldOrders?.map((i, index) => {
            if (index < orderscount) {
              return <OrderCard i={i} key={index} />;
            } else {
              return null;
            }
          })}
        </div>
      )}

      {/* <div
        className="contact p-2 mx-2 text-center fw-bold flex-row d-flex"
        style={{
          fontSize: "18px",
          backgroundColor: "white",
          borderRadius: "20px",
        }}
      >
        Problem With Order ?{" "}
        <div
          style={{ color: "#55a5cb" }}
          className="ps-2"
          onClick={() => {
            navigate("/Messages");
          }}
        >
          Contact Store
        </div>
      </div> */}
    </div>
  );
};
