import { useState, useContext, createContext, useEffect } from "react";
import { useAuth } from "./AuthProvider";
import io from "socket.io-client";
import toast from "react-hot-toast";
const WebSocketContext = createContext();

const SocketProvider = ({ children }) => {
  // const[conv,setConv]=useConv();
  const [auth] = useAuth();
  const [webSocket, setWebSocket] = useState(null);
  useEffect(() => {
    if (auth?.user?._id) {
      const socket = io(process.env.REACT_APP_API, {
        reconnection: true,
        reconnectionAttempts: 10,
      });
      // console.log(
      //   `Connected to the server with user id ${auth.user._id}`
      // );
      socket.on("connect", () => {
        // console.log("Socket.IO connection established");
        setWebSocket(socket);
        if (auth?.user?.role === 0) {
          socket.emit("addUser", auth?.user?._id);
          // console.log("add user:", auth?.user);
        } else {
          // console.log("user:", auth?.user);
          socket.emit("addUser", auth?.user?.storeId);
          // console.log("web:", auth?.user);
        }
        socket.on("orderupdated", (Order) => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(Order));
          }
          // console.log("Socket.IO connection disconnected");
        });
        socket.on("NewOrder", () => {
          toast.success("New Order Received");
        });
        // socket.on("NotifyOrderUpdate", (Order) => {
        //   const notification = {
        //     title: "Order Update",
        //     body: `Order of ${Order?.items.map(
        //       (i) => `${i?.ItmCtn} x ${i.details?.name}`
        //     )} is ${Order.status}`,
        //   };
        //   // if (window.ReactNativeWebView) {
        //   //   toast.success("notificatoin Generated");
        //   //   window.ReactNativeWebView.postMessage(JSON.stringify(notification));
        //   // }
        //   // console.log();
        // });
      });
      // socket.on("getMessage", ({message}) => {
      //   console.log("getMessage:",message)
      // });
      //   socket.on("message", (message) => {
      //     console.log(`Received message: ${message}`)
      //   });

      // socket.emit("message", "Hello, server!");
    }
  }, [auth]);
  return (
    <WebSocketContext.Provider value={[webSocket]}>
      {children}
    </WebSocketContext.Provider>
  );
};

// custom hook
const useSocket = () => useContext(WebSocketContext);

export { useSocket, SocketProvider };
