import React, { useState, useEffect } from "react";
import { AddItem, MenuItem } from "../../Components/cards/MenuItem";
import { EditStoreDetails } from "./EditStoreDetails";
import axios from "axios";
import { useAuth } from "../../Contex/AuthProvider";
import { Header } from "../../Components/Header/Header";
// import "dotenv/config"
// import env from "react-dotenv";
export const MyStore = () => {
  // const items = [1, 2, 3, 4, 5];
  // const [selectedCategory, setSelectedCategory] = useState("");
  // const uniqueCategories = [...new Set(items.map((item) => item.category))]
  // console.log("env:", process.env);
  const [auth] = useAuth();
  // console.log("auth", auth);
  const [items, setItems] = useState([]);
  const [store, setStore] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (auth?.user.storeId) {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/store/store/${auth?.user.storeId}`
        );
        // console.log("res:", res.data.store.Items);
        if (res.data.store) {
          setStore(res.data.store);
          setItems(res.data.store.Items);
          // console.log("id", res.data.store);
          setLoading(false);
        } else {
          console.log(res);
        }
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="Deskcontainer" style={{ paddingBottom: "35px" }}>
      <Header state={"My Store"} />
      {loading ? (
        <div
          className="d-flex justify-content-center align-content-center pb-1"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="" style={{ width: "100%" }}>
          <EditStoreDetails />

          <hr className="mt-3" />
          <div className="d-flex flex-nowrap mb-5">
            <div className="container text-center">
              {store?.type === "FoodStore" ? (
                <div>
                  <h1>Menu</h1>
                  <div className="">
                    <AddItem
                      style={{
                        width: "30%",
                        marginRight: "10px",
                        border: "2px solid #11074C",
                        marginbottom: "10px",
                      }}
                    />
                    {items.map((id) => (
                      <MenuItem
                        id={id.Id}
                        style={{ marginRight: "10px" }}
                        key={id._id}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <h1>Print Options</h1>
                  <div className="w-75 d-flex justify-content-center">
                    <div className="text-start">
                      <h3>Color X Sides Pricing : </h3>
                      {store?.PrintOptions?.colorXside.map((item) => (
                        <div className="mb-3 d-flex ">
                          <label
                            htmlFor="spes1"
                            className="col-md-4"
                            style={{ width: "50%" }}
                          >
                            {item.name}
                          </label>
                          <div className="input-group w-50">
                            <input
                              type="text"
                              className="form-control col-md-4"
                              id="spes1"
                              placeholder="Speciality 1"
                              required=""
                              value={item.price}
                              spellCheck="false"
                              data-ms-editor="true"
                            />
                          </div>
                        </div>
                      ))}
                      <h3>Binding : </h3>
                      {store?.PrintOptions?.binding.map((item) => (
                        <div className="mb-3 d-flex ">
                          <label
                            htmlFor="spes1"
                            className="col-md-4"
                            style={{ width: "50%" }}
                          >
                            {item.name}
                          </label>
                          <div className="input-group w-50">
                            <input
                              type="text"
                              className="form-control col-md-4"
                              id="spes1"
                              placeholder="Speciality 1"
                              required=""
                              value={item.price}
                              spellCheck="false"
                              data-ms-editor="true"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
