import { useState, useEffect } from "react";
import { useAuth } from "../Contex/AuthProvider";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";

export default function AdminRoute() {
  const [ok, setOk] = useState(false);
  const navigate = useNavigate();
  const [auth] = useAuth();

  useEffect(() => {
    const authCheck = async () => {
      if (!auth.token) {
        // navigate("/Login");
        // toast.error("You are not authorized to view this page");
      } else {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}/api/v1/auth/admin-auth`
          );
          if (res?.data.ok) {
            setOk(true);
          } else {
            setOk(false);
            // navigate("/Login");
            // toast.error("You are not authorized to view this page");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    authCheck();
  }, [auth.token, navigate]);

  console.log(ok)
  return <Outlet />;
}
